import {
  Box,
  Button,
  Grid,
  TextField,
  Backdrop,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { SetPassword } from "../../services/CustomerService";
import { Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { action_types } from "../../types/ActionTypes";
import { ApplicationState } from "../../types/Master";
import { Dispatch } from "redux";
import UseTranslation from "../../services/UseTranslation";
import { Container } from "reactstrap";
import "../../custom.css";
import SolmetexLogoSvg from "../../images/Solmetex_logo.svg";

const translation = UseTranslation();
const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    logout: () => dispatch({ type: "LOGOUT", payload: undefined }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  isLoading: state.isLoading,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class SetPasssword extends React.Component<PropsFromRedux> {
  state: {
    isRedirect: boolean;
    errors: string;
  } = {
    isRedirect: false,
    errors: "",
  };

  validate = (data: FormData) => {
    let pwd = data.get("password");
    let confirm_pwd = data.get("confirmpassword");
    let isValid = true;
    if (typeof pwd !== "undefined" && typeof confirm_pwd !== "undefined") {
      if (pwd != confirm_pwd) {
        isValid = false;
        this.setState({
          errors: translation.passwordMismatch,
        });
      }
    }
    return isValid;
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (this.validate(data)) {
      this.props.setLoader(true);
      SetPassword(
        data.get("email"),
        data.get("password"),
        data.get("otp"),
        "fp",
        ""
      )
        .then((res: any) => {
          this.props.setLoader(false);
          if (res.status == "success") {
            this.props.setToastMessage(translation.passwordUpdateSuccess, true);
            this.setState({ isRedirect: true });
          } else {
            this.props.setToastMessage(translation.passwordUpdateFail, false);
            this.setState({ isRedirect: true });
          }
        })
        .catch((ex: any) => {
          this.props.setLoader(false);
          this.props.setToastMessage(translation.passwordUpdateFail, false);
          this.setState({ isRedirect: true });
        });
    }
  };

  render() {
    const { isRedirect } = this.state;
    const { isLoading } = this.props;

    if (isRedirect) {
      return <Redirect to="/SignIn" />;
    }

    return (
      <Box className="sx-signUpBg">
        <Container>
          <Backdrop
            style={{
              zIndex: 2250,
            }}
            open={isLoading}
          >
            <Box className="loading-icon" />
          </Backdrop>
          <Box
            component="form"
            onSubmit={this.handleSubmit}
            className="sx-login"
          >
            <Grid container spacing={2}>
              <Grid
                item
                md={7}
                className="d-flex justify-content-center align-items-center"
              >
                <Box>
                  <div className="text-center mb-5">
                    <img
                      src={require("../../images/setPwd.png")}
                      alt="Solmetex"
                      className="img-fluid"
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item md={5}>
                <Box className="sx-loginBg text-center">
                  <div className="text-center mb-3">
                    <img
                      src={SolmetexLogoSvg}
                      alt="Solmetex"
                      className="sx-loginLogo"
                    />
                  </div>
                  <Typography variant="h1" className="sx-loginTitle mb-1">
                    Reset password
                  </Typography>
                  <Typography className="mb-4 sx-grey">
                    Set your new password so you can login and access again.
                  </Typography>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={translation.emailAddress}
                    name="email"
                    autoComplete="email"
                    className="sx-inputGroup"
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="otp"
                    label={translation.Otp}
                    name="otp"
                    className="sx-inputGroup"
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label={translation.password}
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    margin="normal"
                    className="sx-inputGroup"
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    required
                    fullWidth
                    name="confirmpassword"
                    label={translation.confirmPassword}
                    type="password"
                    id="confirmpassword"
                    autoComplete="confirm-password"
                    helperText={this.state.errors}
                    FormHelperTextProps={{
                      classes: {
                        root: "helper-text",
                      },
                    }}
                    margin="normal"
                    className="sx-inputGroup"
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={this.props.isLoading}
                    className="sx-button mt-4"
                  >
                    {translation.submit}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  }
}
export default connector(SetPasssword);
