import { defaultOnClusterClickHandler } from "@googlemaps/markerclusterer";

export interface ApplicationState {
    isAuthUser: boolean;
    user: User;
    isLoading: boolean;
    error: any;
    toast: Toast;
    SelectedAccount: Account;
    ShowSelectedAccountData: boolean;
    SelectedRegisteredProduct: RegisteredProduct;
    IsContactAccountsLoaded: boolean;
    SelectedRecycledCertificate: RecycledCerticate;
    SelectedTab: number;
    WooCommerceRedirectionRequired: boolean;
    complianceStatusType: string;
    displayAccountsData: boolean;
    DsoParentAccount: Account;
    DsoAccounts: Account[];
    ShippingLabel: string;
    SelectedAccountContact: User;
}

export interface SignInRequest {
    id: string;
    password: string;
}

export interface AccountLookUpRequest {
    inputValue: string;
    zipCode: string;
}

export interface SignUpRequest {
    accountId: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export interface LookupEntity {
    ID: string;
    Name: string;
}

export enum ComplianceStatus {
    InCompliance = 1,
    ApproachingDeadline = 2,
    OutOfCompliance = 3,
    NoProductReported = 4,
    HasMaintenancePlans = 5,
    None = 6,
}

export enum LoggedInRole {
    National = 1,
    Regional = 2,
    SubRegional = 3,
    None = 4,
}

export interface Account {
    ID: string;
    Name: string;
    Number: string;
    Phone: string;
    Email: string;
    Street: string;
    Street2: string;
    City: string;
    Country: string;
    State: string;
    DefaultDealer: LookupEntity;
    PrimaryContact: User;
    AmalgamContainerStatus: ComplianceStatus;
    AmalgamBucketsStatus: ComplianceStatus;
    StrawsStatus: ComplianceStatus;
    WaterTestingStatus: ComplianceStatus;
    LastRecycleDate: string;
    RegionalManager: User;
    SubRegionalManager: User;
    MaintenancePlansCount: number;
    LocationId: string;
    ZipCode: string;
    Fax: string;
    DefaultRecycler: LookupEntity;
    AllowShopFromPortal: boolean;
}

export interface User {
    ID: string;
    Account: LookupEntity;
    Email: string;
    Token: string;
    Name: string;
    FirstName: string;
    LastName: string;
    Accounts: Account[];
    Phone: string;
    Mobile: string;
    IsDsoLogin: boolean;
    Role: LoggedInRole;
    IsLoginForMultipleAccounts: boolean;
}

export interface Toast {
    Message: string;
    Status: boolean;
}

export interface Product {
    ID: string;
    Name: string;
    Number: string;
    Description: string;
    ReplacementFrequency: number;
    QuoteProductName: string;
    AutoshipFrequency: string[];
}

export interface RegistrableProduct {
    ID: string;
    Name: string;
    Number: string;
    Description: string;
    ReplacementFrequency: number;
    QuoteProductName: string;
    AutoshipFrequency: string[];
    DisplayBottleSize: boolean;
    DisplayLotNumber: boolean;
    DisplaySerialNumber: boolean;
    DisplayNumberOfChairs: boolean;
    DisplayNumberOfBottles: boolean;
    DisplayUploadImage: boolean;
}

export interface MaintenancePlan {
    ID: string;
    Name: string;
    Account: Account;
    Product: Product;
    ProductNumber: string;
    ProductDescription: string;
    Quantity: number;
    Frequency: string;
    Dealer: Account;
    Contact: User;
    NextShipDate: string;
    CreatedOn: string;
    Status: boolean;
    IsMaintenancePlanManagerUpdated: boolean;
    IsDefaultDistributorUpdated: boolean;
    IsStatusUpdate: boolean;
    LoggedInContact: User;
}

export interface ProductIssueRequest {
    Title: string;
    Type: string;
    Product: Product;
    Description: string;
    Contact: string;
    Account: string;
}

export interface Case {
    Title: string;
    Type: string;
    TypeValue: string;
    Date: string;
    Product: Product;
    ProductNumber: string;
    ProductDescription: string;
    Status: string;
    Offer: string;
    PromotionStatus: string;
    SerialLotNumber: string;
    PromotionImageUrl: string;
    Description: string;
}

export interface Campaign {
    ID: string;
    Name: string;
    Description: string;
    PromotionType: number;
}

export interface PromotionRequest {
    ChequePayble: string;
    SerialLotNumber: string;
    CampaignId: string;
    Description: string;
    Account: string;
    Title: string;
    Contact: string;
    PromotionType: number;
}

export interface RegisteredProduct {
    Account: Account;
    Product: Product;
    Name: string;
    DealerName: string;
    DealerText: string;
    Quantity: number;
    SerialNumber: string;
    LotNumber: string;
    Dealer: Account;
    PurchaseDate: string;
    InstallationDate: string;
    ReplacementDate: string;
    DealerRepName: string;
    DealerRepEmail: string;
    DealerRepPhoneNumber: string;
    BottleSize: number;
    NumberofBottles: number;
    NumberofChairs: number;
    ProductNumber: string;
    ProductDescription: string;
    ImageOfInstallation: string;
    Source: string;
}

export interface Recycler {
    Name: string;
    Address: string;
    Phone: string;
    Countrycode: string;
    Statecode: string;
    Postcode: string;
    City: string;
}

export interface RecycledCerticate {
    Account: Account;
    Product: Product;
    CertificateName: string;
    DeliveryDate: string;
    TrackingNumber: string;
    Quantity: number;
    Weight: number;
    Material: string;
    View: string;
    Recycler: Recycler;
    Street1: string;
    Street2: string;
    Street3: string;
    City: string;
    State: string;
    ZipCode: string;
    OrginalAccountName: string;
}

export interface WaterTesting {
    Customer: Account;
    ID: string;
    DateofSample: string;
    TestType: string;
    TestLabName: string;
    RecordType: string;
    AttachmentPath: string;
    Name: string;
    RequestContact: boolean;
    CertificateRequest: boolean;
    RequestOrder: boolean;
    SampleFailed: boolean;
    ImageUpdated: boolean;
    WaterCertificateUrl: string;
    DoctorName: string;
    ComplianceOfficerName: string;
    ComplianceOfficerEmail: string;
    Type: string;
    Brand: string;
    HowLongSinceLastShockTreatment: string;
    ShockProductUsed: string;
    OtherShockProductUsed: string;
    OtherStraw: string;
    OtherTypeofTreatment:string;    
    InstallationDate: string;
    ShockBeforeInstall: string;
    TypeOfTreatment: string;
    SourceofWater: string;
    SourceofWaterBrand: string;
    SourceofWaterOther: string;
    SinkName: string;
    Other: string;
    DentisafeTesting: boolean;
    TrackingNumber: string;
    TestId: string;
    Address1:string;
    Address2:string;
    City:string;
    State:string;
    Country:string;
    PostCode:string;
    PhoneNumber:string;   
    OtherWaterBottles:string; 
    ContactEmail:string;
//    CustomerNumber:string;
  //  CustomerId:string;
    SubmittedBy:string;
    TestID:string;
    ShippingLabel:string;
    WaterTestingReportPath:string;
    Vials: Vial[]
}

export interface Vial {
    ID: number;
    SampleNumber: string;
    DeviceOrSourceWater: string;
    BottleOrMunicipal: string;
    OtherInformation: string;
    RoomChairOperatory: string;
}

export interface ShippingLabelRequest {
    Account: Account;
    Product: Product;
    ShippingCode: string;
    PhoneNumber: string;
}

export interface WoocommerceRequest {
    Account: Account;
    Contact: User;
    RedirectTo: string;
}


//Default values

export const defaultLookupEntity = {
    ID: "",
    Name: "",
};

export const defaultProduct = {
    ID: "",
    Name: "",
    Number: "",
    Description: "",
    ReplacementFrequency: 0,
    QuoteProductName: "",
    AutoshipFrequency: [],
};

export const defaultRegistrableProduct = {
    ID: "",
    Name: "",
    Number: "",
    Description: "",
    ReplacementFrequency: 0,
    QuoteProductName: "",
    AutoshipFrequency: [],
    DisplayBottleSize: false,
    DisplayLotNumber: false,
    DisplaySerialNumber: false,
    DisplayNumberOfChairs: false,
    DisplayNumberOfBottles: false,
    DisplayUploadImage: false,
};

export const defaultProductIssueRequest = {
    Title: "",
    Type: "",
    Product: { ...defaultProduct },
    Description: "",
    Contact: "",
    Account: "",
};

export const initialUserState = {
    ID: "",
    Account: defaultLookupEntity,
    Email: "",
    Token: "",
    Name: "",
    FirstName: "",
    LastName: "",
    Accounts: [],
    Phone: "",
    Mobile: "",
    IsDsoLogin: false,
    Role: LoggedInRole.None,
    IsLoginForMultipleAccounts: false,
};

export const defaultAccount = {
    ID: "",
    Name: "",
    Number: "",
    Phone: "",
    Email: "",
    Street: "",
    Street2: "",
    City: "",
    Country: "",
    State: "",
    DefaultDealer: defaultLookupEntity,
    PrimaryContact: initialUserState,
    AmalgamContainerStatus: ComplianceStatus.None,
    AmalgamBucketsStatus: ComplianceStatus.None,
    StrawsStatus: ComplianceStatus.None,
    WaterTestingStatus: ComplianceStatus.None,
    LastRecycleDate: "0001-01-01",
    RegionalManager: initialUserState,
    SubRegionalManager: initialUserState,
    MaintenancePlansCount: 0,
    LocationId: "",
    ZipCode: "",
    Fax: "",
    DefaultRecycler: defaultLookupEntity,
    AllowShopFromPortal: false,
};

export const defaultSignInRequest = {
    id: "",
    password: "",
};

export const defaultSignUpRequest = {
    accountId: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
};

export const defaultAccountLookUpRequest = {
    inputValue: "",
    zipCode: "",
};

export const defaultMaintenancePlan = {
    ID: "",
    Name: "",
    Account: defaultAccount,
    Product: defaultProduct,
    ProductNumber: "",
    ProductDescription: "",
    Quantity: 1,
    Frequency: "",
    Dealer: defaultAccount,
    Contact: initialUserState,
    NextShipDate: "",
    CreatedOn: "",
    Status: false,
    IsMaintenancePlanManagerUpdated: false,
    IsDefaultDistributorUpdated: false,
    IsStatusUpdate: false,
    LoggedInContact: initialUserState
};

export const defaultRegisteredProduct = {
    Account: defaultAccount,
    Product: defaultProduct,
    DealerName: "",
    DealerText: "",
    Quantity: 1,
    SerialNumber: "",
    Dealer: defaultAccount,
    InstallationDate: "",
    PurchaseDate: "",
    ReplacementDate: "",
    CompliancePlanContact: "",
    DealerRepName: "",
    DealerRepEmail: "",
    DealerRepPhoneNumber: "",
    LotNumber: "",
    BottleSize: 0,
    NumberofBottles: 0,
    NumberofChairs: 0,
    Name: "",
    ImageOfInstallation: "",
    ProductNumber: "",
    ProductDescription: "",
    Source: "",
};

export const defaultFile = {
    inputValue: "",
    zipCode: "",
};

export const mimeDict = {
    pdf: "application/pdf",
    png: "image/png",
    jpeg: "image/jpeg",
    gif: "image/gif",
};

export const defaultShippingLabelRequest = {
    Account: defaultAccount,
    Product: defaultProduct,
    ShippingCode: "",
    PhoneNumber: "",
};

export const defaultWoocommerceRequest = {
    Account: defaultAccount,
    Contact: initialUserState,
    RedirectTo: "",
};

export const defaultRecycler = {
    Name: "",
    Address: "",
    Phone: "",
    Countrycode: "",
    Statecode: "",
    Postcode: "",
    City: "",
};

export const defaultRecycledCerticate = {
    Account: defaultAccount,
    Product: defaultProduct,
    CertificateName: "",
    DeliveryDate: "",
    TrackingNumber: "",
    Quantity: 0,
    Weight: 0,
    Material: "",
    View: "",
    Recycler: defaultRecycler,
    Street1: "",
    Street2: "",
    Street3: "",
    City: "",
    State: "",
    ZipCode: "",
    OrginalAccountName: ""
};

export const defaultCampaign = {
    ID: "",
    Name: "",
    Description: "",
    PromotionType: 0,
};

export const defaultPromotionRequest = {
    ChequePayble: "",
    SerialLotNumber: "",
    CampaignId: "",
    Description: "",
    Account: "",
    Title: "",
    Contact: "",
    PromotionType: 0,
};

export const defaultWaterTesting = {
    Customer: defaultAccount,
    ID: "",
    DateofSample: "",
    TestType: "",
    TestLabName: "",
    RecordType: "",
    AttachmentPath: "",
    Name: "",
    RequestContact: false,
    CertificateRequest: false,
    RequestOrder: false,
    SampleFailed: false,
    ImageUpdated: false,
    WaterCertificateUrl: "",
    DoctorName: "",
    ComplianceOfficerName: "",
    ComplianceOfficerEmail: "",
    Type: "",
    Brand: "",
    HowLongSinceLastShockTreatment: "",
    ShockProductUsed: "",
    OtherShockProductUsed:"",
    InstallationDate: "",
    ShockBeforeInstall:"0",
    TypeOfTreatment: "",
    SourceofWater: "",
    SinkName: "",
    Other: "",
    DentisafeTesting: false,
    TrackingNumber: "",
    TestId: "",
    Address1:"",
    Address2:"",
    City:"",
    State:"",
    Country:"",
    PostCode:"",
    PhoneNumber:"",
    OtherTypeofTreatment:"",
    SourceofWaterBrand: "",
    SourceofWaterOther:"",
   // Customer:defaultAccount,
    // CustomerId:"",
    SubmittedBy:"",
    TestID:"",
    ShippingLabel:"",
    OtherWaterBottles:"",
    WaterTestingReportPath:"",
    ContactEmail:"",
    OtherStraw:"",
    Vials: [],    
};

export const defaultVial= {
    ID: 0,
    SampleNumber: "",
    DeviceOrSourceWater: "",
    BottleOrMunicipal: "",
    OtherInformation: "",
    RoomChairOperatory: "",
}
