import * as React from "react";
import { AnyAction, Dispatch } from "redux";
import {
  ApplicationState,
  Case,
  defaultProductIssueRequest,
  Product,
  ProductIssueRequest,
  User,
} from "../../types/Master";
import { connect, ConnectedProps } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Grid,
} from "@material-ui/core";
import UseTranslation from "../../services/UseTranslation";
import SearchBar from "material-ui-search-bar";
import {
  EnhancedTableHead,
  getComparator,
  HeadCell,
  OrderType,
  stableSort,
} from "../shared/Table";
import {
  CaseTypes,
  generatePDF,
  generateExcel,
  getCaseStatus,
  getFormatedDateForDisplay,
} from "../../services/Shared";
import {
  CreateProductIssue,
  GetAccountContacts,
} from "../../services/CustomerService";
import { Add, Close } from "@material-ui/icons";
import { GetCases, GetProducts } from "../../services/ProductService";
import SideNav from "../shared/SideNav";
import { Autocomplete } from "@material-ui/lab";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
  selectedAccountContact: state.SelectedAccountContact,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const headCells: HeadCell[] = [
  {
    id: "Title",
    numeric: false,
    disableSorting: false,
    label: translation.caseTitle,
  },
  {
    id: "TypeValue",
    numeric: false,
    disableSorting: false,
    label: translation.caseType,
  },
  {
    id: "Date",
    numeric: false,
    disableSorting: false,
    label: translation.date,
  },
  {
    id: "ProductNumber",
    numeric: false,
    disableSorting: false,
    label: translation.productnumber,
  },
  {
    id: "ProductDescription",
    numeric: false,
    disableSorting: false,
    label: translation.productdescription,
  },
  {
    id: "Status",
    numeric: false,
    disableSorting: false,
    label: translation.caseStatus,
  },
  {
    id: "Description",
    numeric: false,
    disableSorting: false,
    label: translation.caseDescription,
  },
];

const defaultcolumns = [
  "Case Title",
  "Case Type",
  "Date",
  "Product",
  "Case Status",
];

const Cases = (props: PropsFromRedux) => {
  const {
    user,
    isLoading,
    selectedAccount,
    selectedAccountContact,
    isContactAccountsLoaded,
    setLoader,
    setToastMessage,
  } = props;
  const [searched, setSearched] = React.useState<string>("");
  const [order, setOrder] = React.useState<OrderType>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("CreatedOn");
  const [cases, setCases] = React.useState<Case[]>([]);
  const [totalcases, setTotalcases] = React.useState<Case[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isAddNew, setIsAdNew] = React.useState<boolean>(false);
  const [request, setRequest] = React.useState<ProductIssueRequest>({
    ...defaultProductIssueRequest,
  });
  const [products, setProducts] = React.useState<Product[]>([]);
  const [contacts, setContacts] = React.useState<User[]>([]);

  const requestSearch = (searchedVal: string) => {
    let listOfWords = searchedVal.split(" ");
    let filteredRows: Case[] = [];
    totalcases.forEach((item) => {
      let isAllWordsPresent = false;
      listOfWords.every((word: string) => {
        if (
          item.Product.Number.toLowerCase().includes(word.toLowerCase()) ||
          item.Product.Description.toLowerCase().includes(word.toLowerCase()) ||
          item.Title.toLowerCase().includes(word.toLowerCase()) ||
          item.TypeValue.toLowerCase().includes(word.toLowerCase()) ||
          getCaseStatus(item.Status)
            .toLowerCase()
            .includes(word.toLowerCase()) ||
          getFormatedDateForDisplay(item.Date)
            .toLowerCase()
            .includes(word.toLowerCase())
        ) {
          isAllWordsPresent = true;
          return true;
        } else {
          isAllWordsPresent = false;
          return false;
        }
      });
      if (isAllWordsPresent) filteredRows.push({ ...item });
    });
    setCases(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setIsAdNew(false);
  };

  React.useEffect(() => {
    if (isContactAccountsLoaded && selectedAccount.ID != "") {
      setLoader(true);
      Promise.all([getContacts(), getProducts(), getCases()])
        .then(() => setLoader(false))
        .catch((ex) => setLoader(false));
    }
  }, [selectedAccount, isContactAccountsLoaded]);

  const getContacts = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      GetAccountContacts(selectedAccount.ID)
        .then((contacts: User[]) => {
          setContacts(contacts);
          resolve();
        })
        .catch((ex) => {
          console.error(ex);
          reject();
        });
    });
  };

  const getProducts = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      GetProducts()
        .then((products: Product[]) => {
          setProducts(products);
          resolve();
        })
        .catch((ex) => {
          console.error(ex);
          reject();
        });
    });
  };

  const getCases = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      GetCases(selectedAccount.ID)
        .then((res) => {
          setCases(res);
          setTotalcases(res);
          resolve();
        })
        .catch((ex) => {
          console.error(ex);
          reject();
        });
    });
  };

  const handleAddNew = (e: any) => {
    e.preventDefault();
    setIsAdNew(true);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var ContactID = user.ID;    
    if (selectedAccountContact != null && selectedAccountContact.ID != "") {
      ContactID = selectedAccountContact.ID;
    }
    setLoader(true);
    let newrequest = {
      ...request,
    };
    if (user.IsDsoLogin) {
      newrequest = {
        ...request,
        Account: selectedAccount.ID,
        Title: `${selectedAccount.Number} - ${request.Title}`,
      };
    } else {
      newrequest = {
        ...request,
        Account: selectedAccount.ID,
        Contact: ContactID,
        Title: `${selectedAccount.Number} - ${request.Title}`,
      };
    }
    CreateProductIssue(newrequest)
      .then((responseId: any) => {
        setRequest({ ...defaultProductIssueRequest });
        setIsAdNew(false);
        getCases().then(() => {
          setToastMessage(translation.productIssueCreateSuccess, true);
          setLoader(false);
        });
      })
      .catch((ex: any) => {
        console.error(ex);
        setLoader(false);
        setToastMessage(translation.failed, false);
        setIsAdNew(false);
      });
  };

  const downloadExcelOrPdf = (isPdf: boolean) => {
    const tableRows: any[] = [];
    cases.forEach((item) => {
      const data = [
        item.Title,
        item.TypeValue,
        getFormatedDateForDisplay(item.Date),
        item.Product.Description,
        getCaseStatus(item.Status),
      ];
      tableRows.push(data);
    });
    if (isPdf) generatePDF(tableRows, defaultcolumns, translation.supportCases);
    else generateExcel(tableRows, defaultcolumns, translation.supportCases);
  };

  const handleProductSelection = (val: string) => {
    products.every((item) => {
      if (item.ID == val) {
        setRequest({
          ...request,
          Product: { ...item },
        });
        return false;
      } else return true;
    });
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={3} md={2} className="sx-colorBg-grey-08 px-4 pt-4">
        <SideNav />
      </Grid>
      <Grid item xs={12} sm={9} md={10}>
        <Dialog className="sx-model" open={isAddNew} onClose={handleClose}>
          <Box component="form" onSubmit={handleSubmit}>
            <DialogTitle className="sx-model-title sx-border-bottom">
              {translation.supportCase}
            </DialogTitle>
            <Box position="absolute" top={8} right={8}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <DialogContent className="my-4">
              <TextField
                fullWidth
                className="sx-inputGroup my-2"
                id="title"
                name="title"
                label={translation.title}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
                required
                value={request.Title}
                onChange={(e: any) => {
                  setRequest({ ...request, Title: e.target.value });
                }}
              />
              <FormControl
                fullWidth
                variant="filled"
                className="sx-inputGroup sx-customSelect my-2"
              >
                <Select
                  id="select-type"
                  className="sx-input"
                  disableUnderline={true}
                  value={request.Type}
                  onChange={(e: any) => {
                    setRequest({ ...request, Type: e.target.value });
                  }}
                  required
                  displayEmpty
                >
                  <MenuItem value="" className="disabled">
                    {translation.selectType}
                  </MenuItem>
                  {CaseTypes.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                className="sx-inputGroup sx-customSelect my-2"
              >
                <Autocomplete
                  value={request.Product}
                  onChange={(event, newValue) => {
                    handleProductSelection(newValue ? newValue.ID : "");
                  }}
                  id="select-product"
                  options={products}
                  getOptionLabel={(option) =>
                    option.QuoteProductName != ""
                      ? `${option.Number} - ${option.QuoteProductName}`
                      : option.Number
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translation.selectProduct}
                      variant="filled"
                      className="sx-input"
                      InputLabelProps={{ className: "sx-input-label" }}
                    />
                  )}
                />
              </FormControl>
              <TextField
                fullWidth
                className="sx-inputGroup my-2"
                id="description"
                name="description"
                label={translation.description}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
                multiline
                required
                value={request.Description}
                onChange={(e: any) => {
                  setRequest({ ...request, Description: e.target.value });
                }}
              />
              {user.IsDsoLogin && (
                <FormControl
                  fullWidth
                  variant="filled"
                  className="sx-inputGroup sx-customSelect my-2"
                >
                  <Select
                    id="select-contact"
                    className="sx-input"
                    disableUnderline={true}
                    onChange={(e: any) => {
                      setRequest({ ...request, Contact: e.target.value });
                    }}
                    value={request.Contact}
                    required
                    displayEmpty
                  >
                    <MenuItem value="" className="disabled">
                      {translation.selectContact}
                    </MenuItem>
                    {contacts &&
                      contacts.length > 0 &&
                      contacts.map((item) => {
                        return (
                          <MenuItem
                            value={item.ID}
                          >{`${item.FirstName} ${item.LastName}`}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions className="px-4 py-3 sx-border-top">
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                className="sx-button"
              >
                {translation.submit}
              </Button>
              <Button
                variant="text"
                className="sx-button sx-textButton"
                onClick={handleClose}
              >
                {translation.cancel}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        <Box className="p-3">
          <h3>{translation.supportCases}</h3>
          <p className="small">{translation.supportCasesDescription}</p>
        </Box>
        <Box className="sx-table-shadow mb-5 p-3">
          <Box className="d-flex justify-content-between align-items-center pb-3 sx-tableSearchContainer">
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              className="col-sm-5 px-0 sx-tableSearch shadow-none"
            />
            <Box className="d-flex justify-content-between align-items-center pt-1">
              <Button
                type="submit"
                variant="contained"
                className="ml-2 sx-button"
                onClick={handleAddNew}
              >
                <Add />
                <span className="sx-hideOnMobile">{translation.add}</span>
              </Button>
              <IconButton
                className="ml-1 p-0"
                onClick={() => downloadExcelOrPdf(true)}
              >
                <img
                  src={require("../../images/pdf.png")}
                  alt="Solmetex"
                  className="img-fluid sx-iconButtonImg"
                />
              </IconButton>
              <IconButton
                className="p-0"
                onClick={() => downloadExcelOrPdf(false)}
              >
                <img
                  src={require("../../images/xls.png")}
                  alt="Solmetex"
                  className="img-fluid sx-iconButtonImg"
                />
              </IconButton>
            </Box>
          </Box>
          <Box className="pb-3">
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  rowCount={cases.length}
                  displayActionCell={false}
                  onRequestSort={handleRequestSort}
                />
                {cases.length == 0 ? (
                  <TableBody className="sx-tableBody">
                    <TableRow>
                      <TableCell
                        className="mb-5 p-3 text-center"
                        colSpan={headCells.length}
                      >
                        <img
                          src={require("../../images/empty-state.png")}
                          alt="Solmetex"
                          className="img-fluid"
                        />
                        <h3 className="mt-3 sx-blue">No Data Found</h3>
                        <p>please try again later</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="sx-tableBody">
                    {stableSort(cases, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => {
                        return (
                          <TableRow>
                            <TableCell component="th" scope="row" width={180}>
                              {item.Title}
                            </TableCell>
                            <TableCell>{item.TypeValue}</TableCell>
                            <TableCell>
                              {getFormatedDateForDisplay(item.Date)}
                            </TableCell>
                            <TableCell>{item.ProductNumber}</TableCell>
                            <TableCell>{item.ProductDescription}</TableCell>
                            <TableCell>{getCaseStatus(item.Status)}</TableCell>
                            <TableCell>{item.Description}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={cases.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="pagination-content"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default connector(Cases);
