import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { GetAttachment } from "../../services/CustomerService";
import { ApplicationState, User } from "../../types/Master";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image as ImageView,
  Font,
} from "@react-pdf/renderer";
import UseTranslation from "../../services/UseTranslation";
import { getLongDate } from "../../services/Shared";
import moment from "moment";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: require("../../fonts/Poppins-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../fonts/Poppins-Bold.ttf"),
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    fontFamily: "Poppins",
  },
  section: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 6,
    marginBottom: 6,
    padding: 8,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
});

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedRegisteredProduct: state.SelectedRegisteredProduct,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loginSuccess: (val: User) =>
      dispatch({ type: "LOGIN_SUCCESS", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ProofOfInstallationView = (props: PropsFromRedux) => {
  const { setLoader, selectedAccount, selectedRegisteredProduct } = props;
  const [isImgDataLoaded, setIsImgDataLoaded] = React.useState<boolean>(false);
  const [imgBase64String, setImgBase64String] = React.useState<string>("");
  const [dimension, setDimension] = React.useState<{
    height: number;
    width: number;
  }>({ height: 400, width: 420 });
  const [address, setAddress] = React.useState<string>("");

  React.useEffect(() => {
    let address = `${translation.address} : `;
    if (selectedAccount.Street != "") {
      address = address + selectedAccount.Street.trim() + " ";
    }
    if (selectedAccount.City != "") {
      address = address + selectedAccount.City.trim() + ", ";
    }
    if (selectedAccount.State != "") {
      address = address + selectedAccount.State.trim() + ", ";
    }
    if (selectedAccount.ZipCode != "") {
      address = address + selectedAccount.ZipCode.trim() + ", ";
    }
    address = address.substring(0, address.length - 2);
    setAddress(address);
  }, []);

  React.useEffect(() => {
    if (
      selectedRegisteredProduct.ImageOfInstallation != null &&
      selectedRegisteredProduct.ImageOfInstallation.length > 0
    ) {
      var image = new URLSearchParams(
        new URL(selectedRegisteredProduct.ImageOfInstallation).search
      ).get("name");
      setLoader(true);
      if (image != "") {
        GetAttachment(image)
          .then((blob) => {
            setLoader(false);
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              setImgBase64String(reader.result as string);
            };
            reader.onload = function () {
              var image = new Image();
              image.src = reader.result as string;
              image.onload = function () {
                setDimension({
                  height: image.height < 400 ? image.height : 400,
                  width: image.width,
                });
              };
            };
            setIsImgDataLoaded(true);
          })
          .catch((ex) => {
            setLoader(false);
            console.error(ex);
          });
      }
    } else {
      setIsImgDataLoaded(true);
    }
  }, []);

  return isImgDataLoaded ? (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{ display: "flex", alignItems: "center", marginTop: 16 }}
          >
            <ImageView
              style={{
                width: "150",
              }}
              src={require("../../images/solmetex.jpg")}
            />
          </View>
          <View style={{ margin: 4, display: "flex", alignItems: "center" }}>
            <Text style={{ fontSize: 30 }}>
              {translation.proofofInstallation}
            </Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: 12 }}>{`${
              translation.purchasedate
            } : ${getLongDate(selectedRegisteredProduct.PurchaseDate)}`}</Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >{`${translation.installationdate} : ${getLongDate(
              props.selectedRegisteredProduct.InstallationDate
            )}`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
              display: "flex",
            }}
          >
            <Text style={{ fontSize: 12 }}>{`${translation.dealer} : ${
              props.selectedRegisteredProduct.DealerName
                ? props.selectedRegisteredProduct.DealerName
                : ""
            }`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{ fontSize: 12 }}
            >{`${translation.product} : ${props.selectedRegisteredProduct.ProductDescription}`}</Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >{`${translation.serialNumber} : ${props.selectedRegisteredProduct.SerialNumber}`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
            }}
          >
            <Text
              style={{ fontSize: 12 }}
            >{`${translation.customerid} : ${selectedAccount.Number}`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
            }}
          >
            <Text style={{ fontSize: 12 }}>{address}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              width: "50%",
              alignSelf: "flex-end",
              alignItems: "flex-end",
              border: "1px",
            }}
          >
            <Text style={{ fontSize: 12 }}>{`Date : ${moment().format(
              "MMMM DD, YYYY"
            )}`}</Text>
          </View>
          {imgBase64String != "" && (
            <View
              style={{
                ...styles.section,
                maxHeight: `${dimension.height}px`,
                maxWidth: `${dimension.width}px`,
              }}
            >
              <ImageView src={imgBase64String} />
            </View>
          )}
          <View
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              bottom: "0",
              width: "100%",
              padding: "10px",
            }}
          >
            <Text
              style={{
                fontSize: 8,
                width: "100%",
                padding: "4px",
                border: "1px",
                textAlign: "center",
              }}
            >
              50 Bearfoot Road - Northborough, MA 01532 - Phone: 508-393-5115 -
              Fax: 508-393-1795 - Web: www.solmetex.com
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  ) : (
    <React.Fragment />
  );
};

export default connector(ProofOfInstallationView);
