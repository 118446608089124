import React from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import { ApplicationState } from "../../types/Master";
import Footer from "./Footer";

const Layout = (props: any) => {
  const pathName = window.location.pathname;
  if (
    pathName != null &&
    (pathName.toLocaleLowerCase() == "/attachment" ||
      pathName.toLocaleLowerCase() == "/viewproof" ||
      pathName.toLocaleLowerCase() == "/viewcertificate" ||
      pathName.toLocaleLowerCase() == "/viewshippinglabel")
  ) {
    return (
      <React.Fragment>
        <Container>{props.children}</Container>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <NavMenu />
        <Container>{props.children}</Container>
        <Footer />
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state: ApplicationState) => ({
  isAuthUser: state.isAuthUser,
  isLoading: state.isLoading,
});

export default connect(mapStateToProps)(Layout);
