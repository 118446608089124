import { Exposure } from "@material-ui/icons";
import {
  Account,
  Toast,
  User,
  RegisteredProduct,
  RecycledCerticate,
} from "./Master";

export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_LOADER = "SET_LOADER";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const SET_TOAST_MESSAGE = "SET_TOAST_MESSAGE";
export const SET_CONTACT = "SET_CONTACT";
export const SET_SHOW_SELECTED_ACCOUNT_DATA = "SET_SHOW_SELECTED_ACCOUNT_DATA";
export const SET_IS_CONTACT_ACCOUNTS_LOADED = "SET_IS_CONTACT_ACCOUNTS_LOADED";
export const SET_SELECTED_REGISTEREDPRODUCT = "SET_SELECTED_REGISTEREDPRODUCT";
export const SET_SELECTED_RECYCLEDCERTIFICATE =
  "SET_SELECTED_RECYCLEDCERTIFICATE";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SET_IS_WOOCOMMERCE_REDIRECTION_REQUIRED =
  "SET_IS_WOOCOMMERCE_REDIRECTION_REQUIRED";
export const SET_COMPLIANCE_STATUS_TYPE = "SET_COMPLIANCE_STATUS_TYPE";
export const SET_DISPLAY_ACCOUNTS_DATA = "SET_DISPLAY_ACCOUNTS_DATA";
export const SET_DSO_DETAILS = "SET_DSO_DETAILS";
export const SET_SHIPPING_LABEL = "SET_SHIPPING_LABEL";
export const SET_SELECTED_ACCOUNT_CONTACT = "SET_SELECTED_ACCOUNT_CONTACT";

interface LoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: User;
}
interface SetToastMessage {
  type: typeof SET_TOAST_MESSAGE;
  payload: Toast;
}
interface SetSelectedAccount {
  type: typeof SET_SELECTED_ACCOUNT;
  payload: Account;
}
interface SetSelectedAccountContact {
  type: typeof SET_SELECTED_ACCOUNT_CONTACT;
  payload: User;
}
interface Logout {
  type: typeof LOGOUT;
  payload: undefined;
}
interface SetLoader {
  type: typeof SET_LOADER;
  payload: boolean;
}
interface SetContact {
  type: typeof SET_CONTACT;
  payload: User;
}
interface SetShowSelectedAccountData {
  type: typeof SET_SHOW_SELECTED_ACCOUNT_DATA;
  payload: boolean;
}
interface SetIsContactAccountsLoaded {
  type: typeof SET_IS_CONTACT_ACCOUNTS_LOADED;
  payload: boolean;
}
interface SetSelectedRegisteredProduct {
  type: typeof SET_SELECTED_REGISTEREDPRODUCT;
  payload: RegisteredProduct;
}
interface SetSelectedRecycledCertificate {
  type: typeof SET_SELECTED_RECYCLEDCERTIFICATE;
  payload: RecycledCerticate;
}
interface SetSelectedTab {
  type: typeof SET_SELECTED_TAB;
  payload: number;
}

interface SetWooCommerceRedirectionRequired {
  type: typeof SET_IS_WOOCOMMERCE_REDIRECTION_REQUIRED;
  payload: boolean;
}

interface SetComplianceStatusType {
  type: typeof SET_COMPLIANCE_STATUS_TYPE;
  payload: string;
}

interface SetDisplayAccountsData {
  type: typeof SET_DISPLAY_ACCOUNTS_DATA;
  payload: boolean;
}

interface SetDsoDetails {
  type: typeof SET_DSO_DETAILS;
  payload: { DsoAccounts: Account[]; DsoParentAccount: Account };
}

interface SetShippingLabel {
  type: typeof SET_SHIPPING_LABEL;
  payload: string;
}

export type action_types =
  | Logout
  | LoginSuccess
  | SetLoader
  | SetToastMessage
  | SetSelectedAccount
  | SetShowSelectedAccountData
  | SetContact
  | SetSelectedRegisteredProduct
  | SetIsContactAccountsLoaded
  | SetSelectedRecycledCertificate
  | SetSelectedTab
  | SetWooCommerceRedirectionRequired
  | SetComplianceStatusType
  | SetDisplayAccountsData
  | SetDsoDetails
  | SetShippingLabel
  | SetSelectedAccountContact;
