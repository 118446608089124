import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { ApplicationState, User } from "../../types/Master";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image as ImageView,
  Font,
} from "@react-pdf/renderer";
import UseTranslation from "../../services/UseTranslation";
import { getLongDate } from "../../services/Shared";
import moment from "moment";

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedRecycledCertificate: state.SelectedRecycledCertificate,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loginSuccess: (val: User) =>
      dispatch({ type: "LOGIN_SUCCESS", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: require("../../fonts/Poppins-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../fonts/Poppins-Bold.ttf"),
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    fontFamily: "Poppins",
  },
  section: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 6,
    marginBottom: 6,
    padding: 8,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
});

const translation = UseTranslation();

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const RecycledCertificatePDFView = (props: PropsFromRedux) => {
  const { selectedAccount, selectedRecycledCertificate } = props;
  const [address, setAddress] = React.useState<string>("");
  const [recycleraddress, setRecycleraddress] = React.useState<string>("NA");

  React.useEffect(() => {
    let address = `${translation.address} : `;
    if (selectedRecycledCertificate.Street1 != "") {
      address = address + selectedRecycledCertificate.Street1.trim() + " ";
    }
    if (selectedRecycledCertificate.Street2 != "") {
      address = address + selectedRecycledCertificate.Street2.trim() + " ";
    }
    if (selectedRecycledCertificate.Street3 != "") {
      address = address + selectedRecycledCertificate.Street3.trim() + " ";
    }
    if (selectedRecycledCertificate.City != "") {
      address = address + selectedRecycledCertificate.City.trim() + ", ";
    }
    if (selectedRecycledCertificate.State != "") {
      address = address + selectedRecycledCertificate.State.trim() + ", ";
    }
    if (selectedRecycledCertificate.ZipCode != "") {
      address = address + selectedRecycledCertificate.ZipCode.trim() + ", ";
    }
    address = address.substring(0, address.length - 2);
    setAddress(address);

    let recycleraddress = "";
    if (selectedRecycledCertificate.Recycler.Address != null) {
      recycleraddress =
        recycleraddress +
        selectedRecycledCertificate.Recycler.Address.trim() +
        " ";
    }
    if (selectedRecycledCertificate.Recycler.City != null) {
      recycleraddress =
        recycleraddress +
        selectedRecycledCertificate.Recycler.City.trim() +
        ", ";
    }
    if (selectedRecycledCertificate.Recycler.Statecode != null) {
      recycleraddress =
        recycleraddress +
        selectedRecycledCertificate.Recycler.Statecode.trim() +
        ", ";
    }
    if (selectedRecycledCertificate.Recycler.Postcode != null) {
      recycleraddress =
        recycleraddress +
        selectedRecycledCertificate.Recycler.Postcode.trim() +
        ", ";
    }
    if (recycleraddress != "") {
      recycleraddress = recycleraddress.substring(
        0,
        recycleraddress.length - 2
      );
      setRecycleraddress(recycleraddress);
    }
  }, []);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{ display: "flex", alignItems: "center", marginTop: 16 }}
          >
            <ImageView
              style={{
                width: "150",
              }}
              src={require("../../images/solmetex.jpg")}
            />
          </View>
          <View style={{ margin: 4, display: "flex", alignItems: "center" }}>
            <Text style={{ fontSize: 30 }}>
              {translation.certificateofReceipt}
            </Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
            }}
          >
            <Text style={{ fontSize: 12 }}>{`${
              translation.deliverydate
            } : ${getLongDate(
              selectedRecycledCertificate.DeliveryDate
            )}`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
              display: "flex",
            }}
          >
            <Text style={{ fontSize: 12 }}>{`${translation.trackingnumber} : ${
              selectedRecycledCertificate.TrackingNumber
                ? selectedRecycledCertificate.TrackingNumber
                : ""
            }`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{ fontSize: 12 }}
            >{`${translation.quantity} : ${selectedRecycledCertificate.Quantity}`}</Text>
            <Text
              style={{
                fontSize: 12,
              }}
            >
              {`${translation.weight} : ${selectedRecycledCertificate.Weight}`}{" "}
              lbs
            </Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "50%" }}>
              <Text
                style={{ fontSize: 12 }}
              >{`${translation.description} : ${selectedRecycledCertificate.Product.Description}`}</Text>
            </View>
            <View
              style={{ width: "50%", display: "flex", alignItems: "flex-end" }}
            >
              <Text
                style={{
                  fontSize: 12,
                }}
              >{`${translation.pn} : ${selectedRecycledCertificate.Product.Number}`}</Text>
            </View>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
            }}
          >
            <Text
              style={{ fontSize: 12 }}
            >{`${translation.material} : ${selectedRecycledCertificate.Material}`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
            }}
          >
            <Text
              style={{ fontSize: 12 }}
            >{`${translation.certificate} : ${selectedRecycledCertificate.CertificateName}`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              border: "1px",
            }}
          >
            <Text
              style={{ fontSize: 12 }}
            >{`${translation.customerid} : ${selectedAccount.Number}`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              width: "50%",
              alignSelf: "flex-end",
              alignItems: "flex-end",
              border: "1px",
            }}
          >
            <Text style={{ fontSize: 12, textAlign: "right" }}>
                  {selectedRecycledCertificate.OrginalAccountName}
            </Text>
          </View>
          <View
            style={{
              ...styles.section,
              width: "50%",
              alignSelf: "flex-end",
              alignItems: "flex-end",
              border: "1px",
            }}
          >
            <Text style={{ fontSize: 12, textAlign: "right" }}>{address}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              width: "50%",
              alignSelf: "flex-end",
              alignItems: "flex-end",
              border: "1px",
            }}
          >
            <Text
              style={{ fontSize: 12, textAlign: "right" }}
            >{`Date : ${moment().format("MMMM DD, YYYY")}`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              width: "50%",
              alignSelf: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Text style={{ fontSize: 12, textAlign: "right" }}>{`Recycler : ${
              selectedRecycledCertificate.Recycler.Name
                ? selectedRecycledCertificate.Recycler.Name
                : "NA"
            }`}</Text>
          </View>
          <View
            style={{
              ...styles.section,
              width: "50%",
              alignSelf: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Text style={{ fontSize: 12, textAlign: "right" }}>
              {`${translation.address} : ${recycleraddress}`}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              bottom: "0",
              width: "100%",
              padding: "10px",
            }}
          >
            <Text
              style={{
                fontSize: 8,
                width: "100%",
                padding: "4px",
                border: "1px",
                textAlign: "center",
              }}
            >
              50 Bearfoot Road - Northborough, MA 01532 - Phone: 508-393-5115 -
              Fax: 508-393-1795 - Web: www.solmetex.com
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default connector(RecycledCertificatePDFView);
