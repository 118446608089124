import * as React from "react";
import { AnyAction, Dispatch } from "redux";
import UseTranslation from "../../services/UseTranslation";
import {
  Account,
  ApplicationState,
  ComplianceStatus,
  User,
} from "../../types/Master";
import { connect, ConnectedProps } from "react-redux";
import {
  Box,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { Link, Redirect, useHistory } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import {
  EnhancedTableHead,
  getComparator,
  HeadCell,
  OrderType,
  stableSort,
} from "../shared/Table";
import {
  generatePDF,
  generateExcel,
  getFormatedDateForDisplay,
  getComplianceStatus,
  getStatusValue,
} from "../../services/Shared";
import { Close } from "@material-ui/icons";
import { GetContactWithAccounts } from "../../services/CustomerService";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
  dsoAccounts: state.DsoAccounts,
  dsoParentAccount: state.DsoParentAccount,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setSelectedAccount: (val: Account) =>
      dispatch({ type: "SET_SELECTED_ACCOUNT", payload: val }),
    setShowSelectedAccountData: (val: boolean) =>
      dispatch({ type: "SET_SHOW_SELECTED_ACCOUNT_DATA", payload: val }),
    setContact: (val: User) => dispatch({ type: "SET_CONTACT", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setIsContactAccountsLoaded: (val: boolean) =>
      dispatch({ type: "SET_IS_CONTACT_ACCOUNTS_LOADED", payload: val }),
    setSelectedTab: (val: number) =>
      dispatch({ type: "SET_SELECTED_TAB", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface OwnProps {
  complianceStatusType: string;
}

export type AccountsProps = PropsFromRedux & OwnProps;

const headCells: HeadCell[] = [
  {
    id: "LastRecycleDate",
    numeric: false,
    disableSorting: false,
    label: translation.lastRecycleDate,
  },
  {
    id: "Region",
    numeric: false,
    disableSorting: false,
    label: translation.region,
  },
  {
    id: "SubRegion",
    numeric: false,
    disableSorting: false,
    label: translation.subregion,
  },
  {
    id: "AccountName",
    numeric: false,
    disableSorting: false,
    label: translation.accountName,
  },
  {
    id: "AccountNumber",
    numeric: false,
    disableSorting: false,
    label: translation.accountNumber,
  },
  {
    id: "Street",
    numeric: false,
    disableSorting: false,
    label: translation.street,
  },
  {
    id: "City",
    numeric: false,
    disableSorting: false,
    label: translation.city,
  },
  {
    id: "State",
    numeric: false,
    disableSorting: false,
    label: translation.stateProvince,
  },
  {
    id: "Status",
    numeric: false,
    disableSorting: false,
    label: translation.status,
  },
  {
    id: "LocationId",
    numeric: false,
    disableSorting: false,
    label: translation.locationid,
  },
  {
    id: "Email",
    numeric: false,
    disableSorting: false,
    label: translation.email,
  },
  {
    id: "MaintenancePlans",
    numeric: false,
    disableSorting: false,
    label: translation.subscriptionService,
  },
];

const Accounts = (props: AccountsProps) => {
  const {
    user,
    isContactAccountsLoaded,
    isLoading,
    complianceStatusType,
    dsoAccounts,
    dsoParentAccount,
    setLoader,
    setContact,
    setSelectedAccount,
    setIsContactAccountsLoaded,
    setShowSelectedAccountData,
    setSelectedTab,
  } = props;
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  const [totalAccounts, setTotalAccounts] = React.useState<Account[]>([]);
  const [order, setOrder] = React.useState<OrderType>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("AccountNumber");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = React.useState<string>("");
  const [activeCard, setActiveCard] = React.useState<ComplianceStatus>(
    ComplianceStatus.None
  );
  const history = useHistory();
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);
  const [popUpContent, setPopUpContent] = React.useState<string>("");
  const [popUpTitle, setPopUpTitle] = React.useState<string>("");

  const handleSuggestions = (e: any, type: string) => {
    e.preventDefault();
    if (translation.incompliance == type) {
      setPopUpTitle(type);
      setPopUpContent(translation.tooltipInCompliance);
    } else if (translation.aproachingDeadline == type) {
      setPopUpTitle(type);
      setPopUpContent(translation.tooltipAproachingDeadline);
    } else if (translation.outOfCompliance == type) {
      setPopUpTitle(type);
      setPopUpContent(translation.tooltipOutOfCompliance);
    } else if (translation.noProductReported == type) {
      setPopUpTitle(type);
      setPopUpContent(translation.tooltipNoProductReported);
    }
    setIsEditOpen(true);
  };

  const handleClose = () => {
    setIsEditOpen(false);
  };

  React.useEffect(() => {
    setAccounts([...dsoAccounts]);
    setTotalAccounts([...dsoAccounts]);
  }, [dsoAccounts]);

  React.useEffect(() => {
    if (!isContactAccountsLoaded && user.ID != "") {
      setLoader(true);
      GetContactWithAccounts(user.ID)
        .then((user: User) => {
          setContact(user);
          setIsContactAccountsLoaded(true);
          setLoader(false);
        })
        .catch((ex: any) => {
          console.error(ex);
          setLoader(false);
        });
    }
  }, [isContactAccountsLoaded]);

  const requestSearch = (searchedVal: string) => {
    let listOfWords = searchedVal.split(" ");
    let filteredRows: Account[] = [];
    totalAccounts.forEach((item) => {
      let isAllWordsPresent = false;
      if (
        getComplianceStatus(
          getStatusValue(complianceStatusType, item)
        ).toLowerCase() == searchedVal.toLowerCase()
      ) {
        isAllWordsPresent = true;
      } else {
        listOfWords.every((word: string) => {
          if (
            item.Name.toLowerCase().includes(word.toLowerCase()) ||
            (item.RegionalManager.Name != null &&
              item.RegionalManager.Name.toLowerCase().includes(
                word.toLowerCase()
              )) ||
            (item.SubRegionalManager.Name != null &&
              item.SubRegionalManager.Name.toLowerCase().includes(
                word.toLowerCase()
              )) ||
            item.Number.toLowerCase().includes(word.toLowerCase()) ||
            item.Street.toLowerCase().includes(word.toLowerCase()) ||
            item.City.toLowerCase().includes(word.toLowerCase()) ||
            item.State.toLowerCase().includes(word.toLowerCase()) ||
            item.LocationId.toLowerCase().includes(word.toLowerCase()) ||
            item.Email.toLowerCase().includes(word.toLowerCase()) ||
            getFormatedDateForDisplay(item.LastRecycleDate)
              .toLowerCase()
              .includes(word.toLowerCase())
          ) {
            isAllWordsPresent = true;
            return true;
          } else {
            isAllWordsPresent = false;
            return false;
          }
        });
      }
      if (isAllWordsPresent) filteredRows.push({ ...item });
    });
    setAccounts(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    handleCardClick(activeCard);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getComplianceClassName = (val: ComplianceStatus): string => {
    switch (val) {
      case ComplianceStatus.InCompliance:
        return "sx-bgGreen";
      case ComplianceStatus.ApproachingDeadline:
        return "sx-bgYellow";
      case ComplianceStatus.OutOfCompliance:
        return "sx-bgRed";
      default:
        return "";
    }
  };

  const downloadExcelOrPdf = (isPdf: boolean) => {
    let defaultcolumns = [
      "Last Recycle Date",
      "Region",
      "Sub Region",
      "Account Name",
      "Account Number",
      "Street",
      "City",
      "State",
      "Status",
      "Location Id",
      "Email",
      "Plans",
    ];
    const tableRows: any[] = [];
    accounts.forEach((item) => {
      let data = [
        getFormatedDateForDisplay(item.LastRecycleDate) != "01-01-0001"
          ? getFormatedDateForDisplay(item.LastRecycleDate)
          : "",
        item.RegionalManager.Name,
        item.SubRegionalManager.Name,
        item.Name,
        item.Number,
        item.Street,
        item.City,
        item.State,
        getComplianceStatus(getStatusValue(complianceStatusType, item)),
        item.LocationId,
        item.Email,
        item.MaintenancePlansCount,
      ];
      tableRows.push(data);
    });
    let columnSizes = {
      0: { cellWidth: 17 },
      1: { cellWidth: 16 },
      2: { cellWidth: 16 },
      3: { cellWidth: 18 },
      4: { cellWidth: 18 },
      5: { cellWidth: 17 },
      6: { cellWidth: 11 },
      7: { cellWidth: 12 },
      8: { cellWidth: 14 },
      9: { cellWidth: 18 },
      10: { cellWidth: 17 },
      11: { cellWidth: 13 },
    };
    if (isPdf)
      generatePDF(tableRows, defaultcolumns, translation.accounts, columnSizes);
    else generateExcel(tableRows, defaultcolumns, translation.accounts);
  };

  const handleCardClick = (value: ComplianceStatus) => {
    setPage(0);
    if (value == ComplianceStatus.HasMaintenancePlans) {
      const filteredRows = totalAccounts.filter((row) => {
        return row.MaintenancePlansCount > 0;
      });
      setAccounts([...filteredRows]);
      setActiveCard(value);
    } else if (value != ComplianceStatus.None) {
      const filteredRows = totalAccounts.filter((row) => {
        return getStatusValue(complianceStatusType, row) == value;
      });
      setAccounts([...filteredRows]);
      setActiveCard(value);
    } else {
      setAccounts([...totalAccounts]);
      setActiveCard(ComplianceStatus.None);
    }
  };

  const handleViewAccount = (account: Account) => {
    setSelectedAccount({ ...account });
    setSelectedTab(1);
    setShowSelectedAccountData(true);
    history.push("/");
  };

  if (!user.IsDsoLogin) {
    return <Redirect to="/" />;
  }

  return isLoading ? (
    <Backdrop
      style={{
        zIndex: 2250,
      }}
      open={isLoading}
    >
      <CircularProgress />
    </Backdrop>
  ) : (
    <Box className="mx-4 mx-xs-2">
      <h2>{complianceStatusType}</h2>
      <h5>{dsoParentAccount.Name}</h5>
      <div className="row mt-2 sx-accountsBoard">
        <div className="col sx-accountsBoard-card mb-xs-3">
          <div
            className={`card shadow-sm sx-accountsBoard-card-plain ${
              activeCard == ComplianceStatus.None && "active"
            }`}
          >
            <div className="position-relative">
              <div className="card-body">
                <h6 className="card-title">{translation.totalAccounts}</h6>
                <p className="card-text">{dsoAccounts.length}</p>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <img
                  src={require("../../images/group.png")}
                  alt="Solmetex"
                  className="w-24 sx-translate-y opacity-30"
                />
              </div>
            </div>
            <Button
              fullWidth
              variant="text"
              className="sx-button sx-bgDark2 sx-white sx-topLeftRightBorderRadius-0"
              onClick={() => handleCardClick(ComplianceStatus.None)}
            >
              {translation.view}
            </Button>
          </div>
        </div>
        <div className="col sx-accountsBoard-card mb-xs-3">
          <div
            className={`card shadow-sm sx-accountsBoard-card-plain ${
              activeCard == ComplianceStatus.HasMaintenancePlans && "active"
            }`}
          >
            <div className="position-relative">
              <div className="card-body">
                <h6 className="card-title">
                  {translation.subscriptionService}
                </h6>
                <p className="card-text">
                  {
                    totalAccounts.filter((row) => {
                      return row.MaintenancePlansCount > 0;
                    }).length
                  }
                </p>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <img
                  src={require("../../images/task.png")}
                  alt="Solmetex"
                  className="w-24 sx-translate-y opacity-30"
                />
              </div>
            </div>
            <Button
              fullWidth
              variant="text"
              className="sx-button sx-bgDark2 sx-white sx-topLeftRightBorderRadius-0"
              onClick={() =>
                handleCardClick(ComplianceStatus.HasMaintenancePlans)
              }
            >
              {translation.view}
            </Button>
          </div>
        </div>
        <div className="col sx-accountsBoard-card mb-xs-3">
          <div
            className={`card shadow-sm sx-accountsBoard-card-greenBg ${
              activeCard == ComplianceStatus.InCompliance && "active"
            }`}
          >
            <div
              className="sx-tipImg"
              onClick={(e) => handleSuggestions(e, translation.incompliance)}
            ></div>
            <div className="position-relative">
              <div className="card-body">
                <h6 className="card-title">{translation.incompliance}</h6>
                <p className="card-text sx-green">
                  {
                    totalAccounts.filter((row) => {
                      return (
                        getStatusValue(complianceStatusType, row) ==
                        ComplianceStatus.InCompliance
                      );
                    }).length
                  }
                </p>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <img
                  src={require("../../images/shield.png")}
                  alt="Solmetex"
                  className="w-24 sx-translate-y opacity-30"
                />
              </div>
            </div>
            <Button
              fullWidth
              variant="text"
              className="sx-button sx-bgGreen sx-white sx-topLeftRightBorderRadius-0"
              onClick={() => handleCardClick(ComplianceStatus.InCompliance)}
            >
              {translation.view}
            </Button>
          </div>
        </div>
        <div className="col sx-accountsBoard-card mb-xs-3">
          <div
            className={`card shadow-sm sx-accountsBoard-card-yellowBg ${
              activeCard == ComplianceStatus.ApproachingDeadline && "active"
            }`}
          >
            <div
              className="sx-tipImg"
              onClick={(e) =>
                handleSuggestions(e, translation.aproachingDeadline)
              }
            ></div>
            <div className="position-relative">
              <div className="card-body">
                <h6 className="card-title">{translation.aproachingDeadline}</h6>
                <p className="card-text sx-yellow">
                  {
                    totalAccounts.filter((row) => {
                      return (
                        getStatusValue(complianceStatusType, row) ==
                        ComplianceStatus.ApproachingDeadline
                      );
                    }).length
                  }
                </p>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <img
                  src={require("../../images/deadline.png")}
                  alt="Solmetex"
                  className="w-24 sx-translate-y opacity-30"
                />
              </div>
            </div>
            <Button
              fullWidth
              variant="text"
              className="sx-button sx-bgYellow sx-white sx-topLeftRightBorderRadius-0"
              onClick={() =>
                handleCardClick(ComplianceStatus.ApproachingDeadline)
              }
            >
              {translation.view}
            </Button>
          </div>
        </div>
        <div className="col sx-accountsBoard-card mb-xs-3">
          <div
            className={`card shadow-sm sx-accountsBoard-card-redBg ${
              activeCard == ComplianceStatus.OutOfCompliance && "active"
            }`}
          >
            <div
              className="sx-tipImg"
              onClick={(e) => handleSuggestions(e, translation.outOfCompliance)}
            ></div>
            <div className="position-relative">
              <div className="card-body">
                <h6 className="card-title">{translation.outOfCompliance}</h6>
                <p className="card-text sx-red">
                  {
                    totalAccounts.filter((row) => {
                      return (
                        getStatusValue(complianceStatusType, row) ==
                        ComplianceStatus.OutOfCompliance
                      );
                    }).length
                  }
                </p>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <img
                  src={require("../../images/out.png")}
                  alt="Solmetex"
                  className="w-24 sx-translate-y opacity-30"
                />
              </div>
            </div>
            <Button
              fullWidth
              variant="text"
              className="sx-button sx-bgRed sx-white sx-topLeftRightBorderRadius-0"
              onClick={() => handleCardClick(ComplianceStatus.OutOfCompliance)}
            >
              {translation.view}
            </Button>
          </div>
        </div>
        <div className="col sx-accountsBoard-card mb-xs-3">
          <div
            className={`card shadow-sm sx-accountsBoard-card-blueBg ${
              activeCard == ComplianceStatus.NoProductReported && "active"
            }`}
          >
            <div
              className="sx-tipImg"
              onClick={(e) =>
                handleSuggestions(e, translation.noProductReported)
              }
            ></div>
            <div className="position-relative">
              <div className="card-body">
                <h6 className="card-title">{translation.noProductReported}</h6>
                <p className="card-text sx-blue-02">
                  {
                    totalAccounts.filter((row) => {
                      return (
                        getStatusValue(complianceStatusType, row) ==
                        ComplianceStatus.NoProductReported
                      );
                    }).length
                  }
                </p>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <img
                  src={require("../../images/no-stopping.png")}
                  alt="Solmetex"
                  className="w-24 sx-translate-y opacity-30"
                />
              </div>
            </div>
            <Button
              fullWidth
              variant="text"
              className="sx-button sx-bgBlue sx-white sx-topLeftRightBorderRadius-0"
              onClick={() =>
                handleCardClick(ComplianceStatus.NoProductReported)
              }
            >
              {translation.view}
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h3>{translation.accounts}</h3>
      </div>
      <Dialog className="sx-model" open={isEditOpen} onClose={handleClose}>
        <DialogTitle className="sx-model-title">{popUpTitle}</DialogTitle>
        <Box position="absolute" top={8} right={8}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent
          className="my-4"
          dangerouslySetInnerHTML={{ __html: popUpContent }}
        ></DialogContent>
      </Dialog>
      <Box className="sx-table-shadow my-4 p-3">
        <Box className="d-flex justify-content-between align-items-center pb-3 sx-tableSearchContainer">
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            className="col-sm-5 px-0 sx-tableSearch shadow-none"
          />
          <Box className="d-flex justify-content-between align-items-center pt-1">
            <IconButton
              className="ml-1 p-0"
              onClick={() => downloadExcelOrPdf(true)}
            >
              <img
                src={require("../../images/pdf.png")}
                alt="Solmetex"
                className="img-fluid sx-iconButtonImg"
              />
            </IconButton>
            <IconButton
              className="p-0"
              onClick={() => downloadExcelOrPdf(false)}
            >
              <img
                src={require("../../images/xls.png")}
                alt="Solmetex"
                className="img-fluid sx-iconButtonImg"
              />
            </IconButton>
          </Box>
        </Box>
        <Box className="pb-3">
          <TableContainer>
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                rowCount={accounts.length}
                displayActionCell={true}
                onRequestSort={handleRequestSort}
              />
              <TableBody className="sx-tableBody">
                {stableSort(accounts, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((account) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <div className="d-flex align-items-center">
                            <div
                              className={`sx-accountStatus mr-2 ${getComplianceClassName(
                                getStatusValue(complianceStatusType, account)
                              )}`}
                            />
                            {getFormatedDateForDisplay(
                              account.LastRecycleDate
                            ) != "01-01-0001" &&
                              getFormatedDateForDisplay(
                                account.LastRecycleDate
                              )}
                          </div>
                        </TableCell>
                        <TableCell>{account.RegionalManager.Name}</TableCell>
                        <TableCell>{account.SubRegionalManager.Name}</TableCell>
                        <TableCell>{account.Name}</TableCell>
                        <TableCell>{account.Number}</TableCell>
                        <TableCell>{account.Street}</TableCell>
                        <TableCell>{account.City}</TableCell>
                        <TableCell>{account.State}</TableCell>
                        <TableCell>
                          {getComplianceStatus(
                            getStatusValue(complianceStatusType, account)
                          )}
                        </TableCell>
                        <TableCell>{account.LocationId}</TableCell>
                        <TableCell>{account.Email}</TableCell>
                        <TableCell>{account.MaintenancePlansCount}</TableCell>
                        <TableCell>
                          <Link
                            className="sx-button"
                            to="#"
                            onClick={() => handleViewAccount(account)}
                          >
                            {translation.view}
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={accounts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="pagination-content"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default connector(Accounts);
