import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  DialogActions,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import * as React from "react";
import { Backup, Close, Add } from "@material-ui/icons";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { GetDealerAccountsForPR, GetDealerApprovedForMP } from "../../services/CustomerService";
import { getfileType } from "../../services/Shared";
import UseTranslation from "../../services/UseTranslation";
import {
  Account,
  RegisteredProduct,
  ApplicationState,
  defaultRegisteredProduct,
  User,
  defaultProduct,
  defaultAccount,
  RegistrableProduct,
  defaultRegistrableProduct,
} from "../../types/Master";
import {
  CreateRegisteredProduct,
  GetProductsForRegistration,
} from "../../services/ProductService";
import validator from "validator";
import { Autocomplete } from "@material-ui/lab";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedAccountContact: state.SelectedAccountContact,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loginSuccess: (val: User) =>
      dispatch({ type: "LOGIN_SUCCESS", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

interface errorValidation {
  product: string;
  dealer: string;
  email: string;
}

const defaultErrorValidation = {
  product: "",
  dealer: "",
  email: "",
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface OwnProps {
  getRegisteredProducts: () => void;
}

export type ddRegisteredProductsProps = PropsFromRedux & OwnProps;

const AddRegisteredProduct = (props: ddRegisteredProductsProps) => {
  const {
    isLoading,
    setLoader,
    setToastMessage,
    getRegisteredProducts,
    selectedAccount,
    user,
    selectedAccountContact,
  } = props;
  const [isAddNew, setIsAdNew] = React.useState<boolean>(false);
  const [registeredProduct, setRegisteredProduct] =
    React.useState<RegisteredProduct>(defaultRegisteredProduct);
  const [registrableProducts, setRegistrableProducts] = React.useState<
    RegistrableProduct[]
  >([]);
  const [registrableProduct, setRegistrableProduct] =
    React.useState<RegistrableProduct>(defaultRegistrableProduct);
  const [dealerAccounts, setDealerAccounts] = React.useState<Account[]>([]);
  const [files, setFiles] = React.useState<FileList | null>();
  const [purchaseDate, setPurchaseDate] = React.useState<string>("");
  const [installationDate, setInstallationDate] = React.useState<string>("");
  const [displaySerialNumber, setDisplaySerialNumber] =
    React.useState<boolean>(false);
  const [displayLotNumber, setDisplayLotNumber] =
    React.useState<boolean>(false);
  const [displayUploadImage, setDisplayUploadImage] =
    React.useState<boolean>(false);
  const [displayBottleSize, setDisplayBottleSize] =
    React.useState<boolean>(false);
  const [displayNumberOfBottles, setDisplayNumberOfBottles] =
    React.useState<boolean>(false);
  const [displayNumberOfChairs, setDisplayNumberOfChairs] =
    React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<errorValidation>({
    ...defaultErrorValidation,
  });

  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(event.target.files);
  };

  const handleSelectedProduct = (Id: string) => {
    registrableProducts.every((item) => {
      if (item.ID == Id) {
        setRegisteredProduct({
          ...registeredProduct,
          Product: { ...item },
        });
        setRegistrableProduct({ ...item });
        return false;
      } else return true;
    });
  };

  React.useEffect(() => {
    GetProductsForRegistration()
      .then((registrableProducts: RegistrableProduct[]) => {
        setRegistrableProducts(registrableProducts);
      })
      .catch((ex) => {
        console.error(ex);
      });
      GetDealerApprovedForMP()
      .then((accounts: Account[]) => {
        setDealerAccounts(accounts);
      })
      .catch((ex) => {
        console.error(ex);
      });

    setRegisteredProduct({
      ...registeredProduct,
      Account: { ...defaultAccount, ID: selectedAccount.ID },
    });
  }, []);

  React.useEffect(() => {
    let currentday = moment().get("date");
    let nextDate: moment.Moment;
    let now: moment.Moment;
    now = moment();
    setPurchaseDate(now.format("YYYY-MM-DD"));
    setInstallationDate(now.format("YYYY-MM-DD"));
    setRegisteredProduct({
      ...registeredProduct,
      Account: { ...defaultAccount, ID: selectedAccount.ID },
      PurchaseDate: now.format("YYYY-MM-DD"),
      InstallationDate: now.format("YYYY-MM-DD"),
    });
  }, [selectedAccount]);

  const handleAddNew = (e: any) => {
    e.preventDefault();
    setIsAdNew(true);
  };

  const handleClose = () => {
    setIsAdNew(false);
  };

  const errorValidation = (request: RegisteredProduct): boolean => {
    let errorsObject = { ...defaultErrorValidation };
    let isValid = true;
    if (request.Product.ID == "") {
      errorsObject = { ...errorsObject, product: translation.requiredField };
      isValid = false;
    }
    if (request.Dealer.ID == "") {
      errorsObject = { ...errorsObject, dealer: translation.requiredField };
      isValid = false;
    }
    if (
      request.DealerRepEmail != "" &&
      !validator.isEmail(request.DealerRepEmail)
    ) {
      errorsObject = { ...errorsObject, email: translation.emailValidation };
      isValid = false;
    }
    setErrors({ ...errorsObject });
    return isValid;
  };

  const setToDefaults = () => {
    setRegisteredProduct(defaultRegisteredProduct);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var ContactID = user.ID;
    if (selectedAccountContact != null && selectedAccountContact.ID != "") {
      ContactID = selectedAccountContact.ID;
    }
    if (errorValidation(registeredProduct)) {
      setLoader(true);
      var replacementFrequency = registrableProducts.filter(
        (x) => x.ID == registeredProduct.Product.ID
      )[0].ReplacementFrequency;
      var productName = registrableProducts.filter(
        (x) => x.ID == registeredProduct.Product.ID
      )[0].Name;
      data.append("AccountId", selectedAccount.ID);
      data.append("ProductID", registeredProduct.Product.ID);
      data.append("DealerID", registeredProduct.Dealer.ID);
      data.append("ReplacementFrequency", replacementFrequency.toString());
      data.append("ProductName", productName);
      data.append("ContactID", ContactID);
      data.append("AccountNumber", selectedAccount.Number);

      if (files && files.length > 0) {
        let file = files[0];
        let fileName = file.name;
        let extension = fileName.split(".").pop();
        let fileType = extension ? getfileType(extension) : "";
        data.append("fileName", fileName);
        data.append("file", file);
        data.append("fileType", fileType);
      }

      CreateRegisteredProduct(data)
        .then((res) => {
          setToDefaults();
          setFiles(null);
          setIsAdNew(false);
          setLoader(false);
          setToastMessage(translation.registrationProductSuccess, true);
          getRegisteredProducts();
        })
        .catch((ex) => {
          setToDefaults();
          setFiles(null);
          setLoader(false);
          setIsAdNew(false);
          setToastMessage(translation.registrationProductFailure, false);
          console.error(ex);
        });
    }
  };

  return (
    <Box>
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      <Dialog
        className="sx-model"
        open={isAddNew}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle className="sx-model-title  sx-border-bottom">
            {translation.newProductRegistration}
          </DialogTitle>
          <Box position="absolute" top={8} right={8}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent className="mb-4 sx-addRegistrationDialog px-3">
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Autocomplete
                value={registeredProduct.Product}
                onChange={(event, newValue) => {
                  handleSelectedProduct(newValue ? newValue.ID : "");
                }}
                id="select-product"
                options={registrableProducts}
                getOptionLabel={(option) =>
                  option.QuoteProductName != ""
                    ? `${option.Number} - ${option.QuoteProductName}`
                    : option.Number
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translation.selectProduct}
                    variant="filled"
                    className="sx-input"
                    InputLabelProps={{ className: "sx-input-label" }}
                  />
                )}
              />
              <FormHelperText error className="error-text font-10 text-left">
                {errors.product}
              </FormHelperText>
            </FormControl>
            <TextField
              className="sx-inputGroup"
              margin="normal"
              fullWidth
              id="quantity"
              name="Quantity"
              type="number"
              label={translation.quantity}
              onChange={(e) => {
                setRegisteredProduct({
                  ...registeredProduct,
                  Quantity: Number(e.target.value as string),
                });
              }}
              value={registeredProduct.Quantity}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
                inputProps: { min: 1 },
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />

            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-distributor"
                className={`sx-input ${
                  errors && errors.dealer && "sx-validationFailed"
                }`}
                disableUnderline={true}
                value={registeredProduct.Dealer.ID}
                onChange={(e) =>
                  setRegisteredProduct({
                    ...registeredProduct,
                    Dealer: {
                      ...defaultAccount,
                      ID: e.target.value as string,
                    },
                  })
                }
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.selectDealer}
                </MenuItem>
                {dealerAccounts &&
                  dealerAccounts.length > 0 &&
                  dealerAccounts.map((item) => {
                    return <MenuItem value={item.ID}>{item.Name}</MenuItem>;
                  })}
              </Select>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.dealer}
              </FormHelperText>
            </FormControl>

            <TextField
              className="sx-inputGroup"
              margin="normal"
              fullWidth
              id="dealerrepname"
              name="DealerRepName"
              label={translation.dealerrepname}
              onChange={(e) => {
                setRegisteredProduct({
                  ...registeredProduct,
                  DealerRepName: e.target.value as string,
                });
              }}
              value={registeredProduct.DealerRepName}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />

            <TextField
              className={`sx-inputGroup ${
                errors && errors.email && "sx-validationFailed"
              }`}
              margin="normal"
              fullWidth
              id="dealerRepEmail"
              name="DealerRepEmail"
              label={translation.dealerrepemail}
              onChange={(e) => {
                setRegisteredProduct({
                  ...registeredProduct,
                  DealerRepEmail: e.target.value as string,
                });
              }}
              value={registeredProduct.DealerRepEmail}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
            <div className="error-text font-10 pl-4 text-left">
              {errors.email}
            </div>
            <TextField
              className="sx-inputGroup"
              margin="normal"
              fullWidth
              id="dealerRepPhoneNumber"
              name="DealerRepPhoneNumber"
              label={translation.dealerrepphone}
              onChange={(e) => {
                setRegisteredProduct({
                  ...registeredProduct,
                  DealerRepPhoneNumber: e.target.value as string,
                });
              }}
              value={registeredProduct.DealerRepPhoneNumber}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
            {registrableProduct.DisplaySerialNumber && (
              <TextField
                className="sx-inputGroup"
                margin="normal"
                fullWidth
                id="serialnumber"
                name="SerialNumber"
                label={translation.serialNumber}
                onChange={(e) => {
                  setRegisteredProduct({
                    ...registeredProduct,
                    SerialNumber: e.target.value as string,
                  });
                }}
                value={registeredProduct.SerialNumber}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
            )}
            {registrableProduct.DisplayLotNumber && (
              <TextField
                className="sx-inputGroup"
                margin="normal"
                fullWidth
                id="lotnumber"
                name="LotNumber"
                label={translation.lotnumber}
                onChange={(e) => {
                  setRegisteredProduct({
                    ...registeredProduct,
                    LotNumber: e.target.value as string,
                  });
                }}
                value={registeredProduct.LotNumber}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
            )}

            <TextField
              className="sx-inputGroup mb-2"
              fullWidth
              id="purchaseDate"
              name="PurchaseDate"
              label={translation.purchasedate}
              type="date"
              defaultValue={moment().format("yyyy-mm-dd")}
              onChange={(e) => {
                setRegisteredProduct({
                  ...registeredProduct,
                  PurchaseDate: e.target.value as string,
                });
              }}
              value={registeredProduct.PurchaseDate}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />

            <TextField
              className="sx-inputGroup mb-2"
              fullWidth
              id="installationDate"
              name="InstallationDate"
              label={translation.installationdate}
              type="date"
              defaultValue={moment().format("yyyy-mm-dd")}
              onChange={(e) => {
                setRegisteredProduct({
                  ...registeredProduct,
                  InstallationDate: e.target.value as string,
                });
              }}
              value={registeredProduct.InstallationDate}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
            {registrableProduct.DisplayUploadImage && (
              <Box className="sx-inputGroup w-96">
                <Button
                  variant="outlined"
                  component="label"
                  className="upload-file w-100"
                  startIcon={<Backup />}
                >
                  {"Attachments"}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleFilesAdded(e)}
                    hidden
                  />
                </Button>
                {files && (
                  <Typography className="small">{files[0].name}</Typography>
                )}
              </Box>
            )}
            {registrableProduct.DisplayBottleSize && (
              <TextField
                className="sx-inputGroup"
                margin="normal"
                fullWidth
                id="bottlesize"
                name="BottleSize"
                label={translation.bottlesize}
                type="number"
                onChange={(e) => {
                  setRegisteredProduct({
                    ...registeredProduct,
                    BottleSize: Number(e.target.value as string),
                  });
                }}
                value={registeredProduct.BottleSize}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
            )}
            {registrableProduct.DisplayNumberOfBottles && (
              <TextField
                className="sx-inputGroup"
                margin="normal"
                fullWidth
                id="numberofbottles"
                name="NumberofBottles"
                label={translation.numberofbottles}
                type="number"
                onChange={(e) => {
                  setRegisteredProduct({
                    ...registeredProduct,
                    NumberofBottles: Number(e.target.value as string),
                  });
                }}
                value={registeredProduct.NumberofBottles}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
            )}
            {registrableProduct.DisplayNumberOfChairs && (
              <TextField
                className="sx-inputGroup"
                margin="normal"
                fullWidth
                id="numberofchairs"
                name="NumberofChairs"
                label={translation.numberofChairs}
                type="number"
                onChange={(e) => {
                  setRegisteredProduct({
                    ...registeredProduct,
                    NumberofChairs: Number(e.target.value as string),
                  });
                }}
                value={registeredProduct.NumberofChairs}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                  inputProps: { min: 0 },
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
            )}
          </DialogContent>
          <DialogActions className="px-4 py-3 sx-border-top">
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              className="sx-button"
            >
              {translation.submit}
            </Button>
            <Button
              variant="text"
              className="sx-button sx-textButton"
              onClick={handleClose}
            >
              {translation.cancel}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        variant="contained"
        className="ml-2 sx-button p-0"
        onClick={handleAddNew}
      >
        <Add />
        <span className="sx-hideOnMobile">{translation.add}</span>
      </Button>
    </Box>
  );
};

export default connector(AddRegisteredProduct);
