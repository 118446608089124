import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import {
  ApplicationState,
  WoocommerceRequest,
  defaultWoocommerceRequest,
} from "../../types/Master";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import UseTranslation from "../../services/UseTranslation";
import { GetAutoLoginUrl } from "../../services/CustomerService";
import { Redirect } from "react-router-dom";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedAccountContact: state.SelectedAccountContact,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
  showSelectedAccountData: state.ShowSelectedAccountData,
  isLoading: state.isLoading,
  isWooCommerceRedirectionRequired: state.WooCommerceRedirectionRequired,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Home = (props: PropsFromRedux) => {
  const {
    user,
    isContactAccountsLoaded,
    selectedAccount,
    selectedAccountContact,
    showSelectedAccountData,
    isWooCommerceRedirectionRequired,
    isLoading,
    setLoader,
    setToastMessage,
  } = props;

  if (isContactAccountsLoaded && user.IsDsoLogin && !showSelectedAccountData) {
    return <Redirect to="/dashboard" />;
  }

  const [wooCommerceRequest, setWoocommerceRequest] =
    React.useState<WoocommerceRequest>(defaultWoocommerceRequest);

  React.useEffect(() => {
    var contact = user;
    if (selectedAccountContact != null && selectedAccountContact.ID != "") {
      contact = selectedAccountContact;
    }
    if (selectedAccount && selectedAccount.ID != "") {
      setWoocommerceRequest({
        ...wooCommerceRequest,
        Account: { ...selectedAccount },
        Contact: { ...contact },
      });
    }
  }, [selectedAccount, selectedAccountContact]);

  const handleOrderNow = (e: any) => {
    e.preventDefault();
    getAutoLoginUrl();
  };

  const getAutoLoginUrl = (): Promise<void> => {
    setLoader(true);
    return new Promise((resolve, reject) => {
      GetAutoLoginUrl(wooCommerceRequest)
        .then((res: any) => {
          if (res.status == "success") {
            window.open(res.data, "_blank", "noreferrer");
          } else {
            setToastMessage(translation.woocommerceredirectionissue, false);
          }
          resolve();
          setLoader(false);
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          setToastMessage(translation.woocommerceredirectionissue, false);
          reject();
        });
    });
  };

  return isWooCommerceRedirectionRequired ? (
    <React.Fragment>
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={true}
      >
        <CircularProgress />
      </Backdrop>
    </React.Fragment>
  ) : (
    <Box>
      {!isContactAccountsLoaded || isLoading ? (
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={!isContactAccountsLoaded || isLoading}
        >
          <CircularProgress />
        </Backdrop>
      ) : (
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            className="sx-colorBg-grey-08 px-4 pt-4"
          >
            <Box>
              <Link
                className="my-3 rounded-pill btn-green text-uppercase"
                to="/shippinglabel"
                aria-disabled
              >
                {translation.printRecyclingLabel}
              </Link>
              {selectedAccount.AllowShopFromPortal ? (
                <Button
                  type="button"
                  fullWidth
                  className="rounded-pill btn-outline-blue text-uppercase"
                  onClick={handleOrderNow}
                >
                  {translation.orderNow}
                </Button>
              ) : (
                <Tooltip title={translation.placeOrderValidation}>
                  <div>
                    <a
                      className="rounded-pill btn-green text-uppercase btn-disabled"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      {translation.orderNow}
                    </a>
                  </div>
                </Tooltip>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={10} className="pt-3">
            <img
              src={require("../../images/Solmetex_CompliancePortal-background.jpg")}
              alt="Solmetex"
              className="img-fluid home-banner"
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default connector(Home);
