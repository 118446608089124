import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import UseTranslation from "../../services/UseTranslation";
import {
  Account,
  ApplicationState,
  defaultAccount,
  initialUserState,
  User,
} from "../../types/Master";
import { AnyAction, Dispatch } from "redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import {
  UpdateAccountDefaultDealer,
  UpdateAccountPrimaryContact,
} from "../../services/ProductService";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    setSelectedAccount: (val: Account) =>
      dispatch({ type: "SET_SELECTED_ACCOUNT", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface OwnProps {
  isEditManager: boolean;
  contacts: User[];
  dealers: Account[];
}

export type EditManagerAndDistributorProps = PropsFromRedux & OwnProps;

const EditManagerAndDistributor = (props: EditManagerAndDistributorProps) => {
  const {
    user,
    contacts,
    isLoading,
    isEditManager,
    dealers,
    selectedAccount,
    setSelectedAccount,
    setLoader,
    setToastMessage,
  } = props;
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);
  const [updatePrimaryContact, setUpdatePrimaryContact] = React.useState<User>({
    ...initialUserState,
  });
  const [updateDefaultDistributor, setUpdateDefaultDistributor] =
    React.useState<Account>({ ...defaultAccount });

  const handleEditComplianceManager = (e: any) => {
    e.preventDefault();
    setIsEditOpen(true);
  };

  const handleClose = () => {
    setIsEditOpen(false);
  };

  const handleContactChange = (e: any) => {
    contacts.every((item) => {
      if (item.ID == (e.target.value as string)) {
        setUpdatePrimaryContact({ ...item });
        return false;
      } else return true;
    });
  };

  const handleDistributorChange = (e: any) => {
    dealers.every((item) => {
      if (item.ID == (e.target.value as string)) {
        setUpdateDefaultDistributor({ ...item });
        return false;
      } else return true;
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (updatePrimaryContact.ID != "") {
      setLoader(true);
      UpdateAccountPrimaryContact(selectedAccount.ID, updatePrimaryContact.ID)
        .then((res) => {
          setLoader(false);
          setSelectedAccount({
            ...selectedAccount,
            PrimaryContact: { ...updatePrimaryContact },
          });
          setIsEditOpen(false);
          setToastMessage(translation.complianceManagerUpdateSuccess, true);
          setUpdatePrimaryContact({ ...initialUserState });
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          setToastMessage(translation.failed, false);
        });
    }
    if (updateDefaultDistributor.ID != "") {
      setLoader(true);
      UpdateAccountDefaultDealer(
        selectedAccount.ID,
        updateDefaultDistributor.ID
      )
        .then((res) => {
          setLoader(false);
          setSelectedAccount({
            ...selectedAccount,
            DefaultDealer: { ...updateDefaultDistributor },
          });
          setIsEditOpen(false);
          setToastMessage(translation.defaultDistributorUpdateSuccess, true);
          setUpdateDefaultDistributor({ ...defaultAccount });
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          setToastMessage(translation.failed, true);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="mr-2">
      <span className="sx-label mr-1">
        {isEditManager
          ? translation.subscriptionManager
          : translation.defaultDistributor}
        :
      </span>
      <span className="sx-textWithIcon pl-2">
        {isEditManager
          ? selectedAccount.PrimaryContact.Name
          : selectedAccount.DefaultDealer.Name}
        <IconButton
          aria-label="edit"
          size="small"
          className="sx-buttonIcon ml-1"
          onClick={handleEditComplianceManager}
        >
          <Edit fontSize="small" />
        </IconButton>
      </span>
      </div>
      <Dialog className="sx-model" open={isEditOpen} onClose={handleClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle className="sx-model-title">
            {isEditManager
              ? translation.editComplianceManager
              : translation.editDefaultDealer}
          </DialogTitle>
          <DialogContent className="my-4">
            <Box style={{ minWidth: "360px" }}>
              {isEditManager ? (
                <FormControl
                  fullWidth
                  variant="filled"
                  className="sx-inputGroup sx-customSelect my-2"
                >
                  <Select
                    id="select-manager"
                    className="sx-input"
                    disableUnderline={true}
                    value={updatePrimaryContact.ID}
                    onChange={handleContactChange}
                    required
                    displayEmpty
                  >
                    <MenuItem value="" className="disabled">
                      {translation.selectContact}
                    </MenuItem>
                    {contacts &&
                      contacts.length > 0 &&
                      contacts.map((item) => {
                        return (
                          <MenuItem
                            value={item.ID}
                          >{`${item.FirstName} ${item.LastName}`}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              ) : (
                <FormControl
                  fullWidth
                  variant="filled"
                  className="sx-inputGroup sx-customSelect my-2"
                >
                  <Select
                    id="select-dealer"
                    className="sx-input"
                    disableUnderline={true}
                    value={updateDefaultDistributor.ID}
                    onChange={handleDistributorChange}
                    required
                    displayEmpty
                  >
                    <MenuItem value="" className="disabled">
                      {translation.selectDefaultDistributor}
                    </MenuItem>
                    {dealers &&
                      dealers.length > 0 &&
                      dealers.map((item) => {
                        return <MenuItem value={item.ID}>{item.Name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              )}
            </Box>
          </DialogContent>
          <DialogActions className="px-4 py-3 sx-border-top">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              className="sx-button"
            >
              {translation.submit}
            </Button>
            <Button
              fullWidth
              variant="text"
              className="sx-button sx-textButton"
              onClick={handleClose}
            >
              {translation.cancel}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default connector(EditManagerAndDistributor);
