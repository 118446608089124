import jsPDF from "jspdf";
import moment from "moment";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { Account, ComplianceStatus, mimeDict } from "../types/Master";
import UseTranslation from "./UseTranslation";
import homeIcon from "../images/HomeIcon.svg";
import recyclingCertificateIcon from "../images/RcIcon.svg";
import productRegistrationIcon from "../images/PrIcon.svg";
import subscriptionServicesIcon from "../images/SsIcon.svg";
import waterTestingIcon from "../images/WtIcon.svg";
import supportCaseIcon from "../images/ScIcon.svg";

const translation = UseTranslation();

export const TabTypes = {
  HOME: 0,
  RECYCLED_CERTIFICATES: 1,
  PRODUCT_REGISTRATION: 2,
  MAINTENANCE_PLANS: 3,
  SHIPPING_LABEL: 4,
  PRODUCT_ISSUE: 5,
  PROMOTION: 6,
  WATERTESTING: 7,
};

export const Tabs = [
  {
    id: TabTypes.HOME,
    name: "Home",
    url: "/home",
    icon: homeIcon,
    subText: "",
  },
  {
    id: TabTypes.RECYCLED_CERTIFICATES,
    name: "Recycling Certificates",
    url: "/recyclingcertificate",
    icon: recyclingCertificateIcon,
    subText: "Compliance Center",
  },
  {
    id: TabTypes.PRODUCT_REGISTRATION,
    name: "Product Registration",
    url: "/productregistration",
    icon: productRegistrationIcon,
    subText: "Compliance Center",
  },
  {
    id: TabTypes.MAINTENANCE_PLANS,
    name: "Subscription Services",
    url: "/maintenanceplans",
    icon: subscriptionServicesIcon,
    subText: "Compliance Center",
  },
  {
    id: TabTypes.WATERTESTING,
    name: "Water Testing",
    url: "/watertesting",
    icon: waterTestingIcon,
    subText: "",
  },
  {
    id: TabTypes.PRODUCT_ISSUE,
    name: "Support Cases",
    url: "/cases",
    icon: supportCaseIcon,
    subText: "",
  },
];

export const getDate = (val: string): Date => {
  return new Date(val);
};

export const getFormatedDate = (val: string): string => {
  return moment(val).format("YYYY-MM-DD");
};

export const getFormatedDateForDisplay = (val: string): string => {
  return moment(val).format("MM-DD-YYYY");
};

export const getLongDate = (val: string): string => {
  return moment(val).format("MMMM DD, YYYY");
};

export const getNextShipDate = (): string => {
  let currentday = moment().get("date");
  let nextDate: moment.Moment;
  let now: moment.Moment;
  if (currentday <= 10) {
    now = moment().add("1", "months");
    nextDate = moment(`${now.get("year")}-${now.get("month") + 1}-01`);
  } else {
    now = moment().add("2", "months");
    nextDate = moment(`${now.get("year")}-${now.get("month") + 1}-01`);
  }
  return nextDate.format("YYYY-MM-DD");
};

export const getfileType = (extension: string): string => {
  switch (extension) {
    case "pdf":
      return mimeDict.pdf;
      break;
    case "png":
      return mimeDict.png;
      break;
    case "jpeg":
      return mimeDict.jpeg;
      break;
    case "gif":
      return mimeDict.gif;
      break;
    default:
      return "";
  }
};

export const generatePDF = (
  tableRows: any[],
  tableColumn: string[],
  fileName: string,
  columnsizes?: any
) => {
  const doc = new jsPDF();

  autoTable(doc, {
    head: [tableColumn],
    body: tableRows,
    margin: 0,
    columnStyles: columnsizes,
  });

  const dateStr = moment(new Date()).format("MM-DD-YYYY");
  doc.save(`${fileName}_${dateStr}.pdf`);
};

export const generateExcel = (
  tableRows: any[],
  tableColumn: string[],
  fileName: string
) => {
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [tableColumn]);
  XLSX.utils.sheet_add_json(ws, tableRows, {
    origin: "A2",
    skipHeader: true,
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const dateStr = moment(new Date()).format("MM-DD-YYYY");
  XLSX.writeFile(wb, `${fileName}_${dateStr}.xlsx`);
};

export const getComplianceStatus = (val: ComplianceStatus): string => {
  switch (val) {
    case ComplianceStatus.InCompliance:
      return translation.incompliance;
    case ComplianceStatus.ApproachingDeadline:
      return translation.aproachingDeadline;
    case ComplianceStatus.OutOfCompliance:
      return translation.outOfCompliance;
    case ComplianceStatus.NoProductReported:
      return translation.noProductReported;
    default:
      return "";
  }
};

export const getStatusValue = (complianceStatusType: string, item: Account) => {
  switch (complianceStatusType) {
    case translation.amalgamContainers:
      return item.AmalgamContainerStatus;
    case translation.amalgamBuckets:
      return item.AmalgamBucketsStatus;
    case translation.straws:
      return item.StrawsStatus;
    case translation.waterTesting:
      return item.WaterTestingStatus;
    default:
      return ComplianceStatus.None;
  }
};

export const CaseTypes = [
  {
    id: 1,
    name: "Product Support",
  },
  {
    id: 7,
    name: "Order Inquiry",
  },
];

export const getCaseStatus = (val: string): string => {
  switch (val) {
    case "0":
      return "Active";
    case "1":
      return "Resolved";
    case "2":
      return "Cancelled";
    default:
      return "";
  }
};

export const WaterTestDSBrands = [
  {
    id: "100000000",
    name: "BluTab",
    type: "100000000",
  },
  {
    id: "100000001",
    name: "ICX",
    type: "100000000",
  },
  {
    id: "100000002",
    name: "Citrisil",
    type: "100000000",
  },
  {
    id: "100000003",
    name: "Citrisil Blue",
    type: "100000000",
  },
  {
    id: "100000004",
    name: "Dentapure Straw",
    type: "100000001",
  },
  {
    id: "100000005",
    name: "Sterisil Straw",
    type: "100000001",
  },
  {
    id: "100000006",
    name: "ProEdge BluTube Straw",
    type: "100000001",
  },
  {
    id: "100000007",
    name: "Other",
    type: "100000001",
  },
  {
    id: "100000007",
    name: "Other",
    type: "100000000",
  },
];

export const WaterTestDSTypes = [
  {
    id: "100000000",
    name: "Tablet",
  },
  {
    id: "100000001",
    name: "Straw",
  },
  {
    id: "100000002",
    name: "Other Water Treatment",
  },
  {
    id: "100000003",
    name: "We Don't Have Water Bottles",
  },
];

export const ShockProductUsed = [
  {
    id: "100000000",
    name: "Sterilex",
  },
  {
    id: "100000001",
    name: "Mint-A-Kleen",
  },
  {
    id: "100000002",
    name: "Citrisil Shock",
  },
  {
    id: "100000003",
    name: "Bleach",
  },
  {
    id: "100000004",
    name: "Other",
  },
];

export const ShockBeforeInstall = [
  {
    id: "1",
    name: "Yes",
  },
  {
    id: "0",
    name: "No",
  },
];

export const DeviceorSourceWater = [
  {
    id: "100000000",
    name: "A/W Syringe",
  },
  {
    id: "100000001",
    name: "Handpiece",
  },
  {
    id: "100000002",
    name: "Cavitron",
  },
  {
    id: "100000003",
    name: "Source Water",
  },
  {
    id: "100000004",
    name: "Other",
  },
];

export const SourceWaterDS = [
  {
    id: "100000000",
    name: "City Water Faucet",
  },
  {
    id: "100000001",
    name: "Bottled Distilled",
  },
  {
    id: "100000002",
    name: "Filter, Distiller, or RO Unity",
  },
  {
    id: "100000003",
    name: "Other",
  },
];

export const BottleOrMunicipal = [
  {
    id: "100000000",
    name: "Bottle",
  },
  {
    id: "100000001",
    name: "Municipal",
  },
];

export const WaterTestTypeValues = {
  MAIL_IN: "186680001",
  IN_OFFICE: "186680000",
};

export const WaterTestTypes = [
  {
    id: WaterTestTypeValues.IN_OFFICE,
    name: "In Office",
  },
  {
    id: WaterTestTypeValues.MAIL_IN,
    name: "Mail In",
  },
];

export const getWaterTestTypes = (val: string): string => {
  switch (val.toString()) {
    case WaterTestTypeValues.IN_OFFICE:
      return "In Office";
    case WaterTestTypeValues.MAIL_IN:
      return "Mail In";
    default:
      return "";
  }
};

export const WaterTestingResults = [
  {
    id: "186680000",
    name: "Pass",
  },
  {
    id: "186680001",
    name: "Fail",
  },
];

export const getPromotionCaseStatus = (val: string): string => {
  switch (val) {
    case "186680002":
      return "Approved";
    case "186680000":
      return "New";
    case "186680003":
      return "Not Approved";
    case "186680001":
      return "Under Review";
    default:
      return "";
  }
};

export const YesOrNoOptions = [
  {
    id: "1",
    name: "Yes",
  },
  {
    id: "0",
    name: "No",
  },
];

export const WaterTestRecordTypeValues = {
  STANDARD: "1",
  RETEST: "2",
};

export const WaterTestingRecordType = [
  {
    id: WaterTestRecordTypeValues.STANDARD,
    name: "Standard",
  },
  {
    id: WaterTestRecordTypeValues.RETEST,
    name: "Retest",
  },
];

export const getWaterTestRecotdType = (val: string): string => {
  switch (val.toString()) {
    case WaterTestRecordTypeValues.STANDARD:
      return "Standard";
    case WaterTestRecordTypeValues.RETEST:
      return "Retest";
    default:
      return "";
  }
};
