import {
    Backdrop,
    Box,
    Button,
    IconButton,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    Grid,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import SearchBar from "material-ui-search-bar";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import {
    GetAccountContacts,
    GetDealerAccounts,
    GetDealerApprovedForMP,
} from "../../services/CustomerService";
import {
    GetMaintenancePlanApprovedProduct,
    GetMaintenancePlans,
    UpdateMaintenancePlan,
} from "../../services/ProductService";
import {
    getNextShipDate,
    getFormatedDateForDisplay,
    generatePDF,
    generateExcel,
} from "../../services/Shared";
import UseTranslation from "../../services/UseTranslation";
import {
    Account,
    ApplicationState,
    defaultMaintenancePlan,
    MaintenancePlan,
    Product,
    User,
} from "../../types/Master";
import {
    EnhancedTableHead,
    getComparator,
    HeadCell,
    OrderType,
    stableSort,
} from "../shared/Table";
import AddMaintenancePlanProduct from "./AddMaintenancePlanProduct";
import EditManagerAndDistributor from "./EditManagerAndDistributor";
import SideNav from "../shared/SideNav";
import moment from "moment";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
    isLoading: state.isLoading,
    user: state.user,
    selectedAccount: state.SelectedAccount,
    isContactAccountsLoaded: state.IsContactAccountsLoaded,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {
        setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
        setToastMessage: (Message: string, Status: boolean) =>
            dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const headCells: HeadCell[] = [
    {
        id: "ProductNumber",
        numeric: false,
        disableSorting: false,
        label: translation.productnumber,
    },
    {
        id: "ProductDescription",
        numeric: false,
        disableSorting: false,
        label: translation.productdescription,
    },
    {
        id: "Frequency",
        numeric: false,
        disableSorting: false,
        label: translation.frequency,
    },
    {
        id: "Quantity",
        numeric: false,
        disableSorting: false,
        label: translation.quantity,
    },
    {
        id: "NextShipDate",
        numeric: false,
        disableSorting: false,
        label: translation.nextship,
    },
    {
        id: "Status",
        numeric: false,
        disableSorting: true,
        label: translation.status,
    },
];

const defaultcolumns = [
    "Product Number",
    "Product Description",
    "Frequency",
    "Quantity",
    "Next Ship Date",
    "Status",
];

const MaintenancePlans = (props: PropsFromRedux) => {
    const {
        user,
        isLoading,
        selectedAccount,
        isContactAccountsLoaded,
        setLoader,
        setToastMessage,
    } = props;
    const [plans, setPlans] = React.useState<MaintenancePlan[]>([]);
    const [totalPlans, setTotalPlans] = React.useState<MaintenancePlan[]>([]);
    const [order, setOrder] = React.useState<OrderType>("asc");
    const [orderBy, setOrderBy] = React.useState<string>("CreatedOn");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searched, setSearched] = React.useState<string>("");
    const [updateComplianceRequest, setUpdateComplianceRequest] =
        React.useState<MaintenancePlan>(defaultMaintenancePlan);
    const [products, setProducts] = React.useState<Product[]>([]);
    const [dealers, setDealers] = React.useState<Account[]>([]);
    const [dealersApprovedForMP, setDealersApprovedForMP] = React.useState<
        Account[]
    >([]);
    const [contacts, setContacts] = React.useState<User[]>([]);
    const [isStatusChangeConfirmationOpen, setIsStatusChangeConfirmationOpen] =
        React.useState<boolean>(false);
    const [isStatusChangeToInactive, setIsStatusChangeToInactive] =
        React.useState<boolean>(false);
    const [updateComplianceStatusRequest, setUpdateComplianceStatusRequest] =
        React.useState<MaintenancePlan>(defaultMaintenancePlan);

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    React.useEffect(() => {
        if (isContactAccountsLoaded && selectedAccount.ID != "") {
            setLoader(true);
            try {
                Promise.all([
                    getPlans(),
                    getDealers(),
                    getDealersApprovedForMP(),
                    getContacts(),
                    getProducts(),
                ])
                    .then(() => setLoader(false))
                    .catch((ex) => setLoader(false));
            } catch (error) {
                setLoader(false);
            }
        }
    }, [selectedAccount, isContactAccountsLoaded]);

    const getPlans = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            GetMaintenancePlans(selectedAccount.ID)
                .then((res) => {
                    setPlans(res);
                    setTotalPlans(res);
                    resolve();
                })
                .catch((ex) => {
                    console.error(ex);
                    setLoader(false);
                    reject();
                });
        });
    };

    const getDealers = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            GetDealerAccounts()
                .then((accounts: Account[]) => {
                    setDealers(accounts);
                    resolve();
                })
                .catch((ex) => {
                    console.error(ex);
                    reject();
                });
        });
    };

    const getDealersApprovedForMP = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            GetDealerApprovedForMP()
                .then((accounts: Account[]) => {
                    setDealersApprovedForMP(accounts);
                    resolve();
                })
                .catch((ex) => {
                    console.error(ex);
                    reject();
                });
        });
    };

    const getContacts = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            GetAccountContacts(selectedAccount.ID)
                .then((contacts: User[]) => {
                    setContacts(contacts);
                    resolve();
                })
                .catch((ex) => {
                    console.error(ex);
                    reject();
                });
        });
    };

    const getProducts = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            GetMaintenancePlanApprovedProduct()
                .then((products: Product[]) => {
                    setProducts(products);
                    resolve();
                })
                .catch((ex) => {
                    console.error(ex);
                    reject();
                });
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const requestSearch = (searchedVal: string) => {
        let listOfWords = searchedVal.split(" ");
        let filteredRows: MaintenancePlan[] = [];
        totalPlans.forEach((item) => {
            let isAllWordsPresent = false;
            listOfWords.every((word: string) => {
                if (
                    item.Product.Description.toLowerCase().includes(word.toLowerCase()) ||
                    item.Name.toLowerCase().includes(word.toLowerCase()) ||
                    item.Quantity.toString() == word ||
                    item.Frequency.toString() == word ||
                    (word.toLowerCase() == "active" && item.Status) ||
                    (word.toLowerCase() == "inactive" && !item.Status) ||
                    getFormatedDateForDisplay(item.NextShipDate)
                        .toLowerCase()
                        .includes(word.toLowerCase())
                ) {
                    isAllWordsPresent = true;
                    return true;
                } else {
                    isAllWordsPresent = false;
                    return false;
                }
            });
            if (isAllWordsPresent) filteredRows.push({ ...item });
        });
        setPlans(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const handleEdit = (plan: MaintenancePlan) => {
        setUpdateComplianceRequest({ ...plan });
    };

    const handleStatusChange = (plan: MaintenancePlan) => {
        setIsStatusChangeConfirmationOpen(true);
        if (plan.Status) setIsStatusChangeToInactive(true);
        else setIsStatusChangeToInactive(false);
        setUpdateComplianceStatusRequest({ ...plan });
    };

    const modifyStatus = (e: any) => {
        e.preventDefault();
        setLoader(true);
        let updaterequest = {
            ...updateComplianceStatusRequest,
            Status: !updateComplianceStatusRequest.Status,            
            Account: { ...selectedAccount },
            LoggedInContact: { ...user },
            IsStatusUpdate: true,
            NextShipDate: moment(getNextShipDate().substring(0, 7) + "-01").utc(),
        };
        UpdateMaintenancePlan(updaterequest)
            .then((res) => {
                getPlans()
                    .then(() => {
                        setLoader(false);
                        setIsStatusChangeConfirmationOpen(false);
                    })
                    .catch(() => {
                        setLoader(false);
                        setIsStatusChangeConfirmationOpen(false);
                    });
            })
            .catch((ex) => {
                console.error(ex);
                setLoader(false);
                setIsStatusChangeConfirmationOpen(false);
            });
    };

    const handleStatusChangeConfirmationClose = () => {
        setIsStatusChangeConfirmationOpen(false);
    };

    const downloadExcelOrPdf = (isPdf: boolean) => {
        const tableRows: any[] = [];
        plans.forEach((mp) => {
            const data = [
                mp.Product.Number,
                mp.Product.Description,
                mp.Frequency,
                mp.Quantity,
                getFormatedDateForDisplay(mp.NextShipDate),
                mp.Status ? "Active" : "Inactive",
            ];
            tableRows.push(data);
        });
        if (isPdf)
            generatePDF(tableRows, defaultcolumns, translation.subscriptionService);
        else
            generateExcel(tableRows, defaultcolumns, translation.subscriptionService);
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} sm={3} md={2} className="sx-colorBg-grey-08 px-4 pt-4">
                <SideNav />
            </Grid>
            <Grid item xs={12} sm={9} md={10}>
                <Backdrop
                    style={{
                        zIndex: 2250,
                    }}
                    open={isLoading}
                >
                    <CircularProgress />
                </Backdrop>
                <Dialog
                    className="sx-model"
                    open={isStatusChangeConfirmationOpen}
                    onClose={handleStatusChangeConfirmationClose}
                >
                    <DialogTitle className="sx-model-title">
                        {translation.statusChangeConfirmation}
                    </DialogTitle>
                    <DialogContent className="mb-4">
                        {isStatusChangeToInactive ? (
                            `${translation.inactiveStatusConfirmation} - ${updateComplianceStatusRequest.Name}`
                        ) : (
                            <Box className="d-flex flex-column">
                                <span className="mb-2">
                                    {`${translation.activateStatusConfirmation} - ${updateComplianceStatusRequest.Name}`}
                                </span>
                                <span className="mb-2">
                                    {`${translation.nextShipInformation
                                        } - ${getFormatedDateForDisplay(getNextShipDate())}`}
                                </span>
                                <span className="mb-2">
                                    {translation.stausUpdateInstructions}
                                </span>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions className="px-4 py-3 sx-border-top">
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={isLoading}
                            className="sx-button"
                            onClick={modifyStatus}
                        >
                            {translation.submit}
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="text"
                            className="sx-button sx-textButton"
                            onClick={handleStatusChangeConfirmationClose}
                        >
                            {translation.cancel}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box className="mb-5">
                    <Box className="p-3">
                        <div>
                            <h3>{translation.subscriptionService}</h3>
                            <p className="small">{translation.subscriptionServiceDetails}</p>
                        </div>
                        <div className="d-flex justify-content-start align-items-end">
                            <EditManagerAndDistributor
                                isEditManager={true}
                                contacts={contacts}
                                dealers={dealersApprovedForMP}
                            />
                            <EditManagerAndDistributor
                                isEditManager={false}
                                contacts={contacts}
                                dealers={dealersApprovedForMP}
                            />
                        </div>
                    </Box>
                    <Box className="sx-table-shadow mt-3 p-3">
                        <Box className="d-flex justify-content-between align-items-center py-3 sx-tableSearchContainer">
                            <SearchBar
                                value={searched}
                                onChange={(searchVal) => requestSearch(searchVal)}
                                onCancelSearch={() => cancelSearch()}
                                className="col-sm-5 px-0 sx-tableSearch shadow-none"
                            />
                            <Box className="d-flex justify-content-between align-items-center pt-1">
                                <AddMaintenancePlanProduct
                                    products={products}
                                    dealers={dealersApprovedForMP}
                                    contacts={contacts}
                                    getPlans={getPlans}
                                    setUpdateComplianceRequest={setUpdateComplianceRequest}
                                    updateComplianceRequest={updateComplianceRequest}
                                />
                                <IconButton
                                    className="ml-1 p-0"
                                    onClick={() => downloadExcelOrPdf(true)}
                                >
                                    <img
                                        src={require("../../images/pdf.png")}
                                        alt="Solmetex"
                                        className="img-fluid sx-iconButtonImg"
                                    />
                                </IconButton>
                                <IconButton
                                    className="p-0"
                                    onClick={() => downloadExcelOrPdf(false)}
                                >
                                    <img
                                        src={require("../../images/xls.png")}
                                        alt="Solmetex"
                                        className="img-fluid sx-iconButtonImg"
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box className="pb-3">
                            <TableContainer>
                                <Table>
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        headCells={headCells}
                                        rowCount={plans.length}
                                        displayActionCell={true}
                                        onRequestSort={handleRequestSort}
                                    />
                                    {plans.length == 0 ? (
                                        <TableBody className="sx-tableBody">
                                            <TableRow>
                                                <TableCell
                                                    className="mb-5 p-3 text-center"
                                                    colSpan={headCells.length}
                                                >
                                                    <img
                                                        src={require("../../images/empty-state.png")}
                                                        alt="Solmetex"
                                                        className="img-fluid"
                                                    />
                                                    <h3 className="mt-3 sx-blue">No Data Found</h3>
                                                    <p>please try again later</p>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody className="sx-tableBody">
                                            {stableSort(plans, getComparator(order, orderBy))
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                .map((plan) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>{plan.Product.Number}</TableCell>
                                                            <TableCell>{plan.Product.Description}</TableCell>
                                                            <TableCell>{plan.Frequency}</TableCell>
                                                            <TableCell>{plan.Quantity}</TableCell>
                                                            <TableCell>
                                                                {getFormatedDateForDisplay(plan.NextShipDate)}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box className="d-flex align-items-center sx-statusToggle">
                                                                    {plan.Status ? (
                                                                        <span>{translation.active}</span>
                                                                    ) : (
                                                                        <span>{translation.inactive}</span>
                                                                    )}
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={plan.Status ? true : false}
                                                                        onClick={() => handleStatusChange(plan)}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box className="d-flex align-items-center">
                                                                    {plan.Status && (
                                                                        <IconButton
                                                                            aria-label="edit"
                                                                            size="small"
                                                                            className="sx-buttonIcon ml-1"
                                                                            onClick={() => handleEdit(plan)}
                                                                        >
                                                                            <Edit fontSize="small" />
                                                                        </IconButton>
                                                                    )}
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={plans.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                className="pagination-content"
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default connector(MaintenancePlans);
