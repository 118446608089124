import * as React from "react";
import { AnyAction, Dispatch } from "redux";
import UseTranslation from "../../services/UseTranslation";
import { ApplicationState, LoggedInRole } from "../../types/Master";
import { connect, ConnectedProps } from "react-redux";
import { Backdrop, Box, CircularProgress } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import DoughnutChart from "./DoughnutChart";
import Accounts from "./Accounts";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  complianceStatusType: state.complianceStatusType,
  displayAccountsData: state.displayAccountsData,
  dsoParentAccount: state.DsoParentAccount,
  dsoAccounts: state.DsoAccounts,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setComplianceStatusType: (val: string) =>
      dispatch({ type: "SET_COMPLIANCE_STATUS_TYPE", payload: val }),
    setDisplayAccountsData: (val: boolean) =>
      dispatch({ type: "SET_DISPLAY_ACCOUNTS_DATA", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Dashboard = (props: PropsFromRedux) => {
  const {
    user,
    isLoading,
    complianceStatusType,
    displayAccountsData,
    dsoAccounts,
    dsoParentAccount,
    setComplianceStatusType,
    setDisplayAccountsData,
  } = props;

  if (!user.IsDsoLogin) {
    return <Redirect to="/" />;
  }

  const handleDoughnutChartClick = (val: string) => {
    setDisplayAccountsData(true);
    setComplianceStatusType(val);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      {displayAccountsData ? (
        <Accounts complianceStatusType={complianceStatusType} />
      ) : dsoAccounts && dsoAccounts.length > 0 ? (
        <Box className="mx-4">
          <h2>{translation.healthOfOrganization}</h2>
          <h5>{dsoParentAccount.Name}</h5>
          <Box className="my-4">
            <div className="row ">
              <div className="col">
                <h5>{`${translation.amalgamContainers}:`}</h5>
                <div
                  className="card"
                  onClick={() =>
                    handleDoughnutChartClick(translation.amalgamContainers)
                  }
                >
                  <div className="card-body p-0">
                    <div className="p-3">
                      <DoughnutChart
                        complianceStatusType={translation.amalgamContainers}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <h5>{`${translation.amalgamBuckets}:`}</h5>
                <div
                  className="card"
                  onClick={() =>
                    handleDoughnutChartClick(translation.amalgamBuckets)
                  }
                >
                  <div className="card-body p-0">
                    <div className="p-3">
                      <DoughnutChart
                        complianceStatusType={translation.amalgamBuckets}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <h5>{`${translation.straws}:`}</h5>
                <div
                  className="card"
                  onClick={() => handleDoughnutChartClick(translation.straws)}
                >
                  <div className="card-body p-0">
                    <div className="p-3">
                      <DoughnutChart
                        complianceStatusType={translation.straws}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <h5>{`${translation.waterTesting}:`}</h5>
                <div
                  className="card"
                  onClick={() =>
                    handleDoughnutChartClick(translation.waterTesting)
                  }
                >
                  <div className="card-body p-0">
                    <div className="p-3">
                      <DoughnutChart
                        complianceStatusType={translation.waterTesting}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default connector(Dashboard);
