import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import {
  ApplicationState,
  WoocommerceRequest,
  defaultWoocommerceRequest,
} from "../../types/Master";
import { Box, Grid, Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import UseTranslation from "../../services/UseTranslation";
import { GetAutoLoginUrl } from "../../services/CustomerService";
import { Redirect } from "react-router-dom";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedAccountContact: state.SelectedAccountContact,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
  showSelectedAccountData: state.ShowSelectedAccountData,
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SideNav = (props: PropsFromRedux) => {
  const {
    user,
    isContactAccountsLoaded,
    selectedAccount,
    selectedAccountContact,
    showSelectedAccountData,
    setLoader,
    setToastMessage,
  } = props;

  if (isContactAccountsLoaded && user.IsDsoLogin && !showSelectedAccountData) {
    return <Redirect to="/dashboard" />;
  }

  const [wooCommerceRequest, setWoocommerceRequest] =
    React.useState<WoocommerceRequest>(defaultWoocommerceRequest);

  React.useEffect(() => {    
    var contact = user;
    if (selectedAccountContact != null && selectedAccountContact.ID != "") {
      contact = selectedAccountContact;
    }
    setWoocommerceRequest({
      ...wooCommerceRequest,
      Account: { ...selectedAccount },
      Contact: { ...contact },
    });
  }, [selectedAccount, selectedAccountContact]);

  const handleOrderNow = (e: any) => {
    e.preventDefault();
    getAutoLoginUrl();
  };

  const getAutoLoginUrl = (): Promise<void> => {
    setLoader(true);
    return new Promise((resolve, reject) => {
      GetAutoLoginUrl(wooCommerceRequest)
        .then((res: any) => {
          if (res.status == "success") {
            window.open(res.data, "_blank", "noreferrer");
          } else {
            setToastMessage(translation.woocommerceredirectionissue, false);
          }
          resolve();
          setLoader(false);
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          setToastMessage(translation.woocommerceredirectionissue, false);
          reject();
        });
    });
  };

  return (
    <Box>
      <Box>
        <Link
          className="my-3 rounded-pill btn-green text-uppercase"
          to="/shippinglabel"
          aria-disabled
        >
          {translation.printRecyclingLabel}
        </Link>
        {selectedAccount.AllowShopFromPortal ? (
          <Button
            type="button"
            fullWidth
            className="rounded-pill btn-outline-blue text-uppercase"
            onClick={handleOrderNow}
          >
            {translation.orderNow}
          </Button>
        ) : (
          <Tooltip title={translation.placeOrderValidation}>
            <div>
              <a
                className="rounded-pill btn-green text-uppercase btn-disabled"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                {translation.orderNow}
              </a>
            </div>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default connector(SideNav);
