import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "../../types/Master";

const mapStateToProps = (state: ApplicationState) => ({
  isAuthUser: state.isAuthUser,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Footer = (props: PropsFromRedux) => {
  const { isAuthUser } = props;

  return isAuthUser ? (
    <div className="footer-container"></div>
  ) : (
    <React.Fragment />
  );
};

export default connector(Footer);
