import {
  Box,
  Button,
  TextField,
  FormControl,
  Select,
  DialogActions,
  MenuItem,
  Typography,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import UseTranslation from "../../../services/UseTranslation";
import {
  ApplicationState,
  WaterTesting,
  defaultWaterTesting,
} from "../../../types/Master";
import SolmetexLogoSvg from "../../images/Solmetex_logo.svg";
import { AnyAction, Dispatch } from "redux";
import moment from "moment";

interface errorValidation  {
  doctorname:string;
  complianceofficeremail: string;
};
const defaultErrorValidation = {
  doctorname: "",
  complianceofficeremail: "",
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  selectedAccount: state.SelectedAccount,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const translation = UseTranslation();
interface OwnProps {
  //onsubmit: () => void;
  waterTetsingRequest: WaterTesting;
  handleFormChange: (e: Event) => void;
  handleStepButton:(number:Number)=>void;
  setWaterTestingDataRequest: React.Dispatch<React.SetStateAction<WaterTesting>>;
}

export type ddDSStep1FormProps = PropsFromRedux & OwnProps;

const DSStep1Form = (props: ddDSStep1FormProps) => {
  const {
    isLoading,
    setLoader,
    setToastMessage,
    selectedAccount,
    waterTetsingRequest,
    handleFormChange,
    handleStepButton,
    setWaterTestingDataRequest
  } = props;
  const [sampleDate, setSampleDate] = React.useState<string>("");
  const [showsampleDateRequired, setShowSampleDateRequired] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<errorValidation>({
    ...defaultErrorValidation,
  });
  const [showDoctorRequired, setShowDoctorRequired] = React.useState<boolean>(false);
  const [showComplianceOfficerEmailRequired, setShowComplianceOfficerEmailRequired] = React.useState<boolean>(false);
  //const [waterTestingDataRequest, setWaterTestingDataRequest] =
  //React.useState<WaterTesting>(defaultWaterTesting);

  React.useEffect(() => {
    let currentday = moment().get("date");
    let nextDate: moment.Moment;
    let now: moment.Moment;
    now = moment();
    
    sampleDate: now.format("YYYY-MM-DD");
     console.log(sampleDate);
     setSampleDate(sampleDate);
  } );

  // React.useEffect(() => {
  //   var dentisaferequeststr = localStorage.getItem("dentisaferequest");

  //   if(dentisaferequeststr!=null) {
  //     const parsedData = JSON.parse(dentisaferequeststr) as WaterTesting;
  //     setWaterTestingDataRequest(parsedData);
  //   }
  // } );
  const isValidEmail = (email:any) => {
    // Regular expression to check for a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const errorValidation = (request: WaterTesting): boolean => {
    let errorsObject = { ...defaultErrorValidation };
    let isValid = true;
    if (request.DoctorName == "") {
      setShowDoctorRequired(true);
      errorsObject = { ...errorsObject, doctorname: translation.requiredField };
      isValid = false;
    }
    else{
      setShowDoctorRequired(false);
    }
    if (request.ComplianceOfficerEmail == "" || !isValidEmail(request.ComplianceOfficerEmail)) {
      setShowComplianceOfficerEmailRequired(true);
      errorsObject = { ...errorsObject, complianceofficeremail: translation.requiredField };
      isValid = false;
    }
    else{
      setShowComplianceOfficerEmailRequired(false);
    }
    if (request.DateofSample == "") {
      setShowSampleDateRequired(true);
      errorsObject = { ...errorsObject, complianceofficeremail: translation.requiredField };
      isValid = false;
    }
    else{
      setShowSampleDateRequired(false);
    }
    setErrors({ ...errorsObject });
    return isValid;
  };
  

const handleStep1Button=(stepNumber:number)=>{
  if(errorValidation(waterTetsingRequest)){
    var strWaterTestingDataRequest=JSON.stringify(props.waterTetsingRequest);
    localStorage.setItem("dentisaferequest",strWaterTestingDataRequest);
    props.handleStepButton(stepNumber);
  }
}

  return (
    <Box component="form">
      <TextField
        className="sx-inputGroup"
        margin="normal"
        fullWidth
        id="accountname"
        name="accountname"
        type="text"
        label="Office Name"
        value={props.selectedAccount.Name}
        InputProps={{
          readOnly: true, 
          disableUnderline: true,
          className: "sx-input",
          inputProps: { min: 1 },
        }}
        InputLabelProps={{ className: "sx-input-label" }}
        variant="filled"
      />
      <Box className="tw-inline-flex tw-items-center tw-justify-center tw-w-full mt-4 mb-1">
        <hr className="tw-w-64 tw-h-px tw-my-2 tw-bg-gray-200 tw-border-0" />
        <span className="tw-absolute tw-px-3 tw-font-medium tw-text-gray-400 tw--translate-x-1/2 tw-bg-white tw-left-1/2">
          Address Details
        </span>
      </Box>

      <Box className="tw-grid tw-grid-cols-2 tw-gap-4">
        <TextField
          className="sx-inputGroup"
          margin="normal"
          fullWidth
          id="address"
          name="address"
          type="text"
          label="Address"                    
          value={props.selectedAccount.Street}
          InputProps={{
            readOnly: true, 
            disableUnderline: true,
            className: "sx-input",
            inputProps: { min: 1 },
          }}
          InputLabelProps={{ className: "sx-input-label" }}
          variant="filled"
        />
        <TextField
          className="sx-inputGroup"
          margin="normal"
          fullWidth
          id="street2"
          name="street2"
          type="text"
          label="Street Address"
          value={props.selectedAccount.Street2}
          InputProps={{
            readOnly: true, 
            disableUnderline: true,
            className: "sx-input",
            inputProps: { min: 1 },
          }}
          InputLabelProps={{ className: "sx-input-label" }}
          variant="filled"
        />
        <TextField
          className="sx-inputGroup"
          margin="normal"
          fullWidth
          id="city"
          name="city"
          type="text"
          label="City"
          value={props.selectedAccount.City}
          InputProps={{
            readOnly: true, 
            disableUnderline: true,
            className: "sx-input",
            inputProps: { min: 1 },
          }}
          InputLabelProps={{ className: "sx-input-label" }}
          variant="filled"
        />
        <TextField
          className="sx-inputGroup"
          margin="normal"
          fullWidth
          id="state"
          name="state"
          type="text"
          label="State / Province / Region"
          value={props.selectedAccount.State}
          InputProps={{
            readOnly: true, 
            disableUnderline: true,
            className: "sx-input",
            inputProps: { min: 1 },
          }}
          InputLabelProps={{ className: "sx-input-label" }}
          variant="filled"
        />
        <TextField
          className="sx-inputGroup"
          margin="normal"
          fullWidth
          id="postcode"
          name="postcode"
          type="text"
          label="ZIP / Postal Code"
          value={props.selectedAccount.ZipCode}
          InputProps={{
            readOnly: true, 
            disableUnderline: true,
            className: "sx-input",
            inputProps: { min: 1 },
          }}
          InputLabelProps={{ className: "sx-input-label" }}
          variant="filled"
        />
        <TextField
          className="sx-inputGroup"
          margin="normal"
          fullWidth
          id="country"
          name="country"
          type="text"
          label="Country"
          value={props.selectedAccount.Country}
          InputProps={{
            readOnly: true, 
            disableUnderline: true,
            className: "sx-input",
            inputProps: { min: 1 },
          }}
          InputLabelProps={{ className: "sx-input-label" }}
          variant="filled"
        />
        <TextField
          className="sx-inputGroup"
          margin="normal"
          fullWidth
          id="phone"
          name="phone"
          type="text"
          label="Phone Number"
          value={props.selectedAccount.Phone}
          InputProps={{
            readOnly: true, 
            disableUnderline: true,
            className: "sx-input",
            inputProps: { min: 1 },
          }}
          InputLabelProps={{ className: "sx-input-label" }}
          variant="filled"
        />
        <Box>
        <TextField
          className={`sx-inputGroup ${
            showDoctorRequired &&
            "sx-validationFailed"
          }`}
          margin="normal"
          fullWidth
          id="doctorname"
          name="DoctorName"
          type="text"
          label="Doctor's Name"
          onChange={(e) => {
            setWaterTestingDataRequest({
              ...waterTetsingRequest,
              DoctorName: e.target.value as string,
            });
          }}
          value={waterTetsingRequest.DoctorName}
          InputProps={{ 
            required:true,       
            className: "sx-input",
            inputProps: { min: 1 },
          }}
          InputLabelProps={{ className: "sx-input-label" }}
          variant="filled"
        />
        {showDoctorRequired && (
        <div className="error-text font-10 pl-4 text-left">
          Required
        </div>
              
        )}
        </Box>
      </Box>

      <Box className="tw-inline-flex tw-items-center tw-justify-center tw-w-full tw-mt-6 mb-1">
        <hr className="tw-w-96 tw-h-px tw-my-2 tw-bg-gray-200 tw-border-0" />
        <span className="tw-absolute tw-px-3 tw-font-medium tw-text-gray-400 tw--translate-x-1/2 tw-bg-white tw-left-1/2">
        Compliance Officer's Email
        </span>
      </Box>
      <TextField
        className="sx-inputGroup"
        margin="normal"
        fullWidth
        id="CompilianceOfficername"
        name="CompilianceOfficername"
        type="text"
        label="Compliance Officer's Name"
        onChange={(e) => {
          setWaterTestingDataRequest({
            ...waterTetsingRequest,
            ComplianceOfficerName: e.target.value as string,
          });
        }}
        value={waterTetsingRequest.ComplianceOfficerName}
          //value={props.waterTetsingRequest.ComplianceOfficerName}
        InputProps={{
          disableUnderline: true,
          className: "sx-input",
          inputProps: { min: 1 },
        }}
        InputLabelProps={{ className: "sx-input-label" }}
        variant="filled"
      />
      <Box className="tw-grid tw-grid-cols-2 tw-gap-4">
          <Box>
            <TextField
              className={`sx-inputGroup ${
                showComplianceOfficerEmailRequired &&
                "sx-validationFailed"
              }`}
              margin="normal"
              fullWidth
              id="complianceofficeremail"
              name="complianceofficeremail"
              type="email"
              label="Email"          
              onChange={(e) => {
                setWaterTestingDataRequest({
                  ...waterTetsingRequest,
                  ComplianceOfficerEmail: e.target.value as string,
                });
              }}
              value={waterTetsingRequest.ComplianceOfficerEmail}
              InputProps={{            
                disableUnderline: true,
                className: "sx-input",
                inputProps: { min: 1 }
                
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
            {showComplianceOfficerEmailRequired && (
              <div className="error-text font-10 pl-4 text-left">
               Email Required
              </div>
            )}
          </Box>
          <Box>
          <TextField
            className={`sx-inputGroup ${
              showsampleDateRequired &&
              "sx-validationFailed"
            }`}
            margin="normal"
            fullWidth
            id="sampledate"
            name="sampledate"
            label="Sample Date"
            type="date"
            defaultValue={moment().format("yyyy-mm-dd")}
            onChange={(e) => {
              setWaterTestingDataRequest({
                ...waterTetsingRequest,
                DateofSample: e.target.value as string,
              });
            }}           
            value={props.waterTetsingRequest.DateofSample}
            InputProps={{
              disableUnderline: true,
              className: "sx-input",
            }}
            InputLabelProps={{ className: "sx-input-label", shrink: true }}
            variant="filled"
          />
           {showsampleDateRequired && (
              <div className="error-text font-10 pl-4 text-left">
                Required
              </div>
            )}
          </Box>
      </Box>
      {/* <Box className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-6">
     
      </Box> */}
      <Box className="tw-flex tw-justify-end">
        <Button
          onClick={() =>handleStep1Button(2)}
          variant="contained"
          className="sx-button my-4 !tw-rounded-lg !tw-h-[48px] !tw-min-w-[180px]"
        >
          NEXT
        </Button>
      </Box>
    </Box>
  );
};

export default connector(DSStep1Form);
