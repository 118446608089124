import { BlobServiceClient } from "@azure/storage-blob";
import { store } from "../App";
import {
  Account,
  AccountLookUpRequest,
  Campaign,
  Case,
  ProductIssueRequest,
  ShippingLabelRequest,
  SignUpRequest,
  User,
  WoocommerceRequest,
} from "../types/Master";

export const GetLoggedInUser = (): User | undefined => {
  if (localStorage.getItem("user") !== null) {
    return JSON.parse(localStorage.getItem("user") || "");
  } else return undefined;
};

export const GetToken = (): string => {
  if (localStorage.getItem("user") !== null) {
    let user = JSON.parse(localStorage.getItem("user") || "");
    return user ? user.Token : "";
  } else return "";
};

export const unauthorized = "Unauthorized";
export const handleUnauthorized = (message: string) => {
  store.dispatch({ type: "LOGOUT", payload: undefined });
  throw new Error(message);
};

export const CustomerSignIn = (
  userId: any,
  password: any
): Promise<User | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/SignIn", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, password }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetContactOfAccountByContactEmail = (
  accountId: string,
  email: string
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Customer/GetContactOfAccountByContactEmail?accountid=" +
        accountId +
        "&email=" +
        email,
      {
        method: "GET",
        headers: new Headers({
          Authorization: GetToken(),
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const UpdateAccountLastLogin = (
  accountId: string,
  contactId: string
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Customer/UpdateAccountLastLogin?accountId=" +
        accountId +
        "&contactId=" +
        contactId,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: GetToken(),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response) resolve(response);
        else reject("failed");
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetContactWithAccounts = (contactId: string): Promise<User> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/GetContactWithAccounts/?contactId=" + contactId, {
      method: "GET",
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetAccountsWithNationalManagerSelectedAccount = (
  accountid: string
): Promise<Account[]> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Customer/GetAccountsWithNationalManagerSelectedAccount/?accountid=" +
        accountid,
      {
        method: "GET",
        headers: new Headers({
          Authorization: GetToken(),
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CustomerSignUp = (
  signUpRequest: SignUpRequest
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/SignUp", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signUpRequest),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.data != null) resolve(true);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const ForgotPassword = (
  userId: any,
  accountNumber: any
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/ForgotPassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, accountNumber }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) resolve(response);
        else reject("failed");
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const SetPassword = (
  userId: any,
  password: any,
  otp: any,
  source: any,
  accountNumber: string
): Promise<{ user: User; token: string } | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/CreatePassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, password, otp, source, accountNumber }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) resolve(response);
        else reject("Login faied");
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const VerifyUser = (
  UserId: any,
  PasswordResetId: any,
  ReqestUrl: any,
  Password: any
): Promise<{ user: User; token: string } | undefined> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/VerifyUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ UserId, Password, PasswordResetId, ReqestUrl }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) resolve(response);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const LookUpAccountNumber = (
  accountLookUpRequest: AccountLookUpRequest
): Promise<Account[]> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Customer/LookUpAccount?inputValue=" +
        accountLookUpRequest.inputValue +
        "&zipCode=" +
        accountLookUpRequest.zipCode,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetDealerAccounts = (): Promise<Account[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/GetDealerAccounts", {
      method: "GET",
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetDealerAccountsForPR = (): Promise<Account[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/GetDealerAccountsForPR", {
      method: "GET",
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetDealerApprovedForMP = (): Promise<Account[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/GetDealerApprovedForMP", {
      method: "GET",
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetAccountContacts = (accountid: string): Promise<User[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/GetAccountContacts?accountid=" + accountid, {
      method: "GET",
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CreateProductIssue = (
  request: ProductIssueRequest
): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/CreateProductIssue", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.status == "success")
          resolve(response.data);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CreateShippingLabel = (
  request: ShippingLabelRequest
): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/CreateShippingLabel", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetPromotions = (accountId: string): Promise<Case[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/GetPromotions?accountId=" + accountId, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetCampaigns = (): Promise<Campaign[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/GetCampaigns", {
      method: "GET",
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CreatePromotionCase = (data: FormData): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/CreatePromotionCase", {
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: GetToken(),
      },
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.status == "success")
          resolve(response.data);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetTestingLabs = (): Promise<Account[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/GetTestingLabs", {
      method: "GET",
      headers: new Headers({
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetAttachment = (blobName: any): Promise<Blob> => {
  const account = process.env.REACT_APP_AZURE_ACCOUNT;
  const sas = process.env.REACT_APP_AZURE_SAS_TOKEN;
  const containerName = process.env.REACT_APP_AZURE_CONTAINER;
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net${sas}`
  );
  const containerClient = blobServiceClient.getContainerClient(
    containerName || ""
  );
  const blobClient = containerClient.getBlobClient(blobName);
  return new Promise((resolve, reject) => {
    blobClient
      .download()
      .then((downloadBlockBlobResponse) => {
        if (downloadBlockBlobResponse.blobBody)
          downloadBlockBlobResponse.blobBody
            .then((blob) => {
              resolve(blob);
            })
            .catch((ex) => {
              console.error(ex);
              reject();
            });
      })
      .catch((ex) => {
        console.error(ex);
        reject();
      });
  });
};

export const GetAutoLoginUrl = (
  request: WoocommerceRequest
): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch("api/Customer/WooCommerceUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};
// export const GetAutoLoginUrl = (woocommerceRequest:WoocommerceRequest): Promise<string> => {

//   const data = JSON.stringify(woocommerceRequest);
//   return new Promise((resolve, reject) => {
//     fetch("api/Customer/OrderNow", {
//       method: "POST",
//       headers: {
//         Accept: "*/*",
//         Authorization: GetToken(),
//       },
//       body: data,
//     })
//       .then((response) => response.json())
//       .then((response) => {
//         if (response.message == unauthorized)
//           handleUnauthorized(response.message);
//         else if (response && response.status == "success")
//           resolve(response.data);
//         else reject();
//       })
//       .catch((error) => {
//         console.error(error);
//         reject();
//       });
//   });
// };
