import {
  Box,
  Button,
  Grid,
  TextField,
  Backdrop,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { SetPassword } from "../../services/CustomerService";
import { Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { action_types } from "../../types/ActionTypes";
import { ApplicationState } from "../../types/Master";
import { Dispatch } from "redux";
import UseTranslation from "../../services/UseTranslation";
import { Container } from "reactstrap";
import "../../custom.css";

const translation = UseTranslation();
const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    logout: () => dispatch({ type: "LOGOUT", payload: undefined }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  isLoading: state.isLoading,
  seleectedAccount: state.SelectedAccount,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class ChangePassword extends React.Component<PropsFromRedux> {
  state: {
    isRedirect: boolean;
    errors: string;
  } = {
    isRedirect: false,
    errors: "",
  };

  validate = (data: FormData) => {
    let pwd = data.get("password");
    let confirm_pwd = data.get("confirmpassword");
    let isValid = true;
    if (typeof pwd !== "undefined" && typeof confirm_pwd !== "undefined") {
      if (pwd != confirm_pwd) {
        isValid = false;
        this.setState({
          errors: translation.passwordMismatch,
        });
      }
    }
    return isValid;
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (this.validate(data)) {
      this.props.setLoader(true);
      SetPassword(
        data.get("email"),
        data.get("password"),
        "",
        "cp",
        this.props.seleectedAccount.Number
      )
        .then((res: any) => {
          this.props.setLoader(false);
          if (res.status == "success") {
            this.props.setToastMessage(translation.passwordUpdateSuccess, true);
            this.setState({ isRedirect: true });
            this.props.logout();
          } else {
            this.props.setToastMessage(translation.passwordUpdateFail, true);
            this.setState({ isRedirect: true });
          }
          this.props.setToastMessage(translation.passwordUpdateSuccess, true);
          this.setState({ isRedirect: true });
        })
        .catch((ex: any) => {
          this.props.setLoader(false);
          this.props.setToastMessage(translation.passwordUpdateFail, false);
          this.setState({ isRedirect: true });
        });
    }
  };

  render() {
    const { isRedirect } = this.state;
    const { isLoading } = this.props;

    if (isRedirect) {
      return <Redirect to="/SignIn" />;
    }

    return (
      <Container>
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <Box className="loading-icon" />
        </Backdrop>
        <Box component="form" onSubmit={this.handleSubmit} className="sx-login">
          <Grid container spacing={2}>
            <Grid item md={7}>
              <Box>
                <div className="text-center mb-5">
                  <img
                    src={require("../../images/changePwd.png")}
                    alt="Solmetex"
                    className="img-fluid"
                  />
                </div>
              </Box>
            </Grid>
            <Grid item md={5}>
              <Box className="sx-loginBg">
                <Typography variant="h1" className="sx-loginTitle mb-1">
                  Change password
                </Typography>
                <Typography className="mb-4 sx-grey">
                  Set your new password so you can Login and access again.
                </Typography>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label={translation.password}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  margin="normal"
                  className="sx-inputGroup"
                  InputProps={{ disableUnderline: true, className: "sx-input" }}
                  InputLabelProps={{ className: "sx-input-label" }}
                  variant="filled"
                />
                <TextField
                  required
                  fullWidth
                  name="confirmpassword"
                  label={translation.confirmPassword}
                  type="password"
                  id="confirmpassword"
                  autoComplete="confirm-password"
                  helperText={this.state.errors}
                  FormHelperTextProps={{
                    classes: {
                      root: "helper-text",
                    },
                  }}
                  margin="normal"
                  className="sx-inputGroup"
                  InputProps={{ disableUnderline: true, className: "sx-input" }}
                  InputLabelProps={{ className: "sx-input-label" }}
                  variant="filled"
                />
                <input
                  type="hidden"
                  name="email"
                  value={this.props.user.Email}
                ></input>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={this.props.isLoading}
                  className="sx-button mt-4"
                >
                  {translation.submit}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}
export default connector(ChangePassword);
