import {
  Box,
  Button,
  Grid,
  TextField,
  Backdrop,
  Container,
  Typography,
  Link,
} from "@material-ui/core";
import * as React from "react";
import { ForgotPassword } from "../../services/CustomerService";
import { ApplicationState } from "../../types/Master";
import { action_types } from "../../types/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { Redirect } from "react-router-dom";
import UseTranslation from "../../services/UseTranslation";
import "../../custom.css";
import SolmetexLogoSvg from "../../images/Solmetex_logo.svg";

const translation = UseTranslation();

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  isLoading: state.isLoading,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
class ForgetPassword extends React.Component<PropsFromRedux> {
  state: {
    isResetPasswordSucceeded: boolean;
    isResetPasswordFailed: boolean;
  } = {
    isResetPasswordSucceeded: false,
    isResetPasswordFailed: false,
  };

  handleLoginAlert = () => {
    this.setState({ isValid: false });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.setLoader(true);
    const data = new FormData(event.currentTarget);
    ForgotPassword(data.get("email"), data.get("accountNumber"))
      .then((res: any) => {
        this.props.setLoader(false);
        if (res.status == "success") {
          this.props.setToastMessage(translation.passwordRequestRaised, true);
          this.setState({ isResetPasswordSucceeded: true });
        } else {
          this.setState({ isResetPasswordFailed: true });
        }
      })
      .catch((ex) => {
        this.props.setLoader(false);
        this.props.setToastMessage(translation.passwordRequestRaised, true);
        this.setState({ isResetPasswordFailed: true });
      });
  };

  handleClose = () => {
    this.setState({ isResetPasswordFailed: false });
  };

  render() {
    const { isResetPasswordSucceeded, isResetPasswordFailed } = this.state;
    const { isLoading } = this.props;

    if (isResetPasswordSucceeded) {
      return <Redirect to="/SignIn" />;
    }

    return (
      <Box className="sx-signUpBg">
        <Container>
          <Backdrop
            style={{
              zIndex: 2250,
            }}
            open={isLoading}
          >
            <div className="loading-icon" />
          </Backdrop>
          <Box
            component="form"
            onSubmit={this.handleSubmit}
            className="sx-login"
          >
            <Grid container>
              <Grid item xs={12} md={6} className="m-auto text-center">
                <Box className="sx-loginBg">
                  <div className="text-center mb-5">
                    <img
                      src={SolmetexLogoSvg}
                      alt="Solmetex"
                      className="sx-loginLogo"
                    />
                  </div>
                  <Typography variant="h1" className="sx-loginTitle mb-1">
                    {translation.forgotPassword}
                  </Typography>
                  <Typography className="mb-4 sx-grey">
                    {translation.forgotPasswordInstruction}
                  </Typography>
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    type="email"
                    label={translation.emailAddress}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="accountNumber"
                    name="accountNumber"
                    label={translation.accountNumber}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    className="sx-button mt-4"
                  >
                    {translation.submit}
                  </Button>
                  {isResetPasswordFailed && (
                    <div className="error-text font-12 pt-2">
                      {translation.forgotPasswordFailedInstruction}
                      <a href="/signup">here</a>
                    </div>
                  )}
                  <Typography
                    variant="h5"
                    className="sx-loginSubTitle mt-4 pt-3"
                  >
                    {translation.goBack}
                    <Link href="/signin" className="sx-blue sx-bold ml-2">
                      {translation.signin}
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  }
}
export default connector(ForgetPassword);
