export default {
  account: "Account",
  accountName: "Account Name",
  accounts: "Accounts",
  active: "Active",
  action: "Action",
  accountNumber: "Account Number",
  accountOrPhone: "Account number or phone number",
  accountLookUpInstructions:
    "   To register, lookup your account by searching it with account number or phone number and zip code.",
  accountLookUpNotFound: "Acount lookup not found",
  accountNotFound: "We could not locate your account.Please create one here",
  activateStatusConfirmation:
    "Are you sure you want to activate this Subscription Service?",
  address: "Address",
  add: "Add New",
  addPromotion: "Add Promotion",
  alreadyHaveAnAccount: "Already have an account?",
  amalgamContainers: "Amalgam Containers",
  amalgamBuckets: "Amalgam Buckets",
  aproachingDeadline: "Approaching Deadline",
  bottlesize: "Bottle Size",
  caseTitle: "Case Title",
  caseType: "Case Type",
  caseStatus: "Case Status",
  cancel: "Cancel",
  caseDescription: "Case Description",
  supportCasesDescription:
    "Check the status of your current Support Cases or report new ones to our support services team.",
  certificate: "Certificate #",
  certificateofReceipt: "Certificate of Receipt",
  changePassword: "Change Password",
  changeAccount: "Change Account",
  city: "City",
  confirmPassword: "Confirm Password",
  createdOn: "Created On",
  createPersonalLogin: "Don’t have a login? to create your personal login",
  createLogin: "Create Your Personal Login - Step",
  createLoginInstructions:
    "Please complete this form to create your personal login",
  clickHere: "click here",
  compliance: "Compliance",
  compliancePlans: "Compliance Plans",
  complianceManagerUpdateSuccess:
    "Compliance plan manager updated successfully",
  createPromotion: "Create Promotion",
  customerid: "Customer ID",
  dashboard: "Dashboard",
  date: "Date Submitted",
  dateofsample: "Date of Sample",
  defaultDistributor: "Default Distributor",
  defaultDistributorUpdateSuccess: "Default distributor updated successfully",
  dealer: "Dealer",
  dealerText: "Dealer Text",
  dealerrepname: "Dealer Rep. Name",
  dealerrepemail: "Dealer Rep. Email",
  dealerrepphone: "Dealer Rep. Phone Number",
  deliverydate: "Delivery Date",
  delete: "Delete",
  description: "Description",
  dentalUnitWaterlineTestSubmission: "Dental Unit Waterline Test Submission",
  edit: "Edit",
  editComplianceManager: "Edit Subscription Services Manager",
  editDefaultDealer: "Edit Default Distributor",
  email: "Email",
  emailAddress: "Email Address",
  emailValidation: "Invalid email address",
  enterYourCredentials: " Enter your credentials to continue",
  failed: "Failed to update",
  firstName: "First Name",
  fileSizeError: "Please upload files less than 10Mb size",
  fillInDetails: "* Please fill in your details",
  fileDoesnotExist: "File doesn't exists",
  frequency: "Shipment Frequency (Months)",
  forgotPassword: "Forgot Password?",
  forgotPasswordInstruction:
    "Enter the email and account number used for registration to reset your password.",
  forgotPasswordFailedInstruction:
    "We could not locate your account.Please create one ",
  goBack: "Go back to",
  healthOfOrganization: "Health of Organization",
  image: "Image",
  important: "Important",
  incompliance: "In Compliance",
  inactive: "Inactive",
  installationdate: "Installation Date",
  installationCertificate: "Installation Certificate",
  itemNumber: "Item Number",
  inactiveStatusConfirmation:
    "Are you sure you want to inactivate this Subscription Service?",
  lastName: "LastName",
  lastRecycleDate: "Last Recycle Date",
  linkToFileUpload: "Link to File Uploaded",
  loginFailed: "Login failed. Incorrect username/password.",
  logOut: "Logout",
  lookupAccount: "Lookup Account",
  lookupAccountInstructions:
    "To continue with this account, please submit and proceed.If this is not your account please contact Solmetex at 800-216-5505 for assistance.",
  lookupAccountNotFound:
    "We're sorry, we cannot find your account number with the information you have provided. Please try again or contact Solmetex at 800-216-5505 and we will be happy to assist you.",
  lotnumber: "Lot Number",
  locationid: "Location Id",
  locateShippingCode: "Locate Shipping code",

  maintenanceProductCreationSuccess:
    "Subscription Services Created Successfully",
  maintenanceProductUpdateSuccess: "Subscription Services Updated Successfully",
  material: "Material",
  myAccount: "My Account",
  name: "Name",
  newProductSubscription: "New Product Subscription",
  nextship: "Next Ship Date",
  nextShipInformation: "The next shipment will be on",
  needYourProducts: "Need your <br /> product Now?",
  newProductRegistration: "New Product Registration",
  newWaterTesting: "New Water Testing",
  number: "Number",
  numberofbottles: "Number of Bottles",
  numberofChairs: "Number of Chairs",
  noProductReported: "No Product Reported",
  Otp: "OTP",
  outOfCompliance: "Out Of Compliance",
  offerSelected: "Offer Selected",
  orderNow: "Order Now",
  oneOrMoreSamplesFailed: "Did one or more samples fail?",
  paybleto: "Make Check Payable To",
  password: "Password",
  passwordRequestRaised: "Password change request failed.",
  passwordRequestRaised: "Password reset link sent to your email.",
  passwordUpdateSuccess:
    "Your Password updated successfully, please signin again with new password",
  passwordUpdateFail:
    "Password updation failed. Please check email and OTP and try again.",
  passwordMismatch: "* Passwords did not match.",
  passwordValidation:
    "* Password must contain 8 characters with at least 1 Upper Case, 1 lower case,1 special character and 1 numeric character",
  pdf: "PDF",
  phone: "Phone number",
  phoneValidationMessage:
    "Invalid phone number, phone number length should be minimum 10.",
  placeOrderValidation:
    "You will need to purchase through your large group or DSO",
  promotionStatus: "Promotion Status",
  productid: "Product ID",
  productIssueCreateSuccess: "Support Case Created Successfully",
  productnumber: "Product Number",
  productdescription: "Product Description",
  proofofInstallation: "Proof of Installation",
  proofofInstallationNotExist: "Proof of Installation doesn't exist",
  purchasedate: "Purchase Date",
  product: "Product",
  promotion: "Promotion",
  promotionCreateSuccess: "Promotion Request Created Successfully",
  promotionFileUpload:
    "Uploading a dealer invoice is required for processing this promotion",
  promotionEnrollment: "The promotion may require enrollment in the",
  promotionImageNotExist: "Dealer invoice doesn't exist",
  printRecyclingLabel: "Print Recycling Label",
  printLabel: "View/Print Label",
  printLabelBoldText1:
    "Ensure there are no other shipping or tracking labels attached to your package.",
  printLabelText1:
    "Select the Print button on the print dialogue box that appears. Note: If your browser does not support this function, select Print from the File menu to print the label.",
  printLabelBoldText2: "Fold the printed label at the solid line below.",
  printLabelText2:
    " Place the label in a UPS Shipping Pouch. If you do not have a pouch, affix the folded label using clear plastic shipping tape over the entire label.",
  printLabelBoldText3: "GETTING YOUR SHIPMENT TO UPS",
  printLabelBoldText4: "Customers with a scheduled Pickup",
  printLabelText3: "Your driver will pickup your shipment(s) as usual.",
  printLabelBoldText5: "Customers without a scheduled Pickup",
  printLabelText4:
    "Take your package to any location of The UPS Store®, UPS Access Point™(TM) location, UPS Drop Box, UPS Customer Center, Staples® or Authorized Shipping Outlet near you. To find the location nearest you, please visit the 'Locations' Quick link at ups.com.",
  printLabelText5:
    "Schedule a Pickup on ups.com to have a UPS driver pickup all of your packages",
  printLabelFoldHere: "FOLD HERE",
  pn: "P/N",
  placeOrder: "Place Order",
  quantity: "Quantity",
  requiredField: "Please fill out this field",
  recycledcertificates: "Recycling Certificates",
  recycledcertificate: "Recycling Certificate",
  recycledCertificatesDetails:
    "Keep track of all your recycling certificates in one place to help you stay compliant.",
  region: "Region",
  regionalCompliance: "Regional Compliance",
  regionalLocations: "Regional Locations",
  registrationProductSuccess: "Product Registration Created Successfully",
  registrationProductFailure: "Product Registration Failed",
  registrationSuccess: "Registration Successfully Completed",
  registrationFailed: "Registration Failed",
  registeredProducts: "Product Registration",
  registeredProductDetails:
    "Register your Solmetex, Sterisil, and DryShield products to access records like installation certificates and warranty cards.",
  requestContact:
    "Would you like to be contacted by a water safety specialist?",
  requestCertificate: "Would you like a certificate of dental water safety?",
  results: "Results",
  replacementdate: "Replacement Date",
  recordType: "Record Type",
  requestOrder: "Do you want to order stock and/or a retest?",
  samplesFailed: "Samples Failed",
  select: "Select",
  selectPromotion: "Select Current Promotion",
  selectType: "Select Type Of Request",
  selectTypeOfTest: "Select Type Of Test",
  selectFrequency: "Shipment Frequency (Months)",
  selectAccount: "Select Account",
  selectAccountInstructions:
    "To continue, please select an account and access the site.",
  selectProduct: "Select Product",
  selectCompliancePlanContact: "Select Subscription Services Contact",
  selectDistributor: "Select Dealer",
  selectDealer: "Select Dealer",
  selectTestingLab: "Select Testing Lab",
  serialNumber: "Serial Number",
  serialLotNumber: "Serial/Lot Number",
  selectProductValidation: "Please select product",
  selectContact: "Select Contact",
  selectDefaultDistributor: "Select Default Distributor",
  shippingCode: "Shipping Code",
  shippingLabel: "Shipping Label",
  shippingLabelDownloaded: "Shipping label downloaded",
  shippingLabelFailed: "Shipping label generation failed",
  shippingLabelSubText:
    "To process your returns shipping label, we need a little more information",
  shippingLabelImportantText:
    "Each recycling container requires its own shipping label with a unique Tracking Number",
  signin: "Sign In",
  signup: "Sign Up",
  solmetexComplianceProgram: "Solmetex Compliance Program",
  source: "Source",
  submit: "Submit",
  subregion: "Sub Region",
  supportCase: "Support Case",
  supportCases: "Support Cases",
  street: "Street",
  state: "State",
  stateProvince: "State/Province",
  status: "Status",
  straws: "Straws",
  statusChangeConfirmation: "Status Change Confirmation",
  stausUpdateInstructions:
    "If you wish to modify your next shipment date, click the submit button and select the Edit option in the table after that.",
  subscriptionService: "Subscription Services",
  subscriptionServiceDetails:
    "Make replenishing the products you need easy. Choose your products and schedule your deliveries with our convenient Subscription Services function.",
  subscriptionManager: "Subscription Services Manager",
  testtype: "Test Type",
  testIDmissing:"Test ID missing",
  testLabName: "Testing Lab",
  trackingnumber: "Tracking Number",
  title: "Title",
  totalAccounts: "Total Accounts",
  tooltipInCompliance:
    "<p>Office has changed or recycled collection container within 12 months.</p> <p>*Please note collection container should be inspected every month and changed and recycled if at collection container full line.</p>",
  tooltipOutOfCompliance:
    "<p>Office has not recycled collection container within 12 months.</p> <p>*Please note collection container should be inspected every month and changed and recycled if at collection container full line.</p>",
  tooltipAproachingDeadline:
    "<p>Office has not recycled collection container within 11 months.</p> <p>*Please note collection container should be inspected every month and changed and recycled if at collection container full line.</p>",
  tooltipNoProductReported:
    "<p>Office has no record of no product reported on file.</p>",
  unabletoloadimage: "Unable to load image",
  updateWaterTesting: "Update Water Testing",
  updateProductSubscription: "Update Product Subscription",
  updatePassword: "Update Password",
  uploadTestResults: "Upload Test Results",
  view: "View",
  verifySuccess: "Verification completed.",
  verifyFailed: "Verification failed.",
  waterCertificate: "Link to Water Certificate",
  waterTestingImage: "Link Image/Pdf",
  waterTestingResultDoesntExists: "Water report is not ready",
    waterTesting: "Water Testing",
    waterTestingResult: "Water Testing Result",
  waterTestingSuccess: "Water Testing Created Successfully",
  waterTestingUpdateSuccess: "Water Testing Updated Successfully",
  watertTestingFailure: "Water Testing Failed",
  waterTestingDetails:
    "Upload your dental water test results to track trends and ensure dental unit waterlines are safe and compliant.",
  weight: "Weight",
  welcome: "Welcome",
  zipCode: "Zip code",
  woocommerceredirectionissue: "Sorry, Unable to redirect",
};
