import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
    Link,
} from "@material-ui/core";
import * as React from "react";
import { AnyAction, Dispatch } from "redux";
import {
    ApplicationState,
    Product,
    ShippingLabelRequest,
    defaultShippingLabelRequest,
    defaultProduct,
} from "../../types/Master";
import { connect, ConnectedProps } from "react-redux";
import UseTranslation from "../../services/UseTranslation";
import "../../custom.css";
import { CreateShippingLabel } from "../../services/CustomerService";
import { GetRecyclableProducts } from "../../services/ProductService";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
    isLoading: state.isLoading,
    user: state.user,
    selectedAccount: state.SelectedAccount,
    isContactAccountsLoaded: state.IsContactAccountsLoaded,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {
        setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
        setToastMessage: (Message: string, Status: boolean) =>
            dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
        setShippingLabel: (val: string) =>
            dispatch({ type: "SET_SHIPPING_LABEL", payload: val }),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ShippingLabel = (props: PropsFromRedux) => {
    const {
        isLoading,
        selectedAccount,
        setLoader,
        setToastMessage,
        setShippingLabel,
        isContactAccountsLoaded,
    } = props;
    const [shippingLabelRequest, setshippingLabelRequest] =
        React.useState<ShippingLabelRequest>(defaultShippingLabelRequest);
    const [products, setProducts] = React.useState<Product[]>([]);
    const [phoneValidationMessage, setPhoneValidationMessage] =
        React.useState<string>("");

    React.useEffect(() => {
        setLoader(true);
        Promise.all([getProducts()])
            .then(() => setLoader(false))
            .catch((ex) => setLoader(false));
    }, []);

    React.useEffect(() => {
        if (selectedAccount && selectedAccount.Number != "") {
            setshippingLabelRequest({
                ...shippingLabelRequest,
                Account: { ...selectedAccount },
            });
        }
    }, [selectedAccount]);

    const getProducts = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            GetRecyclableProducts()
                .then((products: Product[]) => {
                    setProducts(products);
                    resolve();
                })
                .catch((ex) => {
                    console.error(ex);
                    reject();
                });
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setPhoneValidationMessage("");

        if (selectedAccount.Phone.length !== 0 || shippingLabelRequest.PhoneNumber.length >= 10) {
            setLoader(true);

            // Open the new window directly in response to the user action
            const newWindow = window.open("about:blank");

            if (newWindow) {
                const htmlContent = `
                <html>
                <head>
                    <title>Shipping Label...</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100vh;
                            margin: 0;
                        }
                        p {
                            font-weight: bold;
                            color: blue;
                            text-align: center;
                        }
                    </style>
                </head>
                <body>
                    <p>Creating the Shipping Label. Please don't close the window.</p>
                </body>
                </html>
            `;
                newWindow.document.write(htmlContent);
                newWindow.document.close(); // Ensure the document is closed and rendered

                let selectedProduct = products.filter(x => x.ID === shippingLabelRequest.Product.ID)[0];
                shippingLabelRequest.Product = selectedProduct;

                CreateShippingLabel(shippingLabelRequest)
                    .then((res: any) => {
                        setLoader(false);
                        if (res.status === "success") {
                            const linkSource = "data:image/png;base64," + res.data;
                            setShippingLabel(linkSource);
                            newWindow.location.href = "/viewshippinglabel";
                            newWindow.focus();
                        } else {
                            newWindow.close();
                            setToastMessage(res.data, false);
                        }
                    })
                    .catch((ex) => {
                        setLoader(false);
                        setToastMessage(translation.shippingLabelFailed, false);
                        console.error(ex);
                        newWindow.close();
                    });
            } else {
                setToastMessage("Pop-up blocker is enabled. Please allow pop-ups for this site.", false);
            }
        } else {
            setPhoneValidationMessage(translation.phoneValidationMessage);
        }
    };

    return (
        <Box className="mx-4 mb-5">
            <Backdrop
                style={{
                    zIndex: 2250,
                }}
                open={isLoading}
            >
                <CircularProgress />
            </Backdrop>
            <Box
                component="form"
                onSubmit={handleSubmit}
                className="d-flex flex-column col-lg-5 col-md-6 m-auto"
            >
                <Typography variant="h1" className="sx-loginTitle text-center">
                    {translation.shippingLabel}
                </Typography>
                <Typography className="mb-4 sx-grey text-center">
                    {translation.welcome}{" "}
                    <span className="sx-blue sx-semiBold">{selectedAccount.Name}</span>
                    <br />
                    {translation.shippingLabelSubText}.
                </Typography>
                <FormControl
                    variant="filled"
                    className="sx-inputGroup sx-customSelect my-2"
                >
                    <Select
                        id="select-product"
                        className="sx-input"
                        disableUnderline={true}
                        onChange={(e) =>
                            setshippingLabelRequest({
                                ...shippingLabelRequest,
                                Product: {
                                    ...defaultProduct,
                                    ID: e.target.value as string,
                                },
                            })
                        }
                        value={shippingLabelRequest.Product.ID}
                        required
                        displayEmpty
                    >
                        <MenuItem value="" className="disabled">
                            {`${translation.selectProduct} *`}
                        </MenuItem>
                        {products &&
                            products.length > 0 &&
                            products.map((item) => {
                                return (
                                    <MenuItem value={item.ID}>
                                        {item.QuoteProductName != ""
                                            ? `${item.Number} - ${item.QuoteProductName}`
                                            : item.Number}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                <TextField
                    className="sx-inputGroup my-2"
                    id="shippingCode"
                    name="shippingCode"
                    label={translation.shippingCode}
                    InputProps={{
                        disableUnderline: true,
                        className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                    FormHelperTextProps={{
                        classes: {
                            root: "helper-text",
                        },
                    }}
                    multiline
                    required
                    onChange={(e) => {
                        setshippingLabelRequest({
                            ...shippingLabelRequest,
                            ShippingCode: e.target.value as string,
                        });
                    }}
                />
                {selectedAccount != null &&
                    (selectedAccount.Phone == null ||
                        selectedAccount.Phone == "" ||
                        selectedAccount.Phone.length == 0) && (
                        <TextField
                            className="sx-inputGroup my-2"
                            id="phoneNumber"
                            name="phoneNumber"
                            label={translation.phone}
                            InputProps={{
                                disableUnderline: true,
                                className: "sx-input",
                            }}
                            InputLabelProps={{ className: "sx-input-label" }}
                            variant="filled"
                            helperText={phoneValidationMessage}
                            FormHelperTextProps={{
                                classes: {
                                    root: "error-text pt-2",
                                },
                            }}
                            required
                            onChange={(e) => {
                                setshippingLabelRequest({
                                    ...shippingLabelRequest,
                                    PhoneNumber: e.target.value as string,
                                });
                            }}
                        />
                    )}
                <Box className="d-flex my-2 justify-content-between">
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!(selectedAccount && selectedAccount.Number != "")}
                        className="sx-button"
                    >
                        {translation.submit}
                    </Button>
                    <Typography className="mt-2 text-right">
                        <Link
                            href={require("../../images/CC_PWS_Lid_instructions.pdf")}
                            target="_blank"
                            className="sx-blue sx-underLine"
                        >
                            {translation.locateShippingCode}
                        </Link>
                    </Typography>
                </Box>
                <Box className="mb-6 mt-4">
                    <div className="alert alert-danger small" role="alert">
                        <span className="sx-bold sx-uppercase">
                            {translation.important}:
                        </span>{" "}
                        {translation.shippingLabelImportantText}.
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default connector(ShippingLabel);
