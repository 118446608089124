import {
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  DialogContentText,
  IconButton,
} from "@material-ui/core";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import UseTranslation from "../../../services/UseTranslation";
import {
  ApplicationState,
  WaterTesting,
  Vial,
  defaultVial,
} from "../../../types/Master";
import {
  WaterTestTypeValues,
  WaterTestDSBrands,
  WaterTestDSTypes,
  ShockProductUsed,
  SourceWaterDS,
  ShockBeforeInstall,
} from "../../../services/Shared";
import {
  EnhancedTableHead,
  getComparator,
  HeadCell,
  OrderType,
  stableSort,
} from "../../shared/Table";
import { AnyAction, Dispatch } from "redux";
import AddVials from "./AddVials";
import moment from "moment";
import { Delete } from "@material-ui/icons";

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  selectedAccount: state.SelectedAccount,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const translation = UseTranslation();
interface OwnProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleStepButton: (number: Number) => void;
  waterTetsingRequest: WaterTesting;
  setWaterTestingDataRequest: React.Dispatch<
    React.SetStateAction<WaterTesting>
  >;
}
export type ddDSStep2FormProps = PropsFromRedux & OwnProps;

const headCells: HeadCell[] = [
  {
    id: "RowId",
    numeric: true,
    disableSorting: false,
    label: "Row ID",
  },
  {
    id: "SampleNumber",
    numeric: false,
    disableSorting: false,
    label: "SampleNumber",
  },
  {
    id: "Room",
    numeric: false,
    disableSorting: false,
    label: "Room / Chair / Operatory#",
  },
  {
    id: "Delete",
    numeric: false,
    disableSorting: true,
    label: translation.delete,
  },
];

const defaultcolumns = ["RowId", "SampleNumber", "Room"];

interface errorValidation {
  DoctorName: string;
  ComplianceOfficerEmail: string;
  Vials: string;
  Type: string;
  Brand: string;
}

const defaultErrorValidation = {
  DoctorName: "",
  ComplianceOfficerEmail: "",
  Vials: "",
  Type: "",
  Brand: "",
};

const DSStep2Form = (props: ddDSStep2FormProps) => {
  const {
    isLoading,
    setLoader,
    setToastMessage,
    selectedAccount,
    handleStepButton,
    waterTetsingRequest,
    handleSubmit,
    setWaterTestingDataRequest,
  } = props;
  const [showHowLongSinceWaterTreatment, setHowLongSinceWaterTreatment] =
    React.useState<boolean>(false);
  const [showBrands, setShowBrands] = React.useState<boolean>(false);
  const [showOther, setShowOther] = React.useState<boolean>(false);
  const [showOtherStraw, setShowOtherStraw] = React.useState<boolean>(false);
  const [showInstallDate, setShowInstallDate] = React.useState<boolean>(false);
  const [showShockProductUsed, setShowShockProductUsed] =
    React.useState<boolean>(false);

  const [showSOurceWater, setShowSOurceWater] = React.useState<boolean>(false);

  const [showShockBeforeInstall, setShowShockBeforeInstall] =
    React.useState<boolean>(false);
  const [showOtherTypeofTreatment, setShowOtherTypeofTreatment] =
    React.useState<boolean>(false);
  const [showSinkName, setShowSinkName] = React.useState<boolean>(false);
  const [showVialPopUp, setShowVialPopUp] = React.useState<boolean>(false);
  const [selectedType, setSelectedType] = React.useState<string>("100000000");
  const [filteredBrands, setFilteredBrands] =
    React.useState<any>(WaterTestDSBrands);
  const [vials, setVials] = React.useState<Vial[]>([]);
  const [vial, setVial] = React.useState<Vial>(defaultVial);
  const [order, setOrder] = React.useState<OrderType>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("CreatedOn");
  const [installDate, setInstallDate] = React.useState<string>("");
  const [showWaterSourceBrand, setShowWaterSourceBrand] =
    React.useState<boolean>(false);
  const [showWaterSourceOther, setShowWaterSourceOther] =
    React.useState<boolean>(false);
  const [showTypeRequired, setShowTypeRequired] =
    React.useState<boolean>(false);
  const [showBrandRequired, setBrandRequired] = React.useState<boolean>(false);
  const [shockBeforeRequired, setShockBeforeRequired] =
    React.useState<boolean>(false);
  const [showInstallDateRequired, setInstallDateRequired] =
    React.useState<boolean>(false);
  const [showVialRequired, setShowVialRequired] =
    React.useState<boolean>(false);
  const [showShockProductRequired, setShowShockProductRequired] =
    React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<errorValidation>({
    ...defaultErrorValidation,
  });

  React.useEffect(() => {
    let currentday = moment().get("date");
    let nextDate: moment.Moment;
    let now: moment.Moment;
    now = moment();
    installDate: now.format("YYYY-MM-DD");
  });

  React.useEffect(() => {
    if (waterTetsingRequest != null && waterTetsingRequest.Type != null) {
      setSelectedType(waterTetsingRequest.Type);
    }
    var newBrands = WaterTestDSBrands.filter(
      (brand) => brand.type === selectedType
    );
    setFilteredBrands(newBrands);
    setTypeSelection(selectedType);
    if (selectedType == "100000000" || selectedType == "100000001") {
      setShowBrands(true);
    } else {
      setShowBrands(false);
    }
  }, [selectedType]);

  const handleCancel = (e: any) => {
    e.preventDefault();
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const errorValidation = (request: WaterTesting): boolean => {
    debugger;
    let errorsObject = { ...defaultErrorValidation };
    let isValid = true;
    if (request.Type == null || request.Type.length == 0) {
      errorsObject = { ...errorsObject, Type: translation.requiredField };
      isValid = false;
      setShowTypeRequired(true);
    } else {
      setShowTypeRequired(false);
    }

    if (
      (request.Type == "100000000" || request.Type == "100000001") &&
      (request.Brand == null || request.Brand.length == 0)
    ) {
      errorsObject = { ...errorsObject, Brand: translation.requiredField };
      isValid = false;
      setBrandRequired(true);
    } else {
      setBrandRequired(false);
    }

    if (request.Vials == null || request.Vials.length == 0) {
      errorsObject = { ...errorsObject, Vials: translation.requiredField };
      isValid = false;
      setShowVialRequired(true);
    } else {
      setShowVialRequired(false);
    }

    if (
      request.Type == "100000001" &&
      (request.InstallationDate == null || request.InstallationDate.length == 0)
    ) {
      errorsObject = { ...errorsObject, Type: translation.requiredField };
      isValid = false;
      setInstallDateRequired(true);
      setShockBeforeRequired(true);
    } else {
      setInstallDateRequired(false);
      setShockBeforeRequired(false);
    }

    if (
      request.Type == "100000000" &&
      (request.ShockProductUsed == null || request.ShockProductUsed.length == 0)
    ) {
      errorsObject = { ...errorsObject, Type: translation.requiredField };
      isValid = false;
      setShowShockProductRequired(true);
    } else {
      setShowShockProductRequired(false);
    }
    setErrors({ ...errorsObject });
    return isValid;
  };

  const handlFormeSubmit = (event: any) => {
    // Invoke the callback function passed from the parent
    if (errorValidation(waterTetsingRequest)) {
      var strWaterTestingDataRequest = JSON.stringify(
        props.waterTetsingRequest
      );
      //localStorage.setItem("dentisaferequest", strWaterTestingDataRequest);
      //localStorage.setItem("dentisaferequest", strWaterTestingDataRequest);
      handleSubmit(event);
    } else {
    }
  };

  // submit button event
  //   const handleSubmit = (e: any) => {
  //     e.preventDefault();
  //     //props.onsubmit();
  //   };

  const handlePage = (e: any) => {
    e.preventDefault();
  };

  const setTypeSelection = (value: string) => {
    if (value == "100000000") {
      setHowLongSinceWaterTreatment(true);
      setShowShockProductUsed(true);
    } else {
      setHowLongSinceWaterTreatment(false);
      setShowShockProductUsed(false);
    }

    if (value == "100000001") {
      setShowInstallDate(true);
      setShowShockBeforeInstall(true);
    } else {
      setShowInstallDate(false);
      setShowShockBeforeInstall(false);
    }

    if (value == "100000002") {
      setShowOtherTypeofTreatment(true);
    } else {
      setShowOtherTypeofTreatment(false);
    }

    if (value == "100000003") {
      setShowSOurceWater(true);
    } else {
      setShowSOurceWater(false);
    }
    //setShowTypeRequired(false);
  };

  const handleDSTypeSelection = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTetsingRequest,
      Type: e.target.value as string,
    });
    //props.waterTetsingRequest.Type=e.target.value;
    setSelectedType(e.target.value);
    setTypeSelection(e.target.value);
    setShowTypeRequired(false);
    setShowWaterSourceBrand(false);
    setShowSinkName(false);
    setShowWaterSourceBrand(false);
    setShowOther(false);
    setShowWaterSourceOther(false);
  };

  const handleDSShockBeforeInstallSelection = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTetsingRequest,
      ShockBeforeInstall: e.target.value,
    });

    props.waterTetsingRequest.ShockBeforeInstall = e.target.value;
    setShockBeforeRequired(false);
  };
  const handleDSBrandSelection = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTetsingRequest,
      Brand: e.target.value as string,
    });
    props.waterTetsingRequest.Brand = e.target.value;
    setBrandRequired(false);
    if (e.target.value == "100000007") {
      setShowOtherStraw(true);
    } else {
      setShowOtherStraw(false);
    }
  };
  const handleDSShockProductsSelection = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTetsingRequest,
      ShockProductUsed: e.target.value as string,
    });
    props.waterTetsingRequest.ShockProductUsed = e.target.value;
    console.log(waterTetsingRequest);
    if (e.target.value == "100000004") {
      setShowOther(true);
    } else {
      setShowOther(false);
    }
    setShowShockProductRequired(false);
  };

  const handleDSSourceWaterSelection = (e: any) => {
    props.waterTetsingRequest.SourceofWater = e.target.value;
    setWaterTestingDataRequest({
      ...waterTetsingRequest,
      SourceofWater: e.target.value as string,
    });
    if (e.target.value == "100000000") {
      setShowSinkName(true);
    } else {
      setShowSinkName(false);
    }
    if (e.target.value == "100000002") {
      setShowWaterSourceBrand(true);
    } else {
      setShowWaterSourceBrand(false);
    }

    if (e.target.value == "100000003") {
      setShowWaterSourceOther(true);
    } else {
      setShowWaterSourceOther(false);
    }
  };

  const handleClose = () => {
    setShowVialPopUp(false);
  };

  const handleAddVial = (event: any) => {
    // Handle the event in the parent component
    const newVial: Vial = {
      ID: vials.length + 1,
      SampleNumber: vial.SampleNumber,
      RoomChairOperatory: vial.RoomChairOperatory,
      DeviceOrSourceWater: vial.DeviceOrSourceWater,
      BottleOrMunicipal: vial.BottleOrMunicipal,
      OtherInformation: vial.OtherInformation,
    };
    setVials((current) => [...current, newVial]);

    setWaterTestingDataRequest((prevRequest) => ({
      ...prevRequest,
      Vials: [...prevRequest.Vials, newVial],
    }));
    console.log(waterTetsingRequest);
    setVial(defaultVial);
    setShowVialRequired(false);
  };

  const handleVialDelete = (vial: Vial) => {
    console.log(vial);
    debugger;
    let vials = waterTetsingRequest.Vials.filter((item) => item.ID !== vial.ID);
    setWaterTestingDataRequest({ ...waterTetsingRequest, Vials: vials });
    //setUpdateWaterTesting({ ...waterTesting });
  };

  return (
    <Box component="form">
      {/* <Typography className="tw-2xl-text">DUWL Treatment</Typography> */}
      <Box className="tw-inline-flex tw-items-center tw-justify-center tw-w-full mb-1">
        <hr className="tw-w-64 tw-h-px tw-my-2 tw-bg-gray-200 tw-border-0" />
        <span className="tw-absolute tw-px-3 tw-font-medium tw-text-gray-400 tw--translate-x-1/2 tw-bg-white tw-left-1/2">
          DUWL Treatment
        </span>
      </Box>
      <Box>
        <FormControl
          variant="filled"
          fullWidth
          className="sx-inputGroup sx-customSelect my-2"
        >
          <Select
            id="select-type"
            className="sx-input"
            name="dstype"
            disableUnderline={true}
            value={props.waterTetsingRequest.Type}
            onChange={(e) => handleDSTypeSelection(e)}
            displayEmpty
          >
            <MenuItem value="" className="disabled">
              {translation.select}
            </MenuItem>
            {WaterTestDSTypes.map((item: any) => {
              return <MenuItem value={item.id}>{item.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        {showTypeRequired && (
          <div className="error-text font-10 pl-4 text-left">Required</div>
        )}
      </Box>

      <Box>
        {showBrands && (
          <Box>
            <FormControl className="tw-w-full !tw-mt-6">
              <FormLabel
                id="radio-brand-label"
                className="!tw-font-bold !tw-text-black"
              >
                Brand
              </FormLabel>

              <RadioGroup
                value={props.waterTetsingRequest.Brand}
                onChange={(e) => handleDSBrandSelection(e)}
              >
                {filteredBrands.map((item: any) => (
                  <FormControlLabel
                    key={item.id}
                    value={item.id}
                    control={<Radio />}
                    label={item.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {showBrandRequired && (
              <div className="error-text font-10 pl-4 text-left">Required</div>
            )}
          </Box>
        )}
        {showOtherStraw && (
          <Box>
            <TextField
              className="sx-inputGroup"
              margin="normal"
              fullWidth
              id="otherstraw"
              name="otherstraw"
              type="text"
              label="Other"
              onChange={(e) => {
                setWaterTestingDataRequest({
                  ...waterTetsingRequest,
                  OtherStraw: e.target.value as string,
                });
              }}
              value={waterTetsingRequest.OtherStraw}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
                inputProps: { min: 1 },
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
          </Box>
        )}
        {showHowLongSinceWaterTreatment && (
          <TextField
            className="sx-inputGroup"
            margin="normal"
            fullWidth
            id="shockusedsince"
            name="shockusedsince"
            type="text"
            placeholder="# of Days or 'Never Shocked'"
            label="How long since last shock treatment?"
            onChange={(e) => {
              setWaterTestingDataRequest({
                ...waterTetsingRequest,
                HowLongSinceLastShockTreatment: e.target.value as string,
              });
            }}
            value={props.waterTetsingRequest.HowLongSinceLastShockTreatment}
            InputProps={{
              disableUnderline: true,
              className: "sx-input",
              inputProps: { min: 1 },
            }}
            InputLabelProps={{ className: "sx-input-label" }}
            variant="filled"
          />
        )}

        {showOtherTypeofTreatment && (
          <TextField
            className="sx-inputGroup"
            margin="normal"
            fullWidth
            id="typeoftreatment"
            name="typeoftreatment"
            type="text"
            label="Type of treatment"
            onChange={(e) => {
              setWaterTestingDataRequest({
                ...waterTetsingRequest,
                TypeOfTreatment: e.target.value as string,
              });
            }}
            value={waterTetsingRequest.TypeOfTreatment}
            InputProps={{
              disableUnderline: true,
              className: "sx-input",
              inputProps: { min: 1 },
            }}
            InputLabelProps={{ className: "sx-input-label" }}
            variant="filled"
          />
        )}

        {showInstallDate && (
          <Box>
            <TextField
              className={`sx-inputGroup ${
                showInstallDateRequired && "sx-validationFailed"
              }`}
              margin="normal"
              fullWidth
              id="installdate"
              name="installdate"
              label="Install Date"
              type="date"
              defaultValue={moment().format("yyyy-mm-dd")}
              onChange={(e) => {
                setWaterTestingDataRequest({
                  ...waterTetsingRequest,
                  InstallationDate: e.target.value as string,
                });
              }}
              value={waterTetsingRequest.InstallationDate}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
                inputProps: { min: 1 },
              }}
              InputLabelProps={{ className: "sx-input-label", shrink: true }}
              variant="filled"
            />
            {showInstallDateRequired && (
              <div className="error-text font-10 pl-4 text-left">Required</div>
            )}
          </Box>
        )}

        {showShockBeforeInstall && (
          <Box>
            <FormControl className="tw-w-full !tw-mt-6">
              <FormLabel
                id="radio-shockbefore-label"
                className={`sx-input ${
                  shockBeforeRequired && "sx-validationFailed"
                }`}
              >
                Shock before install?
              </FormLabel>

              <RadioGroup
                value={props.waterTetsingRequest.ShockBeforeInstall}
                onChange={(e) => handleDSShockBeforeInstallSelection(e)}
              >
                {ShockBeforeInstall.map((item: any) => (
                  <FormControlLabel
                    key={item.id}
                    value={item.id}
                    control={<Radio />}
                    label={item.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {shockBeforeRequired && (
              <div className="error-text font-10 pl-4 text-left">Required</div>
            )}
          </Box>
        )}

        {showShockProductUsed && (
          <Box>
            <FormControl className="tw-w-full !tw-mt-6">
              <FormLabel
                id="radio-brand-label"
                className="!tw-font-bold !tw-text-black"
              >
                Shock Product Used
              </FormLabel>

              <RadioGroup
                value={props.waterTetsingRequest.ShockProductUsed}
                onChange={(e) => handleDSShockProductsSelection(e)}
              >
                {ShockProductUsed.map((item: any) => (
                  <FormControlLabel
                    key={item.id}
                    value={item.id}
                    control={<Radio />}
                    label={item.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {showShockProductRequired && (
              <div className="error-text font-10 pl-4 text-left">Required</div>
            )}
          </Box>
        )}

        {showOther && (
          <TextField
            className="sx-inputGroup"
            margin="normal"
            fullWidth
            id="othershockproduct"
            name="othershockproduct"
            type="text"
            label="Other"
            onChange={(e) => {
              setWaterTestingDataRequest({
                ...waterTetsingRequest,
                OtherShockProductUsed: e.target.value as string,
              });
            }}
            value={waterTetsingRequest.OtherShockProductUsed}
            InputProps={{
              disableUnderline: true,
              className: "sx-input",
              inputProps: { min: 1 },
            }}
            InputLabelProps={{ className: "sx-input-label" }}
            variant="filled"
          />
        )}

        {showSOurceWater && (
          <FormControl className="tw-w-full !tw-mt-6">
            <FormLabel
              id="radio-brand-label"
              className="!tw-font-bold !tw-text-black"
            >
              Source Water
            </FormLabel>
            <RadioGroup
              value={props.waterTetsingRequest.SourceofWater}
              onChange={(e) => handleDSSourceWaterSelection(e)}
            >
              {SourceWaterDS.map((item: any) => (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  control={<Radio />}
                  label={item.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}

        {showWaterSourceBrand && (
          <TextField
            className="sx-inputGroup"
            margin="normal"
            fullWidth
            id="swbrand"
            name="swbrand"
            type="text"
            label="Brand"
            onChange={(e) => {
              setWaterTestingDataRequest({
                ...waterTetsingRequest,
                SourceofWaterBrand: e.target.value as string,
              });
            }}
            value={waterTetsingRequest.SourceofWaterBrand}
            InputProps={{
              disableUnderline: true,
              className: "sx-input",
              inputProps: { min: 1 },
            }}
            InputLabelProps={{ className: "sx-input-label" }}
            variant="filled"
          />
        )}

        {showWaterSourceOther && (
          <TextField
            className="sx-inputGroup"
            margin="normal"
            fullWidth
            id="swOther"
            name="swOther"
            type="text"
            label="Other"
            onChange={(e) => {
              setWaterTestingDataRequest({
                ...waterTetsingRequest,
                OtherWaterBottles: e.target.value as string,
              });
            }}
            value={waterTetsingRequest.OtherWaterBottles}
            InputProps={{
              disableUnderline: true,
              className: "sx-input",
              inputProps: { min: 1 },
            }}
            InputLabelProps={{ className: "sx-input-label" }}
            variant="filled"
          />
        )}

        {showSinkName && (
          <TextField
            className="sx-inputGroup"
            margin="normal"
            fullWidth
            id="sinkName"
            name="sinkName"
            type="text"
            label="Sink name or #"
            onChange={(e) => {
              setWaterTestingDataRequest({
                ...waterTetsingRequest,
                SinkName: e.target.value as string,
              });
            }}
            value={waterTetsingRequest.SinkName}
            InputProps={{
              disableUnderline: true,
              className: "sx-input",
              inputProps: { min: 1 },
            }}
            InputLabelProps={{ className: "sx-input-label" }}
            variant="filled"
          />
        )}
      </Box>

      <Box className="tw-mt-6">
        <Box className="tw-inline-flex tw-items-center tw-justify-center tw-w-full mb-1">
          <hr className="tw-w-64 tw-h-px tw-my-2 tw-bg-gray-200 tw-border-0" />
          <span className="tw-absolute tw-px-3 tw-font-medium tw-text-gray-400 tw--translate-x-1/2 tw-bg-white tw-left-1/2">
            Vials
          </span>
        </Box>

        <TableContainer className="mt-2">
          <Table>
            <EnhancedTableHead
              headCells={headCells}
              rowCount={vials.length}
              displayActionCell={false}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {waterTetsingRequest.Vials.length == 0 ? (
              <TableBody className="sx-tableBody">
                <TableRow>
                  <TableCell
                    className="mb-5 p-3 text-center"
                    colSpan={headCells.length}
                  >
                    <img
                      src={require("../../../images/empty-state.png")}
                      alt="Solmetex"
                      className="img-fluid tw-mx-auto tw-h-[100px]"
                    />
                    <Typography className="mt-1 tw-text-gray-400">
                      There are no vials
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="sx-tableBody">
                {waterTetsingRequest.Vials.map((vial) => {
                  return (
                    <TableRow>
                      <TableCell component="th" scope="row" width={180}>
                        {vial.ID}
                      </TableCell>
                      <TableCell component="th" scope="row" width={180}>
                        {vial.SampleNumber}
                      </TableCell>
                      <TableCell>{vial.RoomChairOperatory}</TableCell>
                      <TableCell>
                        <Box className="d-flex align-items-center">
                          <IconButton
                            aria-label="edit"
                            size="small"
                            className="sx-buttonIcon ml-1"
                            onClick={() => handleVialDelete(vial)}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <AddVials setVials={handleAddVial} vial={vial} setVial={setVial} />

        {showVialRequired && (
          <div className="error-text font-10 pl-4 text-left">Required</div>
        )}
      </Box>

      <Box className="tw-flex tw-justify-end">
        <Button
          variant="text"
          className="sx-button sx-textButton mt-3 mr-3 !tw-min-w-[100px]"
          onClick={() => props.handleStepButton(1)}
        >
          Back
        </Button>
        <Button
          onClick={handlFormeSubmit}
          variant="contained"
          className="sx-button mt-3 !tw-rounded-lg !tw-h-[48px] !tw-min-w-[180px]"
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default connector(DSStep2Form);
