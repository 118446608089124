import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  TextField,
  MenuItem,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import moment from "moment";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { UpdateMaintenancePlan } from "../../services/ProductService";
import { getFormatedDate, getNextShipDate } from "../../services/Shared";
import UseTranslation from "../../services/UseTranslation";
import {
  Account,
  ApplicationState,
  defaultAccount,
  defaultMaintenancePlan,
  initialUserState,
  MaintenancePlan,
  Product,
  User,
} from "../../types/Master";
import { Autocomplete } from "@material-ui/lab";

const translation = UseTranslation();

interface errorValidation {
  product: string;
  dealer: string;
  frequency: string;
  quantity: string;
  contact: string;
}

const defaultErrorValidation = {
  product: "",
  dealer: "",
  frequency: "",
  quantity: "",
  contact: "",
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedAccountContact: state.SelectedAccountContact,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    setSelectedAccount: (val: Account) =>
      dispatch({ type: "SET_SELECTED_ACCOUNT", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface OwnProps {
  updateComplianceRequest: MaintenancePlan;
  products: Product[];
  dealers: Account[];
  contacts: User[];
  setUpdateComplianceRequest: (request: MaintenancePlan) => void;
  getPlans: () => Promise<void>;
}

export type addMaintenancePlanProductProps = PropsFromRedux & OwnProps;

const AddMaintenancePlanProduct = (props: addMaintenancePlanProductProps) => {
  const {
    user,
    updateComplianceRequest,
    isLoading,
    selectedAccount,
    selectedAccountContact,
    products,
    dealers,
    contacts,
    setSelectedAccount,
    setUpdateComplianceRequest,
    getPlans,
    setLoader,
    setToastMessage,
  } = props;
  const [isAddNew, setIsAdNew] = React.useState<boolean>(false);
  const [addNewComplianceRequest, setAddNewComplianceRequest] =
    React.useState<MaintenancePlan>({ ...defaultMaintenancePlan });
  const [nextShipMinDate, setNextShipMinDate] = React.useState<string>("");
  const [errors, setErrors] = React.useState<errorValidation>({
    ...defaultErrorValidation,
  });

  React.useEffect(() => {
    setNextShipMinDate(getNextShipDate().substring(0, 7));
  }, []);

  React.useEffect(() => {
    if (updateComplianceRequest.ID != "") {
      setIsAdNew(true);
      setErrors({ ...defaultErrorValidation });
      setAddNewComplianceRequest({
        ...updateComplianceRequest,
        NextShipDate: updateComplianceRequest.NextShipDate.substring(0, 7),
        Contact: {
          ...selectedAccount.PrimaryContact,
        },
        Account: { ...selectedAccount },
        LoggedInContact: { ...user },
      });
    }
  }, [updateComplianceRequest]);

  const handleAddNew = (e: any) => {
    e.preventDefault();
    var Contact = user;
    if (selectedAccountContact != null && selectedAccountContact.ID != "") {
      Contact = selectedAccountContact;
    }
    if (selectedAccount.DefaultDealer.ID != "") {
      setAddNewComplianceRequest({
        ...defaultMaintenancePlan,
        Dealer: {
          ...defaultMaintenancePlan.Dealer,
          ID: selectedAccount.DefaultDealer.ID,
          Name: selectedAccount.DefaultDealer.Name,
        },
        Contact: {
          ...Contact,
        },
        Account: { ...defaultAccount, ID: selectedAccount.ID },
        NextShipDate: nextShipMinDate,
        LoggedInContact: { ...user },
      });
    } else {
      setAddNewComplianceRequest({
        ...defaultMaintenancePlan,
        Contact: {
          ...Contact,
        },
        Account: { ...defaultAccount, ID: selectedAccount.ID },
        NextShipDate: nextShipMinDate,
        LoggedInContact: { ...user },
      });
    }
    setUpdateComplianceRequest({ ...defaultMaintenancePlan });
    setIsAdNew(true);
  };

  const handleClose = () => {
    setIsAdNew(false);
  };

  const errorValidation = (updaterequest: MaintenancePlan): boolean => {
    let errorsObject = { ...defaultErrorValidation };
    let isValid = true;
    if (updaterequest.Product.ID == "") {
      errorsObject = { ...errorsObject, product: translation.requiredField };
      isValid = false;
    }
    if (updaterequest.Frequency == "") {
      errorsObject = { ...errorsObject, frequency: translation.requiredField };
      isValid = false;
    }
    if (updaterequest.Dealer.ID == "") {
      errorsObject = { ...errorsObject, dealer: translation.requiredField };
      isValid = false;
    }
    setErrors({ ...errorsObject });
    return isValid;
  };

  const handleCreateMaintenancePlan = (e: any) => {
    e.preventDefault();
    if (errorValidation(addNewComplianceRequest)) {
      setLoader(true);
      let updaterequest = {
        ...addNewComplianceRequest,
        NextShipDate: moment(
          addNewComplianceRequest.NextShipDate + "-01"
        ).utc(),
      };

      UpdateMaintenancePlan(updaterequest)
        .then((res) => {
          setIsAdNew(false);
          if (res != "") {
            setLoader(false);
            setToastMessage(res, false);
          } else {
            if (updaterequest.IsMaintenancePlanManagerUpdated) {
              contacts.every((item: User) => {
                if (item.ID == updaterequest.Contact.ID) {
                  setSelectedAccount({
                    ...selectedAccount,
                    PrimaryContact: { ...item },
                  });
                  return false;
                } else return true;
              });
            }
            if (updaterequest.IsDefaultDistributorUpdated) {
              dealers.every((item: Account) => {
                if (item.ID == updaterequest.Dealer.ID) {
                  setSelectedAccount({
                    ...selectedAccount,
                    DefaultDealer: { ...item },
                  });
                  return false;
                } else return true;
              });
            }
            getPlans()
              .then(() => {
                setLoader(false);
                if (updateComplianceRequest.ID != "")
                  setToastMessage(
                    translation.maintenanceProductUpdateSuccess,
                    true
                  );
                else
                  setToastMessage(
                    translation.maintenanceProductCreationSuccess,
                    true
                  );
              })
              .catch((ex) => {
                console.error(ex);
              });
          }
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          setIsAdNew(false);
          setToastMessage(translation.failed, false);
        });
    }
  };

  const handleContactChange = (e: any) => {
    if (
      selectedAccount.PrimaryContact.ID != "" &&
      selectedAccount.PrimaryContact.ID != (e.target.value as string)
    ) {
      setAddNewComplianceRequest({
        ...addNewComplianceRequest,
        Contact: {
          ...initialUserState,
          ID: e.target.value as string,
        },
        IsMaintenancePlanManagerUpdated: true,
      });
    } else {
      setAddNewComplianceRequest({
        ...addNewComplianceRequest,
        Contact: {
          ...initialUserState,
          ID: e.target.value as string,
        },
        IsMaintenancePlanManagerUpdated: false,
      });
    }
  };

  const handleDealerChange = (e: any) => {
    if (
      selectedAccount.DefaultDealer.ID != "" &&
      selectedAccount.DefaultDealer.ID != (e.target.value as string)
    ) {
      setAddNewComplianceRequest({
        ...addNewComplianceRequest,
        Dealer: {
          ...defaultAccount,
          ID: e.target.value as string,
        },
        IsDefaultDistributorUpdated: true,
      });
    } else {
      setAddNewComplianceRequest({
        ...addNewComplianceRequest,
        Dealer: {
          ...defaultAccount,
          ID: e.target.value as string,
        },
        IsDefaultDistributorUpdated: false,
      });
    }
  };

  const handleProductSelection = (val: string) => {
    products.every((item) => {
      if (item.ID == val) {
        setAddNewComplianceRequest({
          ...addNewComplianceRequest,
          Product: { ...item },
        });
        return false;
      } else return true;
    });
  };

  return (
    <Box>
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      <Dialog className="sx-model" open={isAddNew} onClose={handleClose}>
        <Box component="form" onSubmit={handleCreateMaintenancePlan}>
          <DialogTitle className="sx-model-title sx-border-bottom">
            {updateComplianceRequest.ID != ""
              ? translation.updateProductSubscription
              : translation.newProductSubscription}
          </DialogTitle>
          <Box position="absolute" top={8} right={8}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent className="my-4">
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Autocomplete
                value={addNewComplianceRequest.Product}
                onChange={(event, newValue) => {
                  handleProductSelection(newValue ? newValue.ID : "");
                }}
                id="select-product"
                options={products}
                getOptionLabel={(option) =>
                  option.QuoteProductName != ""
                    ? `${option.Number} - ${option.QuoteProductName}`
                    : option.Number
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translation.selectProduct}
                    variant="filled"
                    className="sx-input"
                    InputLabelProps={{ className: "sx-input-label" }}
                  />
                )}
              />
              <FormHelperText error className="error-text font-10 text-left">
                {errors.product}
              </FormHelperText>
            </FormControl>
            <TextField
              className="sx-inputGroup mb-2"
              fullWidth
              id="quantity"
              name="quantity"
              label={translation.quantity}
              type="number"
              onChange={(e) => {
                setAddNewComplianceRequest({
                  ...addNewComplianceRequest,
                  Quantity: Number(e.target.value as string),
                });
              }}
              value={addNewComplianceRequest.Quantity}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
                inputProps: { min: 1 },
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-frequency"
                className={`sx-input ${
                  errors && errors.frequency && "sx-validationFailed"
                }`}
                disableUnderline={true}
                value={addNewComplianceRequest.Frequency}
                onChange={(e) =>
                  setAddNewComplianceRequest({
                    ...addNewComplianceRequest,
                    Frequency: e.target.value as string,
                  })
                }
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.selectFrequency}
                </MenuItem>
                {addNewComplianceRequest.Product.AutoshipFrequency &&
                  addNewComplianceRequest.Product.AutoshipFrequency.length >
                    0 &&
                  addNewComplianceRequest.Product.AutoshipFrequency.map(
                    (item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    }
                  )}
              </Select>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.frequency}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-distributor"
                className={`sx-input ${
                  errors && errors.dealer && "sx-validationFailed"
                }`}
                disableUnderline={true}
                value={addNewComplianceRequest.Dealer.ID}
                onChange={handleDealerChange}
                disabled={updateComplianceRequest.ID != ""}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.selectDistributor}
                </MenuItem>
                {dealers &&
                  dealers.length > 0 &&
                  dealers.map((item) => {
                    return <MenuItem value={item.ID}>{item.Name}</MenuItem>;
                  })}
              </Select>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.dealer}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-contact"
                className="sx-input"
                disableUnderline={true}
                value={
                  addNewComplianceRequest.Contact.ID == null
                    ? ""
                    : addNewComplianceRequest.Contact.ID
                }
                onChange={handleContactChange}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.selectCompliancePlanContact}
                </MenuItem>
                {contacts &&
                  contacts.length > 0 &&
                  contacts.map((item) => {
                    return (
                      <MenuItem
                        value={item.ID}
                      >{`${item.FirstName} ${item.LastName}`}</MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <TextField
              className="sx-inputGroup mb-2"
              required
              fullWidth
              id="nextship"
              name="nextship"
              label={translation.nextship}
              type="month"
              onChange={(e) => {
                setAddNewComplianceRequest({
                  ...addNewComplianceRequest,
                  NextShipDate: e.target.value as string,
                });
              }}
              value={addNewComplianceRequest.NextShipDate}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
              }}
              inputProps={{
                min: nextShipMinDate,
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
          </DialogContent>
          <DialogActions className="px-4 py-3 sx-border-top">
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              className="sx-button"
            >
              {translation.submit}
            </Button>
            <Button
              variant="text"
              className="sx-button sx-textButton"
              onClick={handleClose}
            >
              {translation.cancel}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        type="submit"
        variant="contained"
        className="ml-2 sx-button p-0"
        onClick={handleAddNew}
      >
        <Add /> <span className="sx-hideOnMobile">{translation.add}</span>
      </Button>
    </Box>
  );
};

export default connector(AddMaintenancePlanProduct);
