import React from "react";
import { Redirect, Route } from "react-router";
import {
  Account,
  ApplicationState,
  LoggedInRole,
  User,
  WoocommerceRequest,
  defaultWoocommerceRequest,
} from "./types/Master";
import { GetAutoLoginUrl } from "./services/CustomerService";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { action_types } from "./types/ActionTypes";
import UseTranslation from "./services/UseTranslation";
import { Backdrop, CircularProgress } from "@material-ui/core";
const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isAuthUser: state.isAuthUser,
  user: state.user,
  showSelectedAccountData: state.ShowSelectedAccountData,
  selectedAccount: state.SelectedAccount,
  toast: state.toast,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
  selectedTab: state.SelectedTab,
  wooCommerceRediectionRequired: state.WooCommerceRedirectionRequired,
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setSelectedAccount: (val: Account) =>
      dispatch({ type: "SET_SELECTED_ACCOUNT", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setIsContactAccountsLoaded: (val: boolean) =>
      dispatch({ type: "SET_IS_CONTACT_ACCOUNTS_LOADED", payload: val }),
    setIsWooCommerceRedirectionRequired: (val: boolean) =>
      dispatch({
        type: "SET_IS_WOOCOMMERCE_REDIRECTION_REQUIRED",
        payload: val,
      }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface OwnProps {
  type: string;
}

export type AuthRouteProps = PropsFromRedux & OwnProps;

const AuthRoute = (props: any) => {
  const {
    user,
    showSelectedAccountData,
    isAuthUser,
    type,
    selectedAccount,
    isContactAccountsLoaded,
    isLoading,
    setLoader,
    setToastMessage,
    setIsWooCommerceRedirectionRequired,
  } = props as AuthRouteProps;

  const [redirectToWooCommerceRequired, setRedirectToWooCommerceRequired] =
    React.useState<boolean>(false);
  const [showDSPopup, setShowDSPopup] = React.useState<boolean>(false);

  React.useEffect(() => {
    let RedirectTo = localStorage.getItem("retunpath");
    if (
      selectedAccount.ID != "" &&
      RedirectTo != null &&
      !redirectToWooCommerceRequired
    ) {
      setRedirectToWooCommerceRequired(true);
      setIsWooCommerceRedirectionRequired(true);
    }
  }, [selectedAccount]);

  React.useEffect(() => {
    if (redirectToWooCommerceRequired) {
      handleWooCommerceLogin(user, selectedAccount);
    }
  }, [redirectToWooCommerceRequired]);

  const handleWooCommerceLogin = (Contact: User, Account: Account) => {
    let RedirectTo = localStorage.getItem("retunpath");
    if (RedirectTo != null) {
      getAutoLoginUrl({
        ...defaultWoocommerceRequest,
        Account,
        Contact,
        RedirectTo,
      });
    }
  };

  const getAutoLoginUrl = (
    wooCommerceRequest: WoocommerceRequest
  ): Promise<void> => {
    setLoader(true);

    return new Promise((resolve, reject) => {
      GetAutoLoginUrl(wooCommerceRequest)
        .then((res: any) => {
          if (res.status == "success") {
            window.open(res.data, "_self");
            setRedirectToWooCommerceRequired(false);
          } else {
            setToastMessage(translation.woocommerceredirectionissue, false);
          }
          localStorage.removeItem("retunpath");
          setLoader(false);
          resolve();
        })
        .catch((ex) => {
          console.error(ex);
          localStorage.removeItem("retunpath");
          setLoader(false);
          setToastMessage(translation.woocommerceredirectionissue, false);
          reject();
        });
    });
  };

  if (type === "guest" && isAuthUser) {
    if (!redirectToWooCommerceRequired) {
      if (user.IsDsoLogin && !showSelectedAccountData) {
        return <Redirect to="/dashboard" />;
      } else if (showDSPopup) {
        return <Redirect to="/testid" />;
      } else {
        let originalurl = localStorage.getItem("originalurl");
        if (originalurl != null) {
          localStorage.removeItem("originalurl");
          return <Redirect to={originalurl} />;
        }

        return <Redirect to="/home" />;
      }
    } else {
      return (
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
      );
    }
  } else if (type === "private" && !isAuthUser) return <Redirect to="/" />;
  return <Route {...props} />;
};

export default connector(AuthRoute);
