import {
  Backdrop,
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import UseTranslation from "../../services/UseTranslation";
import { action_types } from "../../types/ActionTypes";
import {
  Account,
  AccountLookUpRequest,
  ApplicationState,
  defaultAccount,
  defaultAccountLookUpRequest,
  defaultSignUpRequest,
  SignUpRequest,
} from "../../types/Master";
import "../../custom.css";
import { Container } from "reactstrap";
import {
  CustomerSignUp,
  LookUpAccountNumber,
} from "../../services/CustomerService";
import validator from "validator";
import { Redirect } from "react-router-dom";
import SolmetexLogoSvg from "../../images/Solmetex_logo.svg";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  isAuthUser: state.isAuthUser,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const SignUp = (props: PropsFromRedux) => {
  const { isLoading, setLoader, setToastMessage } = props;
  const [accountLookUpRequest, setAccountLookupRequest] =
    React.useState<AccountLookUpRequest>(defaultAccountLookUpRequest);
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  const [account, setAccount] = React.useState<Account>(defaultAccount);
  const [lookupAccountFound, setLookupAccountFound] =
    React.useState<boolean>(false);
  const [lookupAccountNotFound, setLookupAccountNotFound] =
    React.useState<boolean>(false);
  const [proceedToStep2, setProceedToStep2] = React.useState<boolean>(false);
  const [signUpRequest, setSignUpRequest] =
    React.useState<SignUpRequest>(defaultSignUpRequest);
  const [validationMessage, setValidationMessage] = React.useState<{
    email: string;
    password: string;
  }>({ email: "", password: "" });
  const [isRegistrationSucceeded, setIsRegistrationSucceeded] =
    React.useState<boolean>(false);

  const handleLookUpAccount = (e: any) => {
    e.preventDefault();
    setLookupAccountNotFound(false);
    setLoader(true);
    LookUpAccountNumber(accountLookUpRequest)
      .then((accounts: Account[]) => {
        setLoader(false);
        setLookupAccountFound(true);
        setAccounts(accounts);
        setAccount(accounts[0]);
      })
      .catch((ex) => {
        console.error(ex);
        setLoader(false);
        setLookupAccountNotFound(true);
      });
  };

  const validate = (): boolean => {
    let isValid = true;
    if (!validator.isEmail(signUpRequest.email)) {
      isValid = false;
      setValidationMessage({
        ...validationMessage,
        email: `* ${translation.emailValidation}`,
      });
    }
    if (signUpRequest.password != signUpRequest.confirmPassword) {
      isValid = false;
      setValidationMessage({
        ...validationMessage,
        password: translation.passwordMismatch,
      });
    }
    if (
      !validator.isStrongPassword(signUpRequest.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      isValid = false;
      setValidationMessage({
        ...validationMessage,
        password: translation.passwordValidation,
      });
    }
    return isValid;
  };

  const handleSignUp = (e: any) => {
    e.preventDefault();
    setLookupAccountNotFound(false);
    if (validate()) {
      signUpRequest.accountId = account.ID;
      setLoader(true);
      setValidationMessage({ email: "", password: "" });
      CustomerSignUp(signUpRequest)
        .then((res) => {
          setLoader(false);
          setIsRegistrationSucceeded(true);
          setToastMessage(translation.registrationSuccess, true);
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          setToastMessage(translation.registrationFailed, false);
        });
    }
  };

  const handleAccountSelection = (e: any) => {
    e.preventDefault();
    setLookupAccountFound(false);
    setProceedToStep2(true);
  };

  const handleAccountChange = (e: any) => {
    accounts.every((item: Account) => {
      if (item.ID == (e.target.value as string)) {
        setAccount(item);
        return false;
      } else return true;
    });
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    setLookupAccountFound(false);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setProceedToStep2(false);
  };

  if (isRegistrationSucceeded) {
    return <Redirect to="/signin" />;
  }

  return (
    <Box className="sx-signUpBg">
      <Container>
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <div className="loading-icon" />
        </Backdrop>
        <Box className="sx-login">
          <Grid container>
            <Grid item xs={12} md={6} className="m-auto text-center">
              <Dialog
                className="sx-model"
                open={lookupAccountFound}
                onClose={handleClose}
              >
                <DialogTitle className="sx-model-title">{translation.lookupAccount}</DialogTitle>
                <DialogContent className="my-4">
                  <DialogContentText>
                    {translation.lookupAccountInstructions}
                  </DialogContentText>
                  <FormControl
                    fullWidth
                    variant="filled"
                    className="sx-inputGroup"
                  >
                    <InputLabel htmlFor="select-account">
                      {translation.selectAccount}
                    </InputLabel>
                    <Select
                      labelId="select-account-label"
                      id="select-account"
                      label={translation.selectAccount}
                      value={account.ID}
                      onChange={handleAccountChange}
                      className="sx-input"
                      disableUnderline={true}
                    >
                      {accounts &&
                        accounts.length > 0 &&
                        accounts.map((item) => {
                          return (
                            <MenuItem value={item.ID}>{item.Name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <Typography className="mt-3 px-3">{`${translation.accountNumber} - ${account.Number}`}</Typography>
                </DialogContent>
                <DialogActions className="px-4 py-3 sx-border-top">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    className="sx-button"
                    onClick={handleAccountSelection}
                  >
                    {translation.submit}
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="text"
                    className="sx-button sx-button--white"
                    onClick={handleClose}
                  >
                    {translation.cancel}
                  </Button>
                </DialogActions>
              </Dialog>
              {!proceedToStep2 && (
                <Box
                  component="form"
                  onSubmit={handleLookUpAccount}
                  className="sx-loginBg"
                >
                  <div className="text-center mb-5">
                    <img
                      src={SolmetexLogoSvg}
                      alt="Solmetex"
                      className="sx-loginLogo"
                    />
                  </div>
                  <Typography variant="h1" className="sx-loginTitle">
                    {`${translation.createLogin} 1`}
                  </Typography>
                  <Typography className="mb-4 sx-grey">
                    {translation.accountLookUpInstructions}
                  </Typography>
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="account"
                    name="account"
                    label={translation.accountOrPhone}
                    onChange={(e) => {
                      setAccountLookupRequest({
                        ...accountLookUpRequest,
                        inputValue: e.target.value as string,
                      });
                    }}
                    value={accountLookUpRequest.inputValue}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="zipcode"
                    name="zipcode"
                    label={translation.zipCode}
                    onChange={(e) => {
                      setAccountLookupRequest({
                        ...accountLookUpRequest,
                        zipCode: e.target.value as string,
                      });
                    }}
                    value={accountLookUpRequest.zipCode}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    className="sx-button mt-4"
                  >
                    {translation.submit}
                  </Button>
                  {lookupAccountNotFound && (
                    <Typography className="error-text font-12 pt-2">
                      {translation.lookupAccountNotFound}
                    </Typography>
                  )}
                  <Typography
                    variant="h5"
                    className="sx-loginSubTitle mt-4 pt-3"
                  >
                    {translation.alreadyHaveAnAccount}
                    <Link href="/signin" className="sx-blue sx-bold ml-2">
                      {translation.signin}
                    </Link>
                  </Typography>
                </Box>
              )}
              {account.ID != "" && proceedToStep2 && (
                <Box
                  component="form"
                  onSubmit={handleSignUp}
                  className="sx-loginBg"
                >
                  <div className="text-center mb-5">
                    <Link href="/">
                      <img
                        src={SolmetexLogoSvg}
                        alt="Solmetex"
                        className="sx-loginLogo"
                      />
                    </Link>
                  </div>
                  <Typography variant="h1" className="sx-loginTitle mb-1">
                    {`${translation.createLogin} 2`}
                  </Typography>
                  <Typography className="mb-4 sx-grey">
                    {translation.createLoginInstructions}
                  </Typography>
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="account"
                    name="account"
                    label={translation.account}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                    value={account.Name}
                    disabled
                  />
                  <Link
                    onClick={handleChange}
                    className="link text-right sx-blue sx-bold"
                  >
                    {translation.changeAccount}
                  </Link>
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    name="firstname"
                    label={translation.firstName}
                    onChange={(e) => {
                      setSignUpRequest({
                        ...signUpRequest,
                        firstName: e.target.value as string,
                      });
                    }}
                    value={signUpRequest.firstName}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    name="lastname"
                    label={translation.lastName}
                    onChange={(e) => {
                      setSignUpRequest({
                        ...signUpRequest,
                        lastName: e.target.value as string,
                      });
                    }}
                    value={signUpRequest.lastName}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    label={translation.emailAddress}
                    onChange={(e) => {
                      setSignUpRequest({
                        ...signUpRequest,
                        email: e.target.value as string,
                      });
                    }}
                    value={signUpRequest.email}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    name="password"
                    type="password"
                    label={translation.password}
                    onChange={(e) => {
                      setSignUpRequest({
                        ...signUpRequest,
                        password: e.target.value as string,
                      });
                    }}
                    value={signUpRequest.password}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                  />
                  <TextField
                    className="sx-inputGroup"
                    margin="normal"
                    required
                    fullWidth
                    id="confirmpassword"
                    name="confirmpassword"
                    type="password"
                    label={translation.confirmPassword}
                    onChange={(e) => {
                      setSignUpRequest({
                        ...signUpRequest,
                        confirmPassword: e.target.value as string,
                      });
                    }}
                    value={signUpRequest.confirmPassword}
                    InputProps={{
                      disableUnderline: true,
                      className: "sx-input",
                    }}
                    InputLabelProps={{ className: "sx-input-label" }}
                    variant="filled"
                    helperText={validationMessage.password}
                    FormHelperTextProps={{
                      classes: {
                        root: "error-text pt-2",
                      },
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    className="sx-button mt-4"
                  >
                    {translation.submit}
                  </Button>
                  <Typography
                    variant="h5"
                    className="sx-loginSubTitle mt-4 pt-3"
                  >
                    {translation.alreadyHaveAnAccount}
                    <Link href="/signin" className="sx-blue sx-bold ml-2">
                      {translation.signin}
                    </Link>
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default connector(SignUp);
