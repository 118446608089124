import {
  Case,
  MaintenancePlan,
  Product,
  RecycledCerticate,
  RegisteredProduct,
  RegistrableProduct,
  WaterTesting,
} from "../types/Master";
import { GetToken, handleUnauthorized, unauthorized } from "./CustomerService";

export const GetProducts = (): Promise<Product[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetProducts", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetRecyclableProducts = (): Promise<Product[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetRecyclableProducts", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetCases = (accountId: string): Promise<Case[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetCases?accountId=" + accountId, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetMaintenancePlanApprovedProduct = (): Promise<Product[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetMaintenancePlanApprovedProduct", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetMaintenancePlans = (
  accountid: string
): Promise<MaintenancePlan[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetMaintenancePlans?accountid=" + accountid, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const UpdateMaintenancePlan = (request: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/UpdateMaintenancePlan", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.status == "success") resolve("");
        else if (response && response.status == "failed")
          resolve(response.message);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const UpdateAccountPrimaryContact = (
  accountId: string,
  contactId: string
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Product/UpdateAccountPrimaryContact/?accountId=" +
        accountId +
        "&contactId=" +
        contactId,
      {
        method: "POST",
        headers: {
          Authorization: GetToken(),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.status == "success") resolve(true);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const UpdateAccountDefaultDealer = (
  accountId: string,
  dealerId: string
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    fetch(
      "api/Product/UpdateAccountDefaultDealer/?accountId=" +
        accountId +
        "&dealerId=" +
        dealerId,
      {
        method: "POST",
        headers: {
          Authorization: GetToken(),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.status == "success") resolve(true);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetProductsForRegistration = (): Promise<RegistrableProduct[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetProductsForRegistration", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetRegisteredProducts = (
  accountid: string
): Promise<RegisteredProduct[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetRegisteredProducts?accountid=" + accountid, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CreateRegisteredProduct = (data: FormData): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/CreateRegisteredProducts", {
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: GetToken(),
      },
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.status == "success") resolve(true);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetRecycledCertificates = (
  accountid: string
): Promise<RecycledCerticate[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetRecycledCerticates?accountid=" + accountid, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const GetWaterTesting = (
  accountNumber: string
): Promise<WaterTesting[]> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/GetWaterTesting?accountNumber=" + accountNumber, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response.data != null) {
          resolve(JSON.parse(response.data));
        } else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};

export const CreateWaterTesting = (data: FormData): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    fetch("api/Product/CreateWaterTesting", {
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: GetToken(),
      },
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else if (response && response.status == "success") resolve(true);
        else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};



export const CreateWaterTestingDS = (data: WaterTesting): Promise<boolean> => {  
  return new Promise((resolve, reject) => {
    fetch("api/Product/CreateWaterTestingDS", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: GetToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message == unauthorized)
          handleUnauthorized(response.message);
        else resolve(response);
        //else reject();
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
};
