import {
  Backdrop,
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Link,
  CircularProgress
} from "@material-ui/core";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { CustomerSignIn } from "../../services/CustomerService";
import UseTranslation from "../../services/UseTranslation";
import { action_types } from "../../types/ActionTypes";
import {
  ApplicationState,
  defaultSignInRequest,
  SignInRequest,
  User,
} from "../../types/Master";
import "../../custom.css";
import { Container } from "reactstrap";
import SolmetexLogoSvg from "../../images/Solmetex_logo.svg";


const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  isAuthUser: state.isAuthUser,
  isWooCommerceRedirectionRequired: state.WooCommerceRedirectionRequired,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    loginSuccess: (val: User) =>
      dispatch({ type: "LOGIN_SUCCESS", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
const SignIn = (props: PropsFromRedux) => {
  const { isLoading,setLoader, loginSuccess, setToastMessage } = props;    
  const [signInrequest, setSignInRequest] =
    React.useState<SignInRequest>(defaultSignInRequest);
  const [signInValidation, setSignInValidation] = React.useState<{
    email: string;
    password: string;
  }>();

  const handleSubmit = (e: any) => {
      e.preventDefault();      
      let validationObject = { email: "", password: "" };      
    if (signInrequest.id == "") {
      validationObject = {
        ...validationObject,
        email: translation.requiredField,
      };
    }
    if (signInrequest.password == "") {
      validationObject = {
        ...validationObject,
        password: translation.requiredField,
      };
    }
    setSignInValidation({ ...validationObject });
    if (signInrequest.id != "" && signInrequest.password != "") {
      setLoader(true);
        CustomerSignIn(signInrequest.id, signInrequest.password)
        .then((res) => {
          setLoader(false);
          if (res) {
            loginSuccess(res);
          }
        })
        .catch((ex) => {
          setLoader(false);
          setToastMessage(translation.loginFailed, false);
        });
    }
  };

  return (
    <Box className="sx-signUpBg">
      <Container>
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <CircularProgress/>
        </Backdrop>
        <Box component="form" onSubmit={handleSubmit} className="sx-login">
          <Grid container>
            <Grid item xs={12} md={6} className="m-auto">
              <Box className="sx-loginBg text-center">
                <div className="text-center mb-4">
                  <img
                    src={SolmetexLogoSvg}
                    alt="Solmetex"
                    className="sx-loginLogo"
                  />
                </div>
                <Typography variant="h1" className="sx-loginTitle mb-1">
                  {translation.signin}
                </Typography>
                <Typography className="mb-4 sx-grey">
                  {translation.enterYourCredentials}
                </Typography>
                <TextField
                  className={`sx-inputGroup ${
                    signInValidation &&
                    signInValidation.email &&
                    "sx-validationFailed"
                  }`}
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  type="email"
                  label={translation.emailAddress}
                  onChange={(e) => {
                    setSignInRequest({
                      ...signInrequest,
                      id: e.target.value as string,
                    });
                  }}
                  value={signInrequest.id}
                  InputProps={{ disableUnderline: true, className: "sx-input" }}
                  InputLabelProps={{ className: "sx-input-label" }}
                  variant="filled"
                />
                {signInValidation && (
                  <div className="error-text font-10 pl-2 text-left">
                    {signInValidation.email}
                  </div>
                )}
                <TextField
                  className={`sx-inputGroup ${
                    signInValidation &&
                    signInValidation.password &&
                    "sx-validationFailed"
                  }`}
                  margin="normal"
                  fullWidth
                  id="password"
                  name="password"
                  type="password"
                  label={translation.password}
                  onChange={(e) => {
                    setSignInRequest({
                      ...signInrequest,
                      password: e.target.value as string,
                    });
                  }}
                  value={signInrequest.password}
                  InputProps={{ disableUnderline: true, className: "sx-input" }}
                  InputLabelProps={{ className: "sx-input-label" }}
                  variant="filled"
                />
                {signInValidation && (
                  <div className="error-text font-10 pl-2 text-left">
                    {signInValidation.password}
                  </div>
                )}
                <Typography className="mt-2 text-right">
                  <Link href="/forgotpassword" className="sx-blue">
                    {translation.forgotPassword}
                  </Link>
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                  className="sx-button mt-3"
                >
                  {translation.signin}
                </Button>
                <Typography variant="h5" className="sx-loginSubTitle mt-4 pt-3">
                  {translation.createPersonalLogin}
                  <Link href="/signup" className="sx-blue sx-bold ml-2">
                    {translation.clickHere}
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default connector(SignIn);
