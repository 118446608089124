import {
  Backdrop,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  Typography,
  IconButton,
  Grid,
  Button,
  Tooltip,
} from "@material-ui/core";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import SearchBar from "material-ui-search-bar";
import { Link } from "react-router-dom";
import UseTranslation from "../../services/UseTranslation";
import { ApplicationState, RecycledCerticate } from "../../types/Master";
import {
  EnhancedTableHead,
  getComparator,
  HeadCell,
  OrderType,
  stableSort,
} from "../shared/Table";
import {
  generateExcel,
  generatePDF,
  getFormatedDateForDisplay,
} from "../../services/Shared";
import { Redirect } from "react-router-dom";
import { GetRecycledCertificates } from "../../services/ProductService";
import SideNav from "../shared/SideNav";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
  showSelectedAccountData: state.ShowSelectedAccountData,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    setSelectedRecycledCertificate: (val: RecycledCerticate) =>
      dispatch({ type: "SET_SELECTED_RECYCLEDCERTIFICATE", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const headCells: HeadCell[] = [
  {
    id: "CertificateName",
    numeric: false,
    disableSorting: false,
    label: translation.certificate,
  },
  {
    id: "DeliveryDate",
    numeric: false,
    disableSorting: false,
    label: translation.deliverydate,
  },
  {
    id: "TrackingNumber",
    numeric: false,
    disableSorting: false,
    label: translation.trackingnumber,
  },
  {
    id: "Quantity",
    numeric: false,
    disableSorting: false,
    label: translation.quantity,
  },
  {
    id: "Weight",
    numeric: false,
    disableSorting: false,
    label: translation.weight,
  },
  {
    id: "Product ID",
    numeric: false,
    disableSorting: false,
    label: translation.productnumber,
  },
  {
    id: "ProductDescription",
    numeric: false,
    disableSorting: false,
    label: translation.productdescription,
  },
  {
    id: "Material",
    numeric: false,
    disableSorting: false,
    label: translation.material,
  },
  {
    id: "RecyclingCertificate",
    numeric: false,
    disableSorting: true,
    label: translation.recycledcertificate,
  },
];

const defaultcolumns = [
  "Number",
  "Delivery Date",
  "Tracking Number",
  "Quantity",
  "Weight",
  "Product ID",
  "Product Description",
  "Material",
];

const RecycledCertificates = (props: PropsFromRedux) => {
  const {
    user,
    isLoading,
    isContactAccountsLoaded,
    showSelectedAccountData,
    selectedAccount,
    setLoader,
    setSelectedRecycledCertificate,
  } = props;
  const [order, setOrder] = React.useState<OrderType>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("CreatedOn");
  const [recycledCerticates, setRecycledCerticates] = React.useState<
    RecycledCerticate[]
  >([]);
  const [totalRecycledCerticates, setTotalRecycledCerticates] = React.useState<
    RecycledCerticate[]
  >([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searched, setSearched] = React.useState<string>("");

  const getRecycledCerticates = () => {
    setLoader(true);
    GetRecycledCertificates(selectedAccount.ID)
      .then((res) => {
        setRecycledCerticates(res);
        setTotalRecycledCerticates(res);
        setLoader(false);
      })
      .catch((ex) => {
        console.error(ex);
        setLoader(false);
      });
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSearch = (searchedVal: string) => {
    let listOfWords = searchedVal.split(" ");
    let filteredRows: RecycledCerticate[] = [];
    totalRecycledCerticates.forEach((item) => {
      let isAllWordsPresent = false;
      listOfWords.every((word: string) => {
        if (
          item.Product.Number.toLowerCase().includes(word.toLowerCase()) ||
          item.CertificateName.toLowerCase().includes(word.toLowerCase()) ||
          item.Product.Description.toLowerCase().includes(word.toLowerCase()) ||
          item.TrackingNumber.toLowerCase().includes(word.toLowerCase()) ||
          item.Weight.toString().includes(word.toLowerCase()) ||
          item.Material.toLowerCase().includes(word.toLowerCase()) ||
          item.Quantity.toString() == word ||
          getFormatedDateForDisplay(item.DeliveryDate)
            .toLowerCase()
            .includes(word.toLowerCase())
        ) {
          isAllWordsPresent = true;
          return true;
        } else {
          isAllWordsPresent = false;
          return false;
        }
      });
      if (isAllWordsPresent) filteredRows.push({ ...item });
    });
    setRecycledCerticates(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const downloadExcelOrPdf = (isPdf: boolean) => {
    const tableRows: any[] = [];
    recycledCerticates.forEach((certificate) => {
      const data = [
        certificate.CertificateName,
        getFormatedDateForDisplay(certificate.DeliveryDate),
        certificate.TrackingNumber,
        certificate.Quantity,
        certificate.Weight,
        certificate.Product.Number,
        certificate.Product.Description,
        certificate.Material,
      ];
      tableRows.push(data);
    });
    if (isPdf)
      generatePDF(tableRows, defaultcolumns, translation.recycledcertificates);
    else
      generateExcel(
        tableRows,
        defaultcolumns,
        translation.recycledcertificates
      );
  };

  const handleViewPDF = (certificate: any) => {
    if (certificate != null) {
      setSelectedRecycledCertificate({
        ...certificate,
      });
      window.open("/viewcertificate");
    }
  };
  React.useEffect(() => {
    if (isContactAccountsLoaded && selectedAccount.Number != "") {
      getRecycledCerticates();
    }
  }, [selectedAccount, isContactAccountsLoaded]);

  if (isContactAccountsLoaded && user.IsDsoLogin && !showSelectedAccountData) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <React.Fragment>
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      {!isContactAccountsLoaded ? (
        <React.Fragment />
      ) : (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={3} md={2} className="sx-colorBg-grey-08 px-4 pt-4">
            <SideNav />
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <Box className="p-3">
              <h3>{translation.recycledcertificates}</h3>
              <p className="small">{translation.recycledCertificatesDetails}</p>
            </Box>
            <Box className="sx-table-shadow mb-5 p-3">
              <Box className="d-flex justify-content-between align-items-center pb-3 sx-tableSearchContainer">
                <SearchBar
                  value={searched}
                  onChange={(searchVal) => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                  className="col-sm-5 px-0 sx-tableSearch shadow-none"
                />
                <Box className="d-flex justify-content-between align-items-center pt-1">
                  <IconButton
                    className="ml-1 p-0"
                    onClick={() => downloadExcelOrPdf(true)}
                  >
                    <img
                      src={require("../../images/pdf.png")}
                      alt="Solmetex"
                      className="img-fluid sx-iconButtonImg"
                    />
                  </IconButton>
                  <IconButton
                    className="p-0"
                    onClick={() => downloadExcelOrPdf(false)}
                  >
                    <img
                      src={require("../../images/xls.png")}
                      alt="Solmetex"
                      className="img-fluid sx-iconButtonImg"
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box className="pb-3">
                <TableContainer>
                  <Table>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      headCells={headCells}
                      rowCount={recycledCerticates.length}
                      displayActionCell={false}
                      onRequestSort={handleRequestSort}
                    />
                    {recycledCerticates.length == 0 ? (
                      <TableBody className="sx-tableBody">
                        <TableRow>
                          <TableCell
                            className="mb-5 p-3 text-center"
                            colSpan={headCells.length}
                          >
                            <img
                              src={require("../../images/empty-state.png")}
                              alt="Solmetex"
                              className="img-fluid"
                            />
                            <h3 className="mt-3 sx-blue">No Data Found</h3>
                            <p>please try again later</p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody className="sx-tableBody">
                        {stableSort(
                          recycledCerticates,
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((certificate) => {
                            return (
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  width={180}
                                >
                                  {certificate.CertificateName}
                                </TableCell>
                                <TableCell>
                                  {getFormatedDateForDisplay(
                                    certificate.DeliveryDate
                                  )}
                                </TableCell>
                                <TableCell>
                                  {certificate.TrackingNumber}
                                </TableCell>
                                <TableCell>{certificate.Quantity}</TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  width={150}
                                >
                                  {certificate.Weight}
                                </TableCell>
                                <TableCell>
                                  {certificate.Product.Number}
                                </TableCell>
                                <TableCell>
                                  {certificate.Product.Description}
                                </TableCell>
                                <TableCell>{certificate.Material}</TableCell>
                                <TableCell>
                                  <Typography>
                                    <Link
                                      to="#"
                                      className="sx-blue"
                                      onClick={() => handleViewPDF(certificate)}
                                    >
                                      <img
                                        src={require("../../images/pdf-download.png")}
                                        alt="Solmetex"
                                        className="img-fluid"
                                      />
                                    </Link>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={recycledCerticates.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="pagination-content"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default connector(RecycledCertificates);
