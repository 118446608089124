import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { ApplicationState } from "../../types/Master";
import {
  PDFViewer,
  View,
  Image as ImageView,
  StyleSheet,
  Document,
  Page,
  Text,
  Font,
} from "@react-pdf/renderer";
import UseTranslation from "../../services/UseTranslation";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: require("../../fonts/Poppins-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../fonts/Poppins-Bold.ttf"),
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    fontFamily: "Poppins",
  },
  baseText: {
    fontSize: 10,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
});

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  shippingLabel: state.ShippingLabel,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ShippingLabelView = (props: PropsFromRedux) => {
  const { shippingLabel } = props;
  let currentDate = new Date();

  return shippingLabel == "" ? (
    <React.Fragment></React.Fragment>
  ) : (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={{ fontSize: 8, margin: 8 }}>
            {currentDate.toLocaleString()}
          </Text>
          <View
            style={{
              marginHorizontal: 50,
              marginVertical: 25,
            }}
          >
            <Text>{translation.printLabel}</Text>
            <View
              style={{
                ...styles.baseText,
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 8,
                }}
              >
                <Text>1. </Text>
                <Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {translation.printLabelBoldText1}
                  </Text>
                  {translation.printLabelText1}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 8,
                }}
              >
                <Text>2. </Text>
                <Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {translation.printLabelBoldText2}
                  </Text>
                  {translation.printLabelText2}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 8,
                }}
              >
                <Text>3. </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    {translation.printLabelBoldText3}
                  </Text>
                  <View style={{ marginTop: 4 }}>
                    <Text style={{ fontWeight: "bold" }}>
                      {translation.printLabelBoldText4}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 2,
                    }}
                  >
                    <Text>• </Text>
                    <Text>{translation.printLabelText3}</Text>
                  </View>
                  <View style={{ marginTop: 4 }}>
                    <Text style={{ fontWeight: "bold" }}>
                      {translation.printLabelBoldText5}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 4,
                    }}
                  >
                    <Text>• </Text>
                    <Text>{translation.printLabelText4}</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 4,
                    }}
                  >
                    <Text>• </Text>
                    <Text>{translation.printLabelText5}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                ...styles.baseText,
                marginTop: 50,
                borderBottomColor: "black",
                borderBottomWidth: "1",
                paddingBottom: 8,
              }}
            >
              <Text>{translation.printLabelFoldHere}</Text>
            </View>
            <ImageView style={{ marginTop: 16 }} src={shippingLabel} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default connector(ShippingLabelView);
