import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import * as React from "react";
import { Backup, Close, Add } from "@material-ui/icons";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { GetTestingLabs } from "../../services/CustomerService";
import {
  WaterTestTypeValues,
  WaterTestingRecordType,
  YesOrNoOptions,
  getfileType,
} from "../../services/Shared";
import UseTranslation from "../../services/UseTranslation";
import {
  Account,
  ApplicationState,
  User,
  defaultAccount,
  WaterTesting,
  defaultWaterTesting,
} from "../../types/Master";
import { WaterTestTypes } from "../../services/Shared";
import { CreateWaterTesting } from "../../services/ProductService";

const translation = UseTranslation();

interface errorValidation {
  testType: string;
  recordType: string;
}

const defaultErrorValidation = {
  testType: "",
  recordType: "",
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedAccountContact: state.SelectedAccountContact,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loginSuccess: (val: User) =>
      dispatch({ type: "LOGIN_SUCCESS", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface OwnProps {
  updateWaterTesting: WaterTesting;
  getwaterTestingData: () => Promise<void>;
}

export type ddWaterTestingProps = PropsFromRedux & OwnProps;

const AddWaterTesting = (props: ddWaterTestingProps) => {
  const {
    isLoading,
    setLoader,
    setToastMessage,
    updateWaterTesting,
    getwaterTestingData,
    selectedAccount,
    selectedAccountContact,
    user,
  } = props;
  const [isAddNew, setIsAdNew] = React.useState<boolean>(false);
  const [waterTestingDataRequest, setWaterTestingDataRequest] =
    React.useState<WaterTesting>(defaultWaterTesting);
  const [files, setFiles] = React.useState<FileList | null>();
  const [displayTestingLab, setDisplayTestingLab] = React.useState(false);
  const [fileSizeError, setFileSizeError] = React.useState("");
  const [isOneOrMoreSamplesFail, setIsOneOrMoreSamplesFail] =
    React.useState(false);
  const [isTestResultsUploaded, setIsTestResultsUploaded] =
    React.useState(false);
  const [errors, setErrors] = React.useState<errorValidation>({
    ...defaultErrorValidation,
  });

  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files;
    if (files && files.length > 0) {
      let file = files[0];
      if (file.size < 10485760) {
        setFiles(files);
        setFileSizeError("");
      } else setFileSizeError(translation.fileSizeError);
      setIsTestResultsUploaded(true);
    } else {
      setIsTestResultsUploaded(false);
    }
  };

  const handleSampleFailedStatus = (e: any) => {
    if (e.target.value == "1") {
      setWaterTestingDataRequest({
        ...waterTestingDataRequest,
        SampleFailed: true,
      });
      setIsOneOrMoreSamplesFail(true);
    } else {
      setWaterTestingDataRequest({
        ...waterTestingDataRequest,
        SampleFailed: false,
      });
      setIsOneOrMoreSamplesFail(false);
    }
  };

  React.useEffect(() => {
    if (updateWaterTesting.ID != "") {
      setIsAdNew(true);
      setWaterTestingDataRequest({
        ...updateWaterTesting,
        DateofSample: updateWaterTesting.DateofSample.substring(0, 10),
        SampleFailed: updateWaterTesting.SampleFailed,
      });
      if (updateWaterTesting.TestType == WaterTestTypeValues.MAIL_IN) {
        setDisplayTestingLab(true);
      } else {
        setDisplayTestingLab(false);
      }
    }
  }, [updateWaterTesting]);

  const handleAddNew = (e: any) => {
    e.preventDefault();
    let now: moment.Moment;
    now = moment();
    setWaterTestingDataRequest({
      ...defaultWaterTesting,
      Customer: { ...defaultAccount, ID: selectedAccount.ID },
      DateofSample: now.format("YYYY-MM-DD"),
    });
    setFiles(null);
    setFileSizeError("");
    setIsAdNew(true);
  };

  const handleClose = () => {
    setIsAdNew(false);
  };

  const errorValidation = (request: WaterTesting): boolean => {
    let errorsObject = { ...defaultErrorValidation };
    let isValid = true;
    if (request.TestType == "") {
      errorsObject = { ...errorsObject, testType: translation.requiredField };
      isValid = false;
    }
    if (request.RecordType == "") {
      errorsObject = { ...errorsObject, recordType: translation.requiredField };
      isValid = false;
    }
    setErrors({ ...errorsObject });
    return isValid;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    var ContactID = user.ID;
    if (selectedAccountContact != null && selectedAccountContact.ID != "") {
      ContactID = selectedAccountContact.ID;
    }
    if (errorValidation(waterTestingDataRequest)) {
      const data = new FormData(event.currentTarget);
      setLoader(true);
      data.append("ID", waterTestingDataRequest.ID);
      data.append("CustomerId", selectedAccount.ID);
      data.append("CustomerNumber", selectedAccount.Number);
      data.append("SubmittedBy", ContactID);
      data.append(
        "SampleFailed",
        waterTestingDataRequest.SampleFailed ? "1" : "0"
      );
      data.append(
        "RequestContact",
        waterTestingDataRequest.RequestContact ? "1" : "0"
      );
      data.append(
        "CertificateRequest",
        waterTestingDataRequest.CertificateRequest ? "1" : "0"
      );
      if (files && files.length > 0) {
        data.append("ImageUpdated", "true");
        let file = files[0];
        let fileName = file.name;
        let extension = fileName.split(".").pop();
        let fileType = extension ? getfileType(extension) : "";
        data.append("fileName", fileName);
        data.append("file", file);
        data.append("fileType", fileType);
      }

      CreateWaterTesting(data)
        .then((res) => {
          setIsAdNew(false);
          getwaterTestingData()
            .then(() => {
              setLoader(false);
              if (waterTestingDataRequest.ID != "")
                setToastMessage(translation.waterTestingUpdateSuccess, true);
              else setToastMessage(translation.waterTestingSuccess, true);
              setWaterTestingDataRequest({ ...defaultWaterTesting });
              setFiles(null);
            })
            .catch((ex) => {
              console.error(ex);
            });
        })
        .catch((ex) => {
          setWaterTestingDataRequest({ ...defaultWaterTesting });
          setFiles(null);
          setLoader(false);
          setIsAdNew(false);
          setToastMessage(translation.watertTestingFailure, false);
          console.error(ex);
        });
    }
  };

  const handleTestTypeChange = (e: any) => {
    setWaterTestingDataRequest({
      ...waterTestingDataRequest,
      TestType: e.target.value,
    });
    if (e.target.value == "186680001") {
      setDisplayTestingLab(true);
    } else {
      setDisplayTestingLab(false);
    }
  };

  return (
    <Box>
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      <Dialog className="sx-model" open={isAddNew} onClose={handleClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle className="sx-model-title  sx-border-bottom">
            {waterTestingDataRequest.ID != ""
              ? translation.updateWaterTesting
              : translation.newWaterTesting}
          </DialogTitle>
          <Box position="absolute" top={8} right={8}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent className="mb-4 px-3">
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-type"
                name="TestType"
                className={`sx-input ${
                  errors && errors.testType && "sx-validationFailed"
                }`}
                disableUnderline={true}
                value={waterTestingDataRequest.TestType}
                onChange={handleTestTypeChange}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.selectTypeOfTest}
                </MenuItem>
                {WaterTestTypes.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.testType}
              </FormHelperText>
            </FormControl>
            {displayTestingLab && (
              <TextField
                className="sx-inputGroup my-2"
                fullWidth
                id="testLabName"
                name="TestLabName"
                label={translation.testLabName}
                defaultValue={moment().format("yyyy-mm-dd")}
                onChange={(e) => {
                  setWaterTestingDataRequest({
                    ...waterTestingDataRequest,
                    TestLabName: e.target.value as string,
                  });
                }}
                value={waterTestingDataRequest.TestLabName}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
            )}
            <TextField
              className="sx-inputGroup my-2"
              fullWidth
              id="DateofSample"
              name="DateofSample"
              label={translation.dateofsample}
              type="date"
              defaultValue={moment().format("yyyy-mm-dd")}
              onChange={(e) => {
                setWaterTestingDataRequest({
                  ...waterTestingDataRequest,
                  DateofSample: e.target.value as string,
                });
              }}
              value={waterTestingDataRequest.DateofSample}
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-type"
                className={`sx-input ${
                  errors && errors.testType && "sx-validationFailed"
                }`}
                name="RecordType"
                disableUnderline={true}
                value={waterTestingDataRequest.RecordType}
                onChange={(e: any) => {
                  setWaterTestingDataRequest({
                    ...waterTestingDataRequest,
                    RecordType: e.target.value,
                  });
                }}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  {translation.recordType}
                </MenuItem>
                {WaterTestingRecordType.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error className="error-text font-10 text-left">
                {errors.recordType}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth className="sx-inputGroup my-2">
              <Button
                variant="outlined"
                component="label"
                className="upload-file w-100"
                startIcon={<Backup />}
              >
                {translation.uploadTestResults}
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf"
                  onChange={(e) => handleFilesAdded(e)}
                  hidden
                />
              </Button>
              {files && (
                <Typography className="small">{files[0].name}</Typography>
              )}
              <FormHelperText error className="helper-text">
                {fileSizeError}
              </FormHelperText>
            </FormControl>
            <Box className="d-flex justify-content-between align-items-center my-2">
              <Typography>{translation.oneOrMoreSamplesFailed}</Typography>
              <FormControl
                variant="filled"
                className="sx-inputGroup sx-customSelect my-2"
              >
                <Select
                  id="select-status"
                  className="sx-input"
                  name="SampleFailed"
                  disableUnderline={true}
                  value={waterTestingDataRequest.SampleFailed ? "1" : "0"}
                  onChange={(e) => handleSampleFailedStatus(e)}
                  displayEmpty
                >
                  <MenuItem value="" className="disabled">
                    {translation.select}
                  </MenuItem>
                  {YesOrNoOptions.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            {!isOneOrMoreSamplesFail && isTestResultsUploaded && (
              <Box className="d-flex justify-content-between align-items-center my-2">
                <Typography>{translation.requestCertificate}</Typography>
                <FormControl
                  variant="filled"
                  className="sx-inputGroup sx-customSelect my-2"
                >
                  <Select
                    id="select-status"
                    label={translation.select}
                    className="sx-input"
                    name="CertificateRequest"
                    disableUnderline={true}
                    value={
                      waterTestingDataRequest.CertificateRequest ? "1" : "0"
                    }
                    onChange={(e: any) => {
                      setWaterTestingDataRequest({
                        ...waterTestingDataRequest,
                        CertificateRequest:
                          e.target.value == "1" ? true : false,
                      });
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" className="disabled">
                      {translation.select}
                    </MenuItem>
                    {YesOrNoOptions.map((item) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            {isOneOrMoreSamplesFail && (
              <Box className="d-flex justify-content-between align-items-center my-2">
                <Typography>{translation.requestOrder}</Typography>
                <FormControl
                  variant="filled"
                  className="sx-inputGroup sx-customSelect my-2"
                >
                  <Select
                    id="select-status"
                    label={translation.select}
                    className="sx-input"
                    name="RequestOrder"
                    disableUnderline={true}
                    value={waterTestingDataRequest.RequestOrder ? "1" : "0"}
                    onChange={(e: any) => {
                      setWaterTestingDataRequest({
                        ...waterTestingDataRequest,
                        RequestOrder: e.target.value == "1" ? true : false,
                      });
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" className="disabled">
                      {translation.select}
                    </MenuItem>
                    {YesOrNoOptions.map((item) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box className="d-flex justify-content-between align-items-center my-2">
              <Typography>{translation.requestContact}</Typography>
              <FormControl
                variant="filled"
                className="sx-inputGroup sx-customSelect my-2"
              >
                <Select
                  id="select-request-contact"
                  label={translation.select}
                  className="sx-input"
                  name="RequestContact"
                  disableUnderline={true}
                  value={waterTestingDataRequest.RequestContact ? "1" : "0"}
                  onChange={(e: any) => {
                    setWaterTestingDataRequest({
                      ...waterTestingDataRequest,
                      RequestContact: e.target.value == "1" ? true : false,
                    });
                  }}
                  displayEmpty
                >
                  <MenuItem value="" className="disabled">
                    {translation.select}
                  </MenuItem>
                  {YesOrNoOptions.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions className="px-4 py-3 sx-border-top">
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              className="sx-button"
            >
              {translation.submit}
            </Button>
            <Button
              variant="text"
              className="sx-button sx-textButton"
              onClick={handleClose}
            >
              {translation.cancel}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        variant="contained"
        className="ml-2 sx-button p-0"
        onClick={handleAddNew}
      >
        <Add />
        <span className="sx-hideOnMobile">{translation.add}</span>
      </Button>
    </Box>
  );
};

export default connector(AddWaterTesting);
