import { Box, Backdrop, CircularProgress } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { ApplicationState } from "../../types/Master";
import { action_types } from "../../types/ActionTypes";
import { connect } from "react-redux";
import UseTranslation from "../../services/UseTranslation";
import "../../custom.css";
import { Dispatch } from "redux";
import { GetAttachment } from "../../services/CustomerService";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

const ImageView = (props: any) => {
  const { isLoading, setLoader, setToastMessage } = props;
  const [isRedirect, setIsRedirect] = useState(false);
  const search = useLocation().search;

  useEffect(() => {
    var attachmentName = new URLSearchParams(search).get("name");
    setLoader(true);
    if (attachmentName && attachmentName != "") {
      let extension = attachmentName.split(".").pop();
      GetAttachment(attachmentName)
        .then((blob) => {
          setLoader(false);
          blob = blob.slice(
            0,
            blob.size,
            extension && extension.toLowerCase() == "pdf"
              ? "application/pdf;base64"
              : "image/jpeg;base64"
          );
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_self");
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          setToastMessage(translation.unabletoloadimage, false);
          setIsRedirect(true);
        });
    }
  }, []);

  if (isRedirect) {
    return <Redirect to="/SignIn" />;
  }

  return (
    <Backdrop
      style={{
        zIndex: 2250,
      }}
      open={isLoading}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageView);
