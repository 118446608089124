import * as React from "react";
import UseTranslation from "../../services/UseTranslation";
import {
  Account,
  ApplicationState,
  ComplianceStatus,
} from "../../types/Master";
import { connect, ConnectedProps } from "react-redux";
import { Chart as ChartJS, Title, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box } from "@material-ui/core";
import { getStatusValue } from "../../services/Shared";

ChartJS.register(Title, ArcElement, Tooltip, Legend);

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  dsoAccounts: state.DsoAccounts,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface OwnProps {
  complianceStatusType: string;
}

export type DoughnutChartProps = PropsFromRedux & OwnProps;

const DoughnutChart = (props: DoughnutChartProps) => {
  const { user, dsoAccounts, complianceStatusType } = props;
  const [totalAccounts, setTotalAccounts] = React.useState<Account[]>([]);

  React.useEffect(() => {
    if (dsoAccounts && dsoAccounts.length > 0)
      setTotalAccounts([...dsoAccounts]);
  }, [dsoAccounts]);

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const data = {
    labels: [
      translation.incompliance,
      translation.aproachingDeadline,
      translation.outOfCompliance,
      translation.noProductReported,
    ],
    datasets: [
      {
        label: "Accounts",
        data: [
          totalAccounts.filter((row) => {
            return (
              getStatusValue(complianceStatusType, row) ==
              ComplianceStatus.InCompliance
            );
          }).length,
          totalAccounts.filter((row) => {
            return (
              getStatusValue(complianceStatusType, row) ==
              ComplianceStatus.ApproachingDeadline
            );
          }).length,
          totalAccounts.filter((row) => {
            return (
              getStatusValue(complianceStatusType, row) ==
              ComplianceStatus.OutOfCompliance
            );
          }).length,
          totalAccounts.filter((row) => {
            return (
              getStatusValue(complianceStatusType, row) ==
              ComplianceStatus.NoProductReported
            );
          }).length,
        ],
        backgroundColor: ["#50cd89", "#ffc700", "#f1416c", "#009ef7"],
      },
    ],
  };

  return totalAccounts.length > 0 ? (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "360px",
        cursor: "pointer",
      }}
    >
      <Doughnut
        options={options}
        data={data}
        plugins={[
          {
            id: "plugin_id",
            beforeDraw: function (chart: {
              width: any;
              height: any;
              ctx: any;
            }) {
              var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
              ctx.restore();
              var fontSize = (height / 160).toFixed(2);
              ctx.font = fontSize + "em sans-serif";
              ctx.textBaseline = "top";
              var text = totalAccounts.length,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ]}
      />
    </Box>
  ) : (
    <React.Fragment />
  );
};

export default connector(DoughnutChart);
