import {
  Backdrop,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import SearchBar from "material-ui-search-bar";
import AddWaterTesting from "./AddWaterTesting";
import UseTranslation from "../../services/UseTranslation";
import {
  ApplicationState,
  defaultWaterTesting,
  WaterTesting,
} from "../../types/Master";
import {
  EnhancedTableHead,
  getComparator,
  HeadCell,
  OrderType,
  stableSort,
} from "../shared/Table";
import {
  generateExcel,
  generatePDF,
  getFormatedDateForDisplay,
  getWaterTestRecotdType,
  getWaterTestTypes,
} from "../../services/Shared";
import { GetWaterTesting } from "../../services/ProductService";
import SideNav from "../shared/SideNav";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    setShippingLabel: (val: string) =>
      dispatch({ type: "SET_SHIPPING_LABEL", payload: val }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const headCells: HeadCell[] = [
  {
    id: "DateofSample",
    numeric: false,
    disableSorting: false,
    label: translation.dateofsample,
  },
  {
    id: "TestType",
    numeric: false,
    disableSorting: false,
    label: translation.testtype,
  },
  {
    id: "RecordType",
    numeric: false,
    disableSorting: false,
    label: translation.recordType,
  },
  {
    id: "TestingLab",
    numeric: false,
    disableSorting: false,
    label: translation.testLabName,
  },
  {
    id: "SampleFailed",
    numeric: false,
    disableSorting: false,
    label: translation.samplesFailed,
  },
  {
    id: "Image",
    numeric: false,
    disableSorting: true,
    label: translation.waterTestingImage,
  },
  {
    id: "WaterCertificate",
    numeric: false,
    disableSorting: true,
    label: translation.waterCertificate,
  },
  {
    id: "ShippingLabel",
    numeric: false,
    disableSorting: true,
    label: translation.shippingLabel,
  },
  {
    id: "WaterTestingResult",
    numeric: false,
    disableSorting: true,
    label: "Water Test Result",
  },
  {
    id: "Edit",
    numeric: false,
    disableSorting: true,
    label: translation.edit,
  },
];

const defaultcolumns = ["Date of Sample", "Test Type", "Testing Lab"];

const WaterTestings = (props: PropsFromRedux) => {
  const {
    user,
    isLoading,
    isContactAccountsLoaded,
    setLoader,
    setToastMessage,
    selectedAccount,
    setShippingLabel
  } = props;
  const [order, setOrder] = React.useState<OrderType>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("CreatedOn");
  const [waterTesting, setWaterTesting] = React.useState<WaterTesting[]>([]);
  const [totalWaterTesting, setTotalWaterTesting] = React.useState<
    WaterTesting[]
  >([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searched, setSearched] = React.useState<string>("");
  const [updateWaterTesting, setUpdateWaterTesting] =
    React.useState<WaterTesting>(defaultWaterTesting);

  React.useEffect(() => {
    if (isContactAccountsLoaded && selectedAccount.ID != "") {
      getwaterTestingData();
    }
  }, [selectedAccount, isContactAccountsLoaded]);

  const getwaterTestingData = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      setLoader(true);
      GetWaterTesting(selectedAccount.ID)
        .then((res) => {
          setWaterTesting(res);
          setTotalWaterTesting(res);
          setLoader(false);
          resolve();
        })
        .catch((ex) => {
          console.error(ex);
          setLoader(false);
          reject();
        });
    });
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSearch = (searchedVal: string) => {
    let listOfWords = searchedVal.split(" ");
    let filteredRows: WaterTesting[] = [];
    totalWaterTesting.forEach((item) => {
      let isAllWordsPresent = false;
      listOfWords.every((word: string) => {
        if (
          (item.TestType &&
            getWaterTestTypes(item.TestType)
              .toLowerCase()
              .includes(word.toLowerCase())) ||
          (item.TestLabName &&
            item.TestLabName &&
            item.TestLabName.toLowerCase().includes(word.toLowerCase())) ||
          (item.DateofSample &&
            getFormatedDateForDisplay(item.DateofSample)
              .toLowerCase()
              .includes(word.toLowerCase()))
        ) {
          isAllWordsPresent = true;
          return true;
        } else {
          isAllWordsPresent = false;
          return false;
        }
      });
      if (isAllWordsPresent) filteredRows.push({ ...item });
    });
    setWaterTesting(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const downloadExcelOrPdf = (isPdf: boolean) => {
    const tableRows: any[] = [];
    waterTesting.forEach((test) => {
      const data = [
        getFormatedDateForDisplay(test.DateofSample),
        getWaterTestTypes(test.TestType),
        test.TestLabName,
      ];
      tableRows.push(data);
    });
    if (isPdf) generatePDF(tableRows, defaultcolumns, translation.waterTesting);
    else generateExcel(tableRows, defaultcolumns, translation.waterTesting);
  };

  const handleViewAttachment = (urlPath: string) => {
    if (urlPath != "") {
      window.open(urlPath);
    } else {
      setToastMessage(translation.fileDoesnotExist, false);
    }
  };

  const handleEdit = (waterTesting: WaterTesting) => {
    setUpdateWaterTesting({ ...waterTesting });
  };

  const handleShippingLabel = (imageString: string) => {
    const linkSource = "data:image/png;base64," + imageString;
    setShippingLabel(linkSource);
    window.open("/viewshippinglabel");
  };

  const handleWaterResult = (resultUrl: any) => {   
    if (resultUrl!=null && resultUrl != "") window.open(resultUrl);
    else setToastMessage(translation.waterTestingResultDoesntExists, false);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={3} md={2} className="sx-colorBg-grey-08 px-4 pt-4">
        <SideNav />
      </Grid>
      <Grid item xs={12} sm={9} md={10}>
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        <Box className="p-3">
          <h3>{translation.waterTesting}</h3>
          <p className="small">{translation.waterTestingDetails} <a href="https://solmetex.com/wp-content/uploads/2024/04/FC15-TestingLog.pdf" className="text-danger" target="_blank" rel="noopener noreferrer">Please click here for a downloadable water test log</a> to record your in-office test results. After recording your results in the pdf form, please upload your results. Mail-in Sterisil R2A test results will automatically populate in your account once results are ready. </p>
          
        </Box>
        <Box className="sx-table-shadow mb-5 p-3">
          <Box className="d-flex justify-content-between align-items-center pb-3 sx-tableSearchContainer">
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              className="col-sm-5 px-0 sx-tableSearch shadow-none"
            />
            <Box className="d-flex justify-content-between align-items-center pt-1">
              <AddWaterTesting
                getwaterTestingData={getwaterTestingData}
                updateWaterTesting={updateWaterTesting}
              />
              <IconButton
                className="ml-3 p-0"
                onClick={() => downloadExcelOrPdf(true)}
              >
                <img
                  src={require("../../images/pdf.png")}
                  alt="Solmetex"
                  className="img-fluid sx-iconButtonImg"
                />
              </IconButton>
              <IconButton
                className="p-0"
                onClick={() => downloadExcelOrPdf(false)}
              >
                <img
                  src={require("../../images/xls.png")}
                  alt="Solmetex"
                  className="img-fluid sx-iconButtonImg"
                />
              </IconButton>
            </Box>
          </Box>
          <Box className="pb-3">
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  rowCount={waterTesting.length}
                  displayActionCell={false}
                  onRequestSort={handleRequestSort}
                />
                {waterTesting.length == 0 ? (
                  <TableBody className="sx-tableBody">
                    <TableRow>
                      <TableCell
                        className="mb-5 p-3 text-center"
                        colSpan={headCells.length}
                      >
                        <img
                          src={require("../../images/empty-state.png")}
                          alt="Solmetex"
                          className="img-fluid"
                        />
                        <h3 className="mt-3 sx-blue">No Data Found</h3>
                        <p>please try again later</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="sx-tableBody">
                    {stableSort(waterTesting, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((wt) => {
                        let extension = wt.AttachmentPath.split(".").pop();
                        return (
                          <TableRow>
                            <TableCell>
                              {getFormatedDateForDisplay(wt.DateofSample)}
                            </TableCell>
                            <TableCell>
                              {getWaterTestTypes(wt.TestType)}
                            </TableCell>
                            <TableCell>
                              {getWaterTestRecotdType(wt.RecordType)}
                            </TableCell>
                                <TableCell>{wt.TestLabName}
                                    {wt.DentisafeTesting && (
                                        <div> Test ID : {wt.TestId}</div> 
                                    )}
                                </TableCell>
                            <TableCell>
                                    {wt.DentisafeTesting ? (
                                        <div>See 'Water Test Result'</div>
                                    ) : (
                                            <div>{wt.SampleFailed ? "Yes" : "No"}</div>
                                    )}
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {wt.AttachmentPath != null &&
                                  wt.AttachmentPath.length > 0 && (
                                    <a
                                      onClick={() =>
                                        handleViewAttachment(wt.AttachmentPath)
                                      }
                                      className="sx-blue"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {extension &&
                                      extension.toLowerCase() == "pdf" ? (
                                        <img
                                          src={require("../../images/pdf-download.png")}
                                          alt="Solmetex"
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={require("../../images/img-download.png")}
                                          alt="Solmetex"
                                          className="img-fluid"
                                        />
                                      )}
                                    </a>
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                {!wt.DentisafeTesting && (
                                  <a
                                    onClick={() =>
                                      handleViewAttachment(
                                        wt.WaterCertificateUrl
                                      )
                                    }
                                    className="sx-blue"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={require("../../images/pdf-download.png")}
                                      alt="Solmetex"
                                      className="img-fluid"
                                    />
                                  </a>
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography>
                                {wt.DentisafeTesting && (
                                  <a
                                    onClick={() =>
                                      handleShippingLabel(wt.ShippingLabel)
                                    }
                                    className="sx-blue"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {extension &&
                                    extension.toLowerCase() == "pdf" ? (
                                      <img
                                        src={require("../../images/pdf-download.png")}
                                        alt="Solmetex"
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../images/img-download.png")}
                                        alt="Solmetex"
                                        className="img-fluid"
                                      />
                                    )}
                                  </a>
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography>
                                {wt.DentisafeTesting && (
                                  <a
                                    onClick={() =>
                                      handleWaterResult(wt.WaterTestingReportPath)
                                    }
                                    className="sx-blue"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={require("../../images/pdf-download.png")}
                                      alt="Solmetex"
                                      className="img-fluid"
                                    />
                                  </a>
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              {!wt.DentisafeTesting && (
                                <Box className="d-flex align-items-center">
                                  <IconButton
                                    aria-label="edit"
                                    size="small"
                                    className="sx-buttonIcon ml-1"
                                    onClick={() => handleEdit(wt)}
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Box>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={waterTesting.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="pagination-content"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default connector(WaterTestings);
