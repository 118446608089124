import {
  Backdrop,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import SearchBar from "material-ui-search-bar";
import { Link } from "react-router-dom";
import AddRegisteredProduct from "./AddRegisteredProduct";
import UseTranslation from "../../services/UseTranslation";
import { ApplicationState, RegisteredProduct } from "../../types/Master";
import {
  EnhancedTableHead,
  getComparator,
  HeadCell,
  OrderType,
  stableSort,
} from "../shared/Table";
import {
  generateExcel,
  generatePDF,
  getFormatedDateForDisplay,
} from "../../services/Shared";
import { GetRegisteredProducts } from "../../services/ProductService";
import SideNav from "../shared/SideNav";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  isContactAccountsLoaded: state.IsContactAccountsLoaded,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
    setSelectedRegisteredProduct: (val: RegisteredProduct) =>
      dispatch({
        type: "SET_SELECTED_REGISTEREDPRODUCT",
        payload: val,
      }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const headCells: HeadCell[] = [
  {
    id: "Dealer",
    numeric: false,
    disableSorting: false,
    label: translation.dealer,
  },
  {
    id: "DealerText",
    numeric: false,
    disableSorting: false,
    label: translation.dealerText,
  },
  {
    id: "PurchaseDate",
    numeric: false,
    disableSorting: false,
    label: translation.purchasedate,
  },
  {
    id: "InstallationDate",
    numeric: false,
    disableSorting: false,
    label: translation.installationdate,
  },
  {
    id: "ReplacementDate",
    numeric: false,
    disableSorting: false,
    label: translation.replacementdate,
  },
  {
    id: "ProductNumber",
    numeric: false,
    disableSorting: false,
    label: translation.productnumber,
  },
  {
    id: "ProductDescription",
    numeric: false,
    disableSorting: false,
    label: translation.productdescription,
  },
  {
    id: "Quantity",
    numeric: false,
    disableSorting: false,
    label: translation.quantity,
  },
  {
    id: "SerialNumber",
    numeric: false,
    disableSorting: false,
    label: translation.serialNumber,
  },
  {
    id: "Source",
    numeric: false,
    disableSorting: false,
    label: translation.source,
  },
  {
    id: "Image",
    numeric: false,
    disableSorting: true,
    label: translation.image,
  },
  {
    id: "InstallationCertificate",
    numeric: false,
    disableSorting: true,
    label: translation.installationCertificate,
  },
];

const defaultcolumns = [
  "Dealer",
  "Purchase Date",
  "Installation Date",
  "Replacement Date",
  "Product Number",
  "Product Description",
  "Quantity",
  "Serial Number",
  "Source",
];

const RegisteredProducts = (props: PropsFromRedux) => {
  const {
    user,
    isLoading,
    isContactAccountsLoaded,
    selectedAccount,
    setLoader,
    setToastMessage,
    setSelectedRegisteredProduct,
  } = props;
  const [order, setOrder] = React.useState<OrderType>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("CreatedOn");
  const [registeredProducts, setRegisteredProducts] = React.useState<
    RegisteredProduct[]
  >([]);
  const [totalRegisteredProducts, setTotalRegisteredProducts] = React.useState<
    RegisteredProduct[]
  >([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searched, setSearched] = React.useState<string>("");

  React.useEffect(() => {
    if (isContactAccountsLoaded && selectedAccount.ID != "") {
      getRegisteredProducts();
    }
  }, [selectedAccount, isContactAccountsLoaded]);

  const getRegisteredProducts = () => {
    setLoader(true);
    GetRegisteredProducts(selectedAccount.ID)
      .then((res) => {
        setRegisteredProducts(res);
        setTotalRegisteredProducts(res);
        setLoader(false);
      })
      .catch((ex) => {
        console.error(ex);
        setLoader(false);
      });
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSearch = (searchedVal: string) => {
    let listOfWords = searchedVal.split(" ");
    let filteredRows: RegisteredProduct[] = [];
    totalRegisteredProducts.forEach((item) => {
      let isAllWordsPresent = false;
      listOfWords.every((word: string) => {
        if (
          item.Product.Name.toLowerCase().includes(word.toLowerCase()) ||
          item.DealerText.toLowerCase().includes(word.toLowerCase()) ||
          item.Product.Description.toLowerCase().includes(word.toLowerCase()) ||
          (item.DealerName &&
            item.DealerName.toLowerCase().includes(word.toLowerCase())) ||
          item.SerialNumber.toLowerCase().includes(word.toLowerCase()) ||
          item.Source.toLowerCase().includes(word.toLowerCase()) ||
          item.Quantity.toString() == word ||
          getFormatedDateForDisplay(item.PurchaseDate)
            .toLowerCase()
            .includes(word.toLowerCase()) ||
          getFormatedDateForDisplay(item.InstallationDate)
            .toLowerCase()
            .includes(word.toLowerCase()) ||
          getFormatedDateForDisplay(item.ReplacementDate)
            .toLowerCase()
            .includes(word.toLowerCase())
        ) {
          isAllWordsPresent = true;
          return true;
        } else {
          isAllWordsPresent = false;
          return false;
        }
      });
      if (isAllWordsPresent) filteredRows.push({ ...item });
    });
    setRegisteredProducts(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const downloadExcelOrPdf = (isPdf: boolean) => {
    const tableRows: any[] = [];
    registeredProducts.forEach((product) => {
      const data = [
        product.Dealer.Name,
        getFormatedDateForDisplay(product.PurchaseDate),
        getFormatedDateForDisplay(product.InstallationDate),
        getFormatedDateForDisplay(product.ReplacementDate),
        product.ProductNumber,
        product.ProductDescription,
        product.Quantity,
        product.SerialNumber,
        product.Source,
      ];
      tableRows.push(data);
    });
    if (isPdf)
      generatePDF(tableRows, defaultcolumns, translation.registeredProducts);
    else
      generateExcel(tableRows, defaultcolumns, translation.registeredProducts);
  };

  const handleViewPDF = (product: any) => {
    if (product != null) {
      setSelectedRegisteredProduct({
        ...product,
      });
      window.open("/viewproof");
    }
  };

  const handleViewImage = (product: any) => {
    window.open(product.ImageOfInstallation);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={3} md={2} className="sx-colorBg-grey-08 px-4 pt-4">
        <SideNav />
      </Grid>
      <Grid item xs={12} sm={9} md={10}>
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        <Box className="p-3">
          <h3>{translation.registeredProducts}</h3>
          <p className="small">{translation.registeredProductDetails}</p>
        </Box>
        <Box className="sx-table-shadow mb-5 p-3">
          <Box className="d-flex justify-content-between align-items-center pb-3 sx-tableSearchContainer">
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              className="col-sm-5 px-0 sx-tableSearch shadow-none"
            />
            <Box className="d-flex justify-content-between align-items-center pt-1">
              <AddRegisteredProduct
                getRegisteredProducts={getRegisteredProducts}
              />
              <IconButton
                className="ml-1 p-0"
                onClick={() => downloadExcelOrPdf(true)}
              >
                <img
                  src={require("../../images/pdf.png")}
                  alt="Solmetex"
                  className="img-fluid sx-iconButtonImg"
                />
              </IconButton>
              <IconButton
                className="p-0"
                onClick={() => downloadExcelOrPdf(false)}
              >
                <img
                  src={require("../../images/xls.png")}
                  alt="Solmetex"
                  className="img-fluid sx-iconButtonImg"
                />
              </IconButton>
            </Box>
          </Box>
          <Box className="pb-3">
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  rowCount={registeredProducts.length}
                  displayActionCell={false}
                  onRequestSort={handleRequestSort}
                />
                {registeredProducts.length == 0 ? (
                  <TableBody className="sx-tableBody">
                    <TableRow>
                      <TableCell
                        className="mb-5 p-3 text-center"
                        colSpan={headCells.length}
                      >
                        <img
                          src={require("../../images/empty-state.png")}
                          alt="Solmetex"
                          className="img-fluid"
                        />
                        <h3 className="mt-3 sx-blue">No Data Found</h3>
                        <p>please try again later</p>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="sx-tableBody">
                    {stableSort(
                      registeredProducts,
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((product) => {
                        return (
                          <TableRow>
                            <TableCell component="th" scope="row" width={180}>
                              {product.DealerName}
                            </TableCell>
                            <TableCell>{product.DealerText}</TableCell>
                            <TableCell>
                              {getFormatedDateForDisplay(product.PurchaseDate)}
                            </TableCell>
                            <TableCell>
                              {getFormatedDateForDisplay(
                                product.InstallationDate
                              )}
                            </TableCell>
                            <TableCell>
                              {getFormatedDateForDisplay(
                                product.ReplacementDate
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row" width={150}>
                              {product.ProductNumber}
                            </TableCell>
                            <TableCell>{product.ProductDescription}</TableCell>
                            <TableCell>{product.Quantity}</TableCell>
                            <TableCell>{product.SerialNumber}</TableCell>
                            <TableCell>{product.Source}</TableCell>
                            <TableCell>
                              {product.ImageOfInstallation != null &&
                                product.ImageOfInstallation.length > 0 && (
                                  <Typography>
                                    <a
                                      onClick={() => handleViewImage(product)}
                                      className="sx-blue"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={require("../../images/img-download.png")}
                                        alt="Solmetex"
                                        className="img-fluid"
                                      />
                                    </a>
                                  </Typography>
                                )}
                            </TableCell>
                            <TableCell>
                              {product.ImageOfInstallation != null &&
                                product.ImageOfInstallation.length > 0 && (
                                  <Typography>
                                    <Link
                                      to="#"
                                      className="sx-blue"
                                      onClick={() => handleViewPDF(product)}
                                    >
                                      <img
                                        src={require("../../images/pdf-download.png")}
                                        alt="Solmetex"
                                        className="img-fluid"
                                      />
                                    </Link>
                                  </Typography>
                                )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={registeredProducts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="pagination-content"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default connector(RegisteredProducts);
