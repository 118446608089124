import {
  ApplicationState,
  defaultAccount,
  defaultRegisteredProduct,
  initialUserState,
  defaultRecycledCerticate,
  User,
} from "../../types/Master";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  SET_LOADER,
  SET_TOAST_MESSAGE,
  SET_SELECTED_ACCOUNT,
  SET_CONTACT,
  SET_SHOW_SELECTED_ACCOUNT_DATA,
  SET_IS_CONTACT_ACCOUNTS_LOADED,
  SET_SELECTED_REGISTEREDPRODUCT,
  SET_SELECTED_RECYCLEDCERTIFICATE,
  SET_SELECTED_TAB,
  SET_IS_WOOCOMMERCE_REDIRECTION_REQUIRED,
  SET_COMPLIANCE_STATUS_TYPE,
  SET_DISPLAY_ACCOUNTS_DATA,
  SET_DSO_DETAILS,
  SET_SHIPPING_LABEL,
  SET_SELECTED_ACCOUNT_CONTACT,
} from "../../types/ActionTypes";
import { GetLoggedInUser } from "../../services/CustomerService";
import { AnyAction } from "redux";
import UseTranslation from "../../services/UseTranslation";

const translation = UseTranslation();

const user = GetLoggedInUser();

const initialState: ApplicationState = {
  isAuthUser: user ? true : false,
  user: user ? user : initialUserState,
  isLoading: false,
  error: false,
  toast: { Message: "", Status: false },
  SelectedAccount: defaultAccount,
  DsoParentAccount: defaultAccount,
  DsoAccounts: [],
  ShowSelectedAccountData: false,
  SelectedRegisteredProduct: defaultRegisteredProduct,
  IsContactAccountsLoaded: false,
  SelectedRecycledCertificate: defaultRecycledCerticate,
  SelectedTab: 0,
  WooCommerceRedirectionRequired: false,
  complianceStatusType: translation.amalgamContainers,
  displayAccountsData: false,
  ShippingLabel: "",
  SelectedAccountContact:user ? user : initialUserState,
};

export default (
  state: ApplicationState = initialState,
  action: AnyAction
): ApplicationState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        isAuthUser: true,
        user: action.payload,
      };
    case SET_CONTACT:
      let user = action.payload as User;
      let selectedAccount = { ...state.SelectedAccount };
      user.Accounts.every((item) => {
        if (item.ID == state.SelectedAccount.ID) {
          selectedAccount = { ...item };
          return false;
        } else return true;
      });
      return {
        ...state,
        user: action.payload,
        SelectedAccount: selectedAccount,
      };
      case SET_SELECTED_ACCOUNT_CONTACT:        
      return {
        ...state,  
        SelectedAccountContact: action.payload,
      };
    case SET_SHOW_SELECTED_ACCOUNT_DATA:
      return {
        ...state,
        SelectedTab: 0,
        ShowSelectedAccountData: action.payload,
      };
    case SET_LOADER:
      return { ...state, isLoading: action.payload };
    case SET_IS_CONTACT_ACCOUNTS_LOADED:
      return { ...state, IsContactAccountsLoaded: action.payload };
    case SET_SELECTED_ACCOUNT:
      return { ...state, SelectedAccount: action.payload };
    case SET_SELECTED_REGISTEREDPRODUCT:
      return { ...state, SelectedRegisteredProduct: action.payload };
    case SET_SELECTED_RECYCLEDCERTIFICATE:
      return { ...state, SelectedRecycledCertificate: action.payload };
    case SET_SELECTED_TAB:
      return { ...state, SelectedTab: action.payload };
    case SET_COMPLIANCE_STATUS_TYPE:
      return { ...state, complianceStatusType: action.payload };
    case SET_DISPLAY_ACCOUNTS_DATA:
      return { ...state, displayAccountsData: action.payload };
    case SET_DSO_DETAILS:
      return {
        ...state,
        DsoAccounts: action.payload.DsoAccounts,
        DsoParentAccount: action.payload.DsoParentAccount,
      };
    case SET_SHIPPING_LABEL:
      return { ...state, ShippingLabel: action.payload };
    case LOGOUT:
      localStorage.removeItem("user");
      return {
        ...state,
        isAuthUser: false,
        SelectedAccount: { ...defaultAccount },
        user: initialUserState,
        IsContactAccountsLoaded: false,
        ShowSelectedAccountData: false,
        SelectedTab: 0,
        DsoAccounts: [],
        DsoParentAccount: { ...defaultAccount },
        ShippingLabel: "",
      };
    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toast: {
          Message: action.payload.Message,
          Status: action.payload.Status,
        },
      };
    case SET_IS_WOOCOMMERCE_REDIRECTION_REQUIRED:
      return { ...state, WooCommerceRedirectionRequired: action.payload };
    default:
      return state;
  }
};
