import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  DialogActions,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import * as React from "react";
import { Backup, Close, Add, ViewAgenda } from "@material-ui/icons";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import {
  GetDealerAccountsForPR,
  GetDealerApprovedForMP,
} from "../../../services/CustomerService";
import {
  DeviceorSourceWater,
  BottleOrMunicipal,
} from "../../../services/Shared";
import UseTranslation from "../../../services/UseTranslation";
import {
  Account,
  RegisteredProduct,
  ApplicationState,
  defaultRegisteredProduct,
  User,
  defaultProduct,
  defaultAccount,
  RegistrableProduct,
  defaultRegistrableProduct,
  Vial,
  defaultVial,
} from "../../../types/Master";
import {
  CreateRegisteredProduct,
  GetProductsForRegistration,
} from "../../../services/ProductService";
import validator from "validator";
import { Autocomplete } from "@material-ui/lab";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  user: state.user,
  selectedAccount: state.SelectedAccount,
  selectedAccountContact: state.SelectedAccountContact,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    loginSuccess: (val: User) =>
      dispatch({ type: "LOGIN_SUCCESS", payload: val }),
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setToastMessage: (Message: string, Status: boolean) =>
      dispatch({ type: "SET_TOAST_MESSAGE", payload: { Message, Status } }),
  };
};

interface errorValidation {
  sampleNumber: string;
  room: string;
  deviceorsourcewate: string;
  bottleormunicipal: string;
}

const defaultErrorValidation = {
  sampleNumber: "",
  room: "",
  deviceorsourcewate: "",
  bottleormunicipal: "",
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface OwnProps {
  setVials: (event: any) => void;
  vial: Vial;
  setVial:React.Dispatch<React.SetStateAction<Vial>>;
}

export type ddVials = PropsFromRedux & OwnProps;

const AddVials = (props: ddVials) => {
  const {
    isLoading,
    setLoader,
    setToastMessage,
    setVials,
    selectedAccount,
    user,
    selectedAccountContact,
    vial,
    setVial
  } = props;
  const [isAddNew, setIsAdNew] = React.useState<boolean>(false);
  const [showSerialNumberRequired,setShowSerialNumberRequired]=React.useState<boolean>(false);
  const [showRoomRequired,setShowRoomRequired]=React.useState<boolean>(false);
  const [showDeviceRequired,setShowDeviceRequired]=React.useState<boolean>(false);
  const [showBottleRequired,setShowBottleRequired]=React.useState<boolean>(false);
  // const [vial, setVial] =
  // React.useState<Vial>(defaultVial);

  const [errors, setErrors] = React.useState<errorValidation>({
    ...defaultErrorValidation,
  });
  

  const handleAddNew = (e: any) => {
    e.preventDefault();
    setIsAdNew(true);
  };

  const handleClose = () => {
    setIsAdNew(false);
  };

  const errorValidation = (request: Vial): boolean => {    
    let errorsObject = { ...defaultErrorValidation };
    let isValid = true;
    if (request.SampleNumber == "" || request.SampleNumber.length!=6) {
      errorsObject = {
        ...errorsObject,
        sampleNumber: translation.requiredField,
      };
      setShowSerialNumberRequired(true)
      isValid = false;
    }
    if (request.RoomChairOperatory == "") {
      errorsObject = { ...errorsObject, room: translation.requiredField };
      isValid = false;
      setShowRoomRequired(true);
    }
    if (request.DeviceOrSourceWater == "") {
      errorsObject = {
        ...errorsObject,
        deviceorsourcewate: translation.emailValidation,
      };
      setShowDeviceRequired(true);
      isValid = false;
    }
    if (request.BottleOrMunicipal == "") {
      errorsObject = {
        ...errorsObject,
        bottleormunicipal: translation.emailValidation,
      };
      isValid = false;
      setShowBottleRequired(true);
    }
    setErrors({ ...errorsObject });
    return isValid;
  };

  const handleDeviceorSourceWater = (e: any) => {
    setVial({
      ...vial,
      DeviceOrSourceWater: e.target.value as string,
    });
    if(e.target.value!==null && e.target.value.length>0 ){
      setShowDeviceRequired(false);
    }
    else
    {
      setShowDeviceRequired(true);
    }  
  };

  const handleSampleNumber = (e: any) => {
    setVial({
      ...vial,
      SampleNumber: e.target.value as string,
    });
    if(e.target.value!==null && e.target.value.length==6 ){
      setShowSerialNumberRequired(false);
    }
    else
    {
      setShowSerialNumberRequired(true);
    }  
  };

  const handleRoomChair = (e: any) => {
    setVial({
      ...vial,
      RoomChairOperatory: e.target.value as string,
    });
    if(e.target.value!==null && e.target.value.length>0 ){
      setShowRoomRequired(false);
    }
    else
    {
      setShowRoomRequired(true);
    }  
  };


  const handleMunicipalorBottle = (e: any) => {
    setVial({
      ...vial,
      BottleOrMunicipal: e.target.value as string,
    });
    if(e.target.value!==null && e.target.value.length>0 ){
      setShowBottleRequired(false);
    }
    else
    {
      setShowBottleRequired(true);
    }  
  };


  const setToDefaults = () => {
    //setVial(defaultVial);
  };

  const handleButtonClick = (event: any) => {
    // Invoke the callback function passed from the parent
    if(errorValidation(vial)){
      setIsAdNew(false);
      setVials(event);
    }    
  };

  return (
    <Box>
      <Backdrop
        style={{
          zIndex: 2250,
        }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      <Dialog
        className="sx-model"
        open={isAddNew}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Box>
          <DialogTitle className="sx-model-title  sx-border-bottom">
            Add Vial
          </DialogTitle>
          <Box position="absolute" top={8} right={8}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent className="mb-4 px-3 tw-grid tw-grid-cols-2 tw-gap-4">
            <Box>
              <TextField
                className={`sx-inputGroup ${showSerialNumberRequired && "sx-validationFailed"}`}
                margin="normal"
                fullWidth
                id="serialnumber"
                name="serialnumber"
                type="number"                
                label="Sample Number"                
                onChange={(e) => {
                handleSampleNumber(e)
                }}
                value={vial.SampleNumber}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                  inputProps: { min: 1, maxLength: 6 },
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
              {showSerialNumberRequired && (
                <div className="error-text font-10 pl-4 text-left">
                  Six digit number required
                </div>
                )}
            </Box>
            <Box>
              <TextField
                className={`sx-inputGroup ${showRoomRequired && "sx-validationFailed"}`}
                margin="normal"
                fullWidth
                id="room"
                name="room"
                type="text"
                label="Room /Chair/Operatory #"
                onChange={(e) => {
                  handleRoomChair(e)
                }}
                value={vial.RoomChairOperatory}
                InputProps={{
                  disableUnderline: true,
                  className: "sx-input",
                  inputProps: { min: 1 },
                }}
                InputLabelProps={{ className: "sx-input-label" }}
                variant="filled"
              />
              {showRoomRequired && (
              <div className="error-text font-10 pl-4 text-left">
                Required
              </div>   
              )}
            </Box>
            
            <Box>
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-watersource"
                className={`sx-input ${showDeviceRequired && "sx-validationFailed"}`}
                name="watersource"
                disableUnderline={true}
                label="Device or Source Water"
                value={vial.DeviceOrSourceWater}
                onChange={(e) => handleDeviceorSourceWater(e)}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  Device or Source Water
                </MenuItem>
                {DeviceorSourceWater.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              {/* <FormHelperText error className="error-text font-10 text-left">
                 Please select
                </FormHelperText> */}
            </FormControl>
            {showDeviceRequired && (
                <div className="error-text font-10 pl-4 text-left">
                  Required
                </div>
            )}
            </Box>
            
            <Box>
            <FormControl
              fullWidth
              variant="filled"
              className="sx-inputGroup sx-customSelect my-2"
            >
              <Select
                id="select-bottle"
                className={`sx-input ${showBottleRequired && "sx-validationFailed"}`}
                name="bottle"
                disableUnderline={true}
                label="Bottle or Municipal"
                value={vial.BottleOrMunicipal}
                onChange={(e) => handleMunicipalorBottle(e)}
                displayEmpty
              >
                <MenuItem value="" className="disabled">
                  Bottle or Municipal
                </MenuItem>
                {BottleOrMunicipal.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              {/* <FormHelperText error className="error-text font-10 text-left">
                 Please select
                </FormHelperText> */}
            </FormControl>
            {showBottleRequired && (
              <div className="error-text font-10 pl-4 text-left">
                Required
              </div>
              
        )}
        </Box>
            {/* <textarea 
              className="sx-inputGroup"
              id="otherinfo"
              name="otherinfo"
              onChange={(e) => {
                vial.OtherInformation = e.target.value as string;
              }}
              value={vial.OtherInformation}
            /> */}

          <TextField
            className="sx-inputGroup"
            margin="normal"
            fullWidth
            id="otherinfo"
            name="otherinfo"
            type="text"
            label="Other Information"
            // value={vial.OtherInformation}
            InputProps={{
              disableUnderline: true,
              className: "sx-input",
              inputProps: { min: 1 },
            }}
            InputLabelProps={{ className: "sx-input-label" }}
            variant="filled"
            onChange={(e) => {
              vial.OtherInformation = e.target.value as string;
            }}
            multiline
            rows={3}
          />


          </DialogContent>
          <DialogActions className="px-4 py-3 sx-border-top">
            <Button
              variant="contained"
              onClick={handleButtonClick}
              disabled={isLoading}
              className="sx-button"
            >
              {translation.submit}
            </Button>
            <Button
              variant="text"
              className="sx-button sx-textButton"
              onClick={handleClose}
            >
              {translation.cancel}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        variant="contained"
        className="ml-2 mt-2 sx-button p-0"
        onClick={handleAddNew}
      >
        <Add />
        <span className="sx-hideOnMobile">Add Vial</span>
      </Button>
    </Box>
  );
};

export default connector(AddVials);
