import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Layout from "./components/navigation/Layout";
import { Provider } from "react-redux";
import { createStore } from "redux";
import AuthRoute from "./AuthRoute";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  DialogContentText,
  IconButton,
} from "@material-ui/core";
import reducers from "./store/reducers";
import Footer from "./components/navigation/Footer";
import SignIn from "./components/customer/SignIn";
import SignUp from "./components/customer/SignUp";
import ForgotPassword from "./components/customer/ForgotPassword";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import SetPassword from "./components/customer/SetPassword";
import UserVerification from "./components/customer/UserVerification";
import ChangePassword from "./components/customer/ChangePassword";
import Dashboard from "./components/dso/Dashboard";
import MaintenancePlans from "./components/maintenanceplan/MaintenancePlans";
import ShippingLabel from "./components/shippinglabel/ShippingLabel";
import AttachmentView from "./components/shared/AttachmentView";
import Cases from "./components/productissues/Cases";
import WaterTestings from "./components/watertesting/WaterTestings";
import RegisteredProducts from "./components/registeredproducts/RegisteredProducts";
import RecycledCertificates from "./components/recycledcertificates/RecycledCertificates";
import ProofOfInstallationView from "./components/registeredproducts/ProofOfInstallationView";
import RecycledCertificateView from "./components/recycledcertificates/RecycledCertificateView";
import Home from "./components/navigation/Home";
import { ApplicationState } from "./types/Master";
import ShippingLabelView from "./components/shippinglabel/ShippingLabelView";
import DSLandingPage from "./components/watertesting/dentisafe/LandingPage";
import { Close } from "@material-ui/icons";
const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "isLoading",
    "toast",
    "IsContactAccountsLoaded",
    "SelectedTab",
    "WooCommerceRedirectionRequired",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer);

export const persistor = persistStore(store);
const mapStateToProps = (state: ApplicationState) => ({
  wooCommerceRediectionRequired: state.WooCommerceRedirectionRequired,
});
class App extends React.Component {
  state: {
    isAuthenticated: boolean;
    showDSTestIdPopup: boolean;
  } = { isAuthenticated: false, showDSTestIdPopup: false };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  handleClose = () => {
    this.state.showDSTestIdPopup = false;
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const paramValue = searchParams.get("returnto");
    if (paramValue != null) {
      localStorage.setItem("retunpath", paramValue);
    } else if (
      window.location.pathname.toLowerCase() != "/signin" &&
      window.location.pathname.toLowerCase() != "/forgotpassword" &&
      window.location.pathname.toLowerCase() != "/signup" &&
      window.location.pathname.toLowerCase() != "/setpassword" &&
      window.location.pathname.toLowerCase() != "/verifyuser" &&
      window.location.pathname.toLowerCase() != "/attachment" &&
      window.location.pathname.toLowerCase() != "/viewproof" &&
      window.location.pathname.toLowerCase() != "/viewcertificate" &&
      window.location.pathname.toLowerCase() != "/viewshippinglabel"
    ) {
      localStorage.setItem(
        "originalurl",
        window.location.pathname.toLowerCase()
      );
    }
    if (window.location.pathname.toLowerCase() == "/dentisafetesting") {     
      var dentisafeId = searchParams.get("test_id");
      if (dentisafeId != null && dentisafeId.length > 0) {
        var id = localStorage.getItem("dentisafeId");
        if (id == null) {
          localStorage.setItem("dentisafeId", dentisafeId);
        } else {
          localStorage.removeItem("dentisafeId");
          localStorage.setItem("dentisafeId", dentisafeId);
        }
        this.state.showDSTestIdPopup = false;
      } else {
        this.state.showDSTestIdPopup = true;
      }
    }
  }

  render() {
    return this.state.showDSTestIdPopup ? (
      <React.Fragment>
        <Dialog open={this.state.showDSTestIdPopup} onClose={this.handleClose}>
          <DialogTitle>URL missing</DialogTitle>
          <Box position="absolute" top={8} right={8}>
            <IconButton onClick={this.handleClose}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText>
              Ensure the URL you submit is valid and adheres to the specified
              format.
            </DialogContentText>
            <TextField
              className="sx-inputGroup"
              margin="normal"
              fullWidth
              id=""
              name=""
              type="text"
              label="URL"
              value=""
              InputProps={{
                disableUnderline: true,
                className: "sx-input",
                inputProps: { min: 1 },
              }}
              InputLabelProps={{ className: "sx-input-label" }}
              variant="filled"
            />
          </DialogContent>
          <DialogActions className="!tw-mr-4 !tw-mb-4">
            <Button className="!tw-rounded-full" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              className="sx-button !tw-rounded-full !tw-bg-[#008cc1] !tw-text-white !tw-min-w-[100px]"
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    ) : (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Layout />
            <Box>
              <Switch>
                <AuthRoute path="/signin" type="guest">
                  <SignIn />
                </AuthRoute>
                <AuthRoute path="/signup" type="guest">
                  <SignUp />
                </AuthRoute>
                <AuthRoute path="/forgotpassword" type="guest">
                  <ForgotPassword />
                </AuthRoute>
                <AuthRoute path="/setpassword" type="guest">
                  <SetPassword />
                </AuthRoute>
                <AuthRoute path="/changepassword" type="private">
                  <ChangePassword />
                </AuthRoute>
                <AuthRoute path="/verifyuser" type="guest">
                  <UserVerification />
                </AuthRoute>
                <AuthRoute path="/home" type="private">
                  <Home />
                </AuthRoute>
                <AuthRoute path="/recyclingcertificate" type="private">
                  <RecycledCertificates />
                </AuthRoute>
                <AuthRoute path="/productregistration" type="private">
                  <RegisteredProducts />
                </AuthRoute>
                <AuthRoute path="/maintenanceplans" type="private">
                  <MaintenancePlans />
                </AuthRoute>
                <AuthRoute path="/shippinglabel" type="private">
                  <ShippingLabel />
                </AuthRoute>
                <AuthRoute path="/dashboard" type="private">
                  <Dashboard />
                </AuthRoute>
                <AuthRoute path="/attachment" type="public">
                  <AttachmentView />
                </AuthRoute>
                <AuthRoute path="/viewproof" type="private">
                  <ProofOfInstallationView />
                </AuthRoute>
                <AuthRoute path="/viewcertificate" type="private">
                  <RecycledCertificateView />
                </AuthRoute>
                <AuthRoute path="/viewshippinglabel" type="private">
                  <ShippingLabelView />
                </AuthRoute>
                <AuthRoute path="/cases" type="private">
                  <Cases />
                </AuthRoute>
                <AuthRoute path="/watertesting" type="private">
                  <WaterTestings />
                </AuthRoute>
                <AuthRoute path="/dentisafetesting" type="private">
                  <DSLandingPage />
                </AuthRoute>                
                <Redirect from="*" to="/signin" />
              </Switch>
            </Box>
          </Router>
          <Footer />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
